
/* MAT TOOLTIP */

.mat-tooltip-panel-above.mat-tooltip-panel {
  pointer-events: none;
}

.dark-theme-tooltip,
mat-tooltip-component .dark-theme-tooltip {
  position: relative;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  letter-spacing: 0.03em;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  background: var(--dark-material-bg);
  border-color: var(--dark-material-bg);
  color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  padding: 6px 10px;
  margin: 5px 14px;
  overflow: initial;
  border-radius: 0;
  pointer-events: initial;
  word-wrap: break-word;

  &.simple {
    border-radius: 2px;
    box-shadow: 0 5px 15px rgba(129, 160, 198, .4);
  }

  &:not(.simple)::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-left: 1px solid var(--dark-material-bg);
    border-bottom: 1px solid var(--dark-material-bg);
    background-color: var(--dark-material-bg);
    clip-path: polygon(0 0, 0 100%, 100% 100%);
  }

  &.right::before {
    left: -5px;
    top: 50%;
    transform: translate(0%, -50%) rotateZ(45deg);
  }

  &.bottom::before,
  &.below::before {
    top: -5px;
    left: 50%;
    transform: translate(-50%, 0%) rotateZ(135deg);
  }

  &.left::before {
    right: -5px;
    top: 50%;
    transform: translate(0%, -50%) rotateZ(225deg);
  }

  &.above::before {
    bottom: -5px;
    left: 50%;
    transform: translate(-50%, 0%) rotateZ(-45deg);
  }

  &.border-rounded {
    border-radius: 8px;
  }
}

mat-tooltip-component .mat-tooltip {
  &.text-left {
    text-align: left;
  }

  &.auto-width {
    max-width: initial;
  }

  &.multi-line {
    white-space: pre-line;
    word-break: break-word;
  }

  &.regular-text {
    padding: 10px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}

.mat-tooltip.metric-total {
  max-width: 310px;
  font-size: 14px;
}

.mat-tooltip.bv-widget-tooltip {
  max-width: 220px;
}

.mat-tooltip.projected-budget {
  width: 100%;
  max-width: 290px;
}

.mat-tooltip.metric-sources-tooltip {
  max-width: 100px;
}

.mat-tooltip.metric-rpo {
  width: 646px;
  max-width: initial;
  font-weight: 400;
  line-height: 20px;
}

.mat-tooltip.metric-disabled-rpo {
  width: 276px;
  max-width: initial;
}

.mat-tooltip.timeframe-lock-button {
  width: 142px;
}

.mat-tooltip.metric-disabled-rpo::before {
  left: 60%;
}
.mat-tooltip.roi-col-tooltip:before {
  left: 80%;
}


.table-view-tooltip {
  position: fixed;
  margin-left: 8px;
  margin-top: -14px;
  left: -100%;
  top: -100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 101;
}

.table-view-tooltip.is-visible {
  opacity: 1;
  visibility: visible;
}

.table-view-tooltip.right-overflow::ng-deep .tooltip-container::before {
  left: 64%;
}
