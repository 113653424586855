.table-with-shadows-container {
  --content-shadow-color: var(--navy-blue);
  display: block;
  position: relative;

  &:not(.sticky-footer):before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background: linear-gradient(to top, var(--content-shadow-color) 0%, rgba(32, 73, 114, 0) 100%);
    transition: opacity .35s;
    pointer-events: none;
    opacity: 0;
    z-index: 10;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4px;
    background: linear-gradient(to left, var(--content-shadow-color) 0%, rgba(32, 73, 114, 0) 100%);
    transition: opacity .35s;
    pointer-events: none;
    opacity: 0;
    z-index: 10;
  }

  &.shadow-bottom,
  &.shadow-top {
    &:after {
      right: 6px;
      bottom: 6px;
    }
  }

  &.shadow-right,
  &.shadow-left {
    &:before {
      right: 6px;
      bottom: 6px;
    }
  }

  thead, tfoot {
    .shadow-tr {
      position: relative;
      height: 0;

      td {
        position: absolute;
        left: 0;
        right: 0;
        height: 4px;
        transition: opacity .35s;
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  thead .shadow-tr {
    td {
      background: linear-gradient(to bottom, var(--content-shadow-color) 0%, rgba(32, 73, 114, 0) 100%);
    }
  }

  tfoot .shadow-tr {
    td {
      top: -4px;
      background: linear-gradient(to top, var(--content-shadow-color) 0%, rgba(32, 73, 114, 0) 100%);
    }
  }

  thead th,
  thead td,
  tbody td {
    &:first-child .cell-content {
      position: relative;
      align-items: center;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -5px;
        bottom: -1px;
        width: 4px;
        background: linear-gradient(to right, var(--content-shadow-color) 0%, rgba(32, 73, 114, 0) 100%);
        transition: opacity .35s;
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  &.shadow-bottom:before,
  &.shadow-right:after {
    opacity: 0.5;
  }

  &.shadow-top thead .shadow-tr td{
    opacity: 0.5;
  }

  &.shadow-bottom tfoot .shadow-tr td {
    opacity: 0.5;
  }

  &.shadow-left {
    thead th,
    thead td,
    tbody td {
      &:first-child .cell-content:after {
        opacity: 0.5;
      }
    }
  }

  .table-wrap {
    overflow: auto;
  }

  table {
    margin-bottom: 6px;
    --border-style: 1px solid var(--blue-disabled);
  }

  thead {
    position: sticky;
    top: -.03rem;
    background-color: white;
    z-index: 9;

    tr:first-child {
      th {
        border-top: var(--border-style);
      }
    }

    th {
      &:first-child {
        z-index: 10;
      }

      .cell-content {
        border-top: var(--border-style);
      }
    }
  }

  &.sticky-footer {
    table{
      margin-bottom: 0;
      border-bottom: none;
    }
    tfoot {
      position: sticky;
      bottom: -0.055rem;
      background-color: white;
      z-index: 9;

      tr{
        td > div {
          border-bottom: 1px solid var(--blue-disabled);
          margin-bottom: 1px;
        }
      }
    }
  }

  thead th,
  tbody td,
  tfoot td {
    &:first-child {
      background-clip: padding-box;
      position: sticky;
      left: 0;
      border-right: var(--border-style);
      background-color: white;
      z-index: 8;

      .cell-content {
        width: 332px;
      }
    }
  }
}

