#drag-image {
  &.container {
    display: flex;
    align-items: center;
    background-color: var(--primary);
    color: var(--corporate-white);
    padding: 14px;
    box-shadow: 20px 16px 12px rgba(150, 169, 188, 0.32);
    border-radius: 8px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    width: 400px;

    .icon {
      margin-right: 10px;

      fa-icon {
        display: block;
        width: 20px;
        height: 20px;
        font-size: 20px;
        --fa-primary-color: var(--corporate-white);
        --fa-secondary-color: var(--corporate-white);
      }

      img {
        display: block;
        width: 15px;
      }
    }
  }
}
