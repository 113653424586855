:root {
  --primary: #0063B7;
  --primary-darken: #2b66a5;
  --dark-mode-primary: #0FB3FF;
  --secondary: #fe6226;
  --secondary-darken: #dd5924;
  --warn: #FD5830;
  --warn-darken: #DA442F;
  --secondary-gray: #707070;
  --gray: #d2d2d2;
  --light-gray: #CCCCCC;
  --gray-darken: #8d8d8d;
  --gray-darkest: #272727;
  --blue: #5b8ec5;
  --blue-disabled: #dee6f1;
  --blue-grey-medium: #B7C1D0;
  --faded-light-blue: #EFF3F8;
  --light-blue: #6eb0f5;
  --dark-blue: #0041A0;
  --navy-blue: #061F38;
  --malibu: #76CEFF;
  --orange: #FF8A00;
  --light-orange: #FECA84;
  --dark-orange: #D96500;
  --red-alert: #FF0000;
  --red-disabled: #FEE3e3;
  --low-alert: #FF9901;
  --success: #27a364;
  --success-darken: #218a55;
  --purple: #4c368d;
  --corporate-purple: #7C55FB;
  --corporate-purple_04: rgba(124, 85, 251, 0.4);
  --corporate-green: #4CB050;
  --corporate-dark-green: #2C6C2F;
  --corporate-black: #333333;
  --corporate-magenta: #E82987;
  --corporate-magenta-a-01: rgba(232, 41, 135, 0.1);
  --corporate-magenta-a-02: rgba(232, 41, 135, 0.2);
  --corporate-red: #FD5830;
  --corporate-red-dark: #c74425;
  --corporate-red-a-06: rgba(253, 88, 48, .6);
  --corporate-blue: #008BCB;
  --corporate-white: #FFFFFF;
  --corporate-white_05: rgba(255, 255, 255, .5);
  --corporate-ultraviolet: #7C4FFF;
  --corporate-navy-violet: #362370;
  --corporate-navy-violet_08: rgba(54, 35, 112, 0.8);
  --corporate-navy-violet_007: rgba(54, 35, 112, 0.07);
  --corporate-navy-violet-dark: #261752;
  --dark-material-bg: #061F38;
  --dark-magenta: #A31259;
  /* Expense status colors */
  --status-closed: #323232;
  --status-committed: #4894E1;
  --status-planned: #7C55FB;

  /* Expense status colors ceg */
  --status-closed-ceg: #0063B7;
  --status-actual: #0063B7;
  --status-commited-ceg: #249BED;;
  --status-planned-ceg: #76CEFF;
  /* Budget status colors */
  --status-reserved: #BABABA;
  --status-remaining: #BABABA;
  --status-available: #A8D6A9;
  --status-under-budget: #FECA84;
  --status-over-budget: #FD5830;
  --status-over-budget_a10: rgba(253, 88, 48, .1);
  --status-overspend: #FD5830;
  --remaining-green: #0F8914;
  /* FontAwesome colors */
  --fa-primary-color: #4C368D;
  --fa-secondary-color: #4C368D;
  --mat-default: #7084A1;
  --mat-focus-shadow: 0 7px 10px -1px rgba(129, 160, 198, .4);
  --mat-focus-shadow-warn: 0 4px 9px rgba(218, 68, 47, .35);
  --mat-focus-shadow-secondary: 0px 6px 10px -1px rgba(129, 160, 198, 0.35);
  --mat-focus-shadow-secondary-warn: 0px 6px 10px -1px rgba(218, 68, 47, .35);
  --black-a-02: rgba(0, 0, 0, .2);
  --black-a-06: rgba(0, 0, 0, .6);
  --selection-bg: rgba(0, 139, 203, .3);
  --mat-lilac: #6B87FF;
  --corporate-pink: #E85DC5;
}


$md-red: (
  50 : #ffebe6,
  100 : #fecdc1,
  200 : #feac98,
  300 : #fe8a6e,
  400 : #fd714f,
  500 : #fd5830,
  600 : #fd502b,
  700 : #fc4724,
  800 : #fc3d1e,
  900 : #fc2d13,
  A100 : #ffffff,
  A200 : #fff7f6,
  A400 : #ffc9c3,
  A700 : #ffb1aa,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-primary: (
  50 : #e7eef6,
  100 : #c3d6e8,
  200 : #9cbad9,
  300 : #749eca,
  400 : #568abe,
  500 : #0063B7,
  600 : #326dac,
  700 : #2b62a3,
  800 : #24589a,
  900 : #17458b,
  A100 : #c1d7ff,
  A200 : #8eb7ff,
  A400 : #5b96ff,
  A700 : #4186ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$md-accent: (
  50 : #e2f6ff,
  100 : #b7e8ff,
  200 : #87d9ff,
  300 : #57caff,
  400 : #33beff,
  500 : #0fb3ff,
  600 : #0dacff,
  700 : #0ba3ff,
  800 : #089aff,
  900 : #048bff,
  A100 : #ffffff,
  A200 : #f2f9ff,
  A400 : #bfdeff,
  A700 : #a6d1ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

html {
  font-size: 17px;
}

.pl-btn {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  padding: 0 25px;
  margin: 0;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  transition: background-color .1s ease-in;
  cursor: pointer;
}

.pl-btn.transparent {
  background-color: transparent;
  color: var(--primary);
}

.pl-btn.transparent:not(:disabled):hover {
  background-color: transparent;
  color: var(--dark-blue);
}

.pl-btn.transparent:disabled {
  opacity: unset;
  color: #cccccc;
}

.pl-table {
  background-color: white;
  width: 100%;
}

.pl-table .table-headers-row {
  width: 100%;
  border-bottom: 1px solid #d7d7d7;
  border-top: 1px solid #d7d7d7;
}

.pl-table .table-headers-row th {
  color: #707070;
  font-size: 12px;
  line-height: 20px;
  padding: 5px 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.pl-table .table-data-row {
  /* border-bottom: 1px solid #eaeaea; */
}

.pl-table .table-data-row:nth-child(2n) {
  background-color: #f2f8ff;
}

.pl-table .table-data-row td{
  position: relative;
  vertical-align: top;
  word-break: break-word;
  color: #707070;
  font-size: 12px;
}

.pl-table .table-data-row td:last-child .cell-wrapper{
  text-align: right;
}

.pl-table .cell-wrapper {
  border-bottom: 1px solid transparent;
  border-top: 1px solid #eaeaea;
  padding: 5px 15px;
}

.pl-table .table-data-row.selected .cell-wrapper {
  border-top-color: var(--primary);
}
.pl-table .table-data-row.selected + tr .cell-wrapper {
  border-top-color: var(--primary);
}



/* OVERRIDE OF MATERIAL COMPONENTS */

/* FORM FIELD COMMON */

mat-form-field.mat-form-field.w-100 {
  width: 100%;
}

mat-form-field.mat-form-field,
mat-form-field.mat-form-field mat-select,
mat-dialog-container.mat-dialog-container,
.mat-select-panel mat-option.mat-option,
.mat-autocomplete-panel mat-option.mat-option,
snack-bar-container.mat-snack-bar-container ,
mat-expansion-panel-header.mat-expansion-panel-header,
mat-expansion-panel .mat-expansion-panel-content {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: var(--corporate-black);
}

mat-form-field.mat-form-field {
  margin-bottom: -1.34375em;
}

mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
  height: 37px;
  padding: 3px 16px 0;
}

mat-form-field.mat-form-field-appearance-fill.mat-primary .mat-form-field-flex {
  background: #EFF3F8;
}

mat-form-field.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex{
  background-color: white;
}

mat-form-field .mat-input-element {
  height: 24px;
  margin-top: unset;
  font-weight: 500;
}

mat-form-field .mat-input-element.cdk-text-field-autofill-monitored:-webkit-autofill,
mat-form-field .mat-input-element.cdk-text-field-autofill-monitored:-webkit-autofill:hover,
mat-form-field .mat-input-element.cdk-text-field-autofill-monitored:-webkit-autofill:focus,
mat-form-field .mat-input-element.cdk-text-field-autofill-monitored:-webkit-autofill:active {
  -webkit-text-fill-color: var(--corporate-black);
  transition: none;
}

mat-form-field.mat-form-field-appearance-fill.textarea .mat-form-field-flex {
  height: 55px;
  min-height: 55px;
}

mat-form-field.mat-form-field-appearance-fill.textarea.autosize .mat-form-field-flex {
  height: initial;
  min-height: initial;
}

mat-form-field textarea.mat-input-element  {
  resize: none;
  height: 40px;
  margin-top: unset;
}

mat-form-field.minimized  textarea.mat-input-element {
  height: 26px;
  min-height: 26px;
}

mat-form-field.mat-focused .mat-input-element {
  font-weight: 600;
}

mat-form-field.mat-primary .mat-input-element {
  color: #333;
  caret-color: var(--primary);
}

mat-form-field .mat-input-element::placeholder,
mat-form-field.mat-form-field .mat-form-field-label {
  font-size: 14px;
  font-weight: 500;
}

mat-form-field.mat-primary .mat-input-element::placeholder,
mat-form-field.mat-primary .mat-form-field-label,
mat-form-field.mat-primary.mat-form-field-disabled .mat-form-field-label {
  color: var(--mat-default);
}

mat-form-field.mat-form-field-invalid .mat-input-element::placeholder {
  color: #f44336;
}

mat-form-field.mat-form-field .mat-form-field-infix {
  padding: 0;
  border-top-width: 10px
}

mat-form-field.mat-form-field .mat-form-field-label-wrapper {
  top: -5px;
  padding-top: 10px;
}

mat-form-field.mat-form-field.mat-form-field-should-float .mat-form-field-label-wrapper {
  top: -10px;
}

mat-form-field.mat-primary.mat-focused:not(.mat-form-field-invalid) {
  .mat-form-field-label,
  &.mat-form-field-should-float,
  .mat-input-element::placeholder {
    color: var(--primary);
  }
}

mat-form-field.mat-form-field .mat-form-field-underline {
  bottom: 19px;
  transform: scaleY(1);
}

mat-form-field.mat-primary .mat-form-field-underline,
mat-form-field.mat-primary.mat-focused .mat-form-field-ripple {
  background-color: var(--primary);
}

mat-form-field.mat-primary .mat-form-field-ripple,
mat-form-field.mat-primary .mat-form-field-underline::before,
mat-form-field.mat-primary.mat-form-field-disabled:not(.no-label) .mat-form-field-underline::before{
  background-color: var(--mat-default);
}

mat-form-field.mat-primary.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(239, 243, 248, .3);
}

mat-form-field.mat-primary.mat-form-field-disabled .mat-form-field-label {
  color: rgba(112, 132, 161, .7)!important;
}

mat-form-field .mat-form-field-subscript-wrapper {
  margin-top: .18em;
  font-size: 11px;
  line-height: 1.3;
}

mat-form-field.mat-form-field .mat-form-field-flex {
  align-items: center;
}

mat-form-field .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
  line-height: 12px;
}

mat-form-field.outline,
mat-form-field.outline .mat-form-field-wrapper {
  border-radius: 5px;
}

mat-form-field.outline .mat-form-field-wrapper {
  background-color: #ffffff;
  margin: 0;
}

mat-form-field.outline.no-label .mat-form-field-wrapper .mat-form-field-flex {
  height: 37px;
  margin: 0;
}

mat-form-field.outline .mat-form-field-infix {
  width: initial;
}

mat-form-field.outline .mat-form-field-outline {
  top: 0;
}

mat-form-field.outline .mat-form-field-outline {
  color: #ECF2F8;
}

mat-form-field.outline.active .mat-form-field-outline {
  color: var(--primary);
}

mat-form-field.outline.mat-focused .mat-form-field-outline,
mat-form-field.outline .mat-form-field-flex:hover .mat-form-field-outline  {
  color: var(--dark-blue);
}

mat-form-field.outline.mat-form-field-disabled {
  pointer-events: none;
  opacity: 0.3;
}

mat-form-field.outline .mat-form-field-outline .mat-form-field-outline-start,
mat-form-field.outline .mat-form-field-outline .mat-form-field-outline-end {
  border-width: 1px;
}

mat-form-field.outline .mat-input-element {
  color: var(--primary);
}

mat-form-field.outline.mat-focused .mat-input-element,
mat-form-field.outline .mat-form-field-flex:hover .mat-input-element {
  color: var(--dark-blue);
}

mat-form-field.outline .mat-input-element::placeholder {
  color: #999999;
}

.mat-button-wrapper .mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
}

.mat-button-wrapper .mat-select-arrow.mat-select-arrow-up {
  transform: rotate(180deg);
}

.mat-button-wrapper .mat-select-arrow-wrapper {
  display: flex;
  margin-left: 8px;
}

.mat-button-wrapper .mat-select-arrow {
  margin: 0;
}

/* SELECT */

/* Should use this custom class for `mat-select` with `disableOptionCentering panelClass="mat-pl-select-panel"`
  to avoid conflicts with Autocomplete component */
.mat-pl-select-panel {
  margin-top: 30px;
}

.mat-select-panel.mat-primary {
  background-color: white;
}

.mat-select-panel.mat-select-fixed-position {
  margin-top: 21px;
  margin-bottom: 40px;
  max-width: 800px;
}

.mat-select-panel.mat-alloc-select-fixed-position {
  margin-top: 22px;
}

.mat-select-panel.mat-select-timezone {
  margin-left: 6px;
}

.mat-select-panel.mat-select-timezone mat-option.mat-option {
  font-size: 13px;
  height: 30px;
}

.mat-select-panel.mat-select-fixed-groups.segments-select .mat-optgroup-label {
  background: none;
  opacity: 0.4;
}

.mat-select-panel.mat-primary .mat-option,
.mat-select-panel.mat-primary .mat-option.mat-selected {
  color: var(--primary);
}

.mat-select-panel.mat-primary mat-option.mat-option.hidden {
  visibility: hidden;
}

.mat-select-panel.mat-primary mat-option.mat-option.mat-selected {
  font-weight: 600;
}

.mat-select-panel.mat-primary mat-option:hover:not(.mat-option-disabled),
.mat-select-panel.mat-primary mat-option.mat-selected:not(.mat-option-disabled) {
  background-color: rgba(0, 139, 203, 0.1);
}

.mat-form-field.mat-primary .mat-select-arrow,
.mat-form-field.mat-primary.mat-focused .mat-select-arrow {
  color: var(--mat-default);
}

mat-form-field.mat-primary .mat-select-value {
  color: #333333;
}

mat-form-field.mat-form-field .mat-select-value {
  padding-top: 3px;
}

mat-form-field.mat-form-field .mat-select-arrow-wrapper {
  transform: translateY(10%);
}

.mat-select-panel.mat-primary .mat-optgroup-label {
  color: var(--primary);
  background-color: rgb(239 243 248);
}

mat-form-field.mat-form-field.auto-width .mat-form-field-infix {
  width: auto;
}

mat-form-field.mat-form-field.auto-width .mat-select-value {
  width: auto;
  max-width: unset;
  flex-grow: 1;
}

.mat-select-placeholder {
  color: transparent;
}

mat-form-field.mat-form-field.auto-width .mat-select-trigger {
  width: 100%;
}

.mat-form-field-can-float.mat-form-field-should-float.auto-width .mat-form-field-label {
  text-overflow: initial;
  overflow: initial;
}
/* DATE PICKER */
mat-form-field.mat-form-field.date-picker .mat-form-field-infix {
  width: 104px;
}

mat-form-field.mat-form-field .mat-datepicker-content .mat-calendar {
  width: 308px;
  height: 370px;
}

mat-form-field.mat-form-field-appearance-fill.mat-form-field-disabled.date-picker .mat-form-field-flex {
  background-color: rgba(239, 243, 248, .3);
}

mat-form-field.mat-form-field-appearance-fill.mat-form-field-disabled.date-picker .mat-form-field-label {
  color: rgba(112, 132, 161, .7);
}

mat-form-field.mat-form-field-appearance-fill.mat-form-field-disabled.date-picker .mat-form-field-underline::before {
  background-color: var(--mat-default);
}

mat-form-field.mat-form-field-appearance-fill.date-picker  .mat-form-field-suffix .mat-icon-button {
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: transparent;
  --fa-primary-color: var(--primary);
  --fa-secondary-color: var(--primary);
}

/* override default bootstrap's modals - zIndex */
.cdk-overlay-container {
  z-index: 2000;
}

.cdk-overlay-container thead tr {
  height: unset!important;
}

.cdk-overlay-container .mat-datepicker-content,
.cdk-overlay-container .mat-calendar-body-cell-content {
  color: rgba(0,0,0,.87);
  background-color: #fff;
}

.cdk-overlay-container .mat-calendar-body-label {
  color: rgba(0,0,0,.54);
}
.cdk-overlay-container .mat-calendar-table-header {
  color: rgba(0,0,0,.38);
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0,0,0,.54);
  background-color: initial;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(232, 41, 135, 0.2);
}

.mat-datepicker-content .mat-button-focus-overlay {
  background-color: #000;
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected),
.mat-calendar-body-today:not(.mat-calendar-body-selected),
.mat-calendar-body-today.mat-calendar-body-selected,
.mat-calendar-budget-today .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.mat-calendar-budget-today .mat-calendar-body-cell-content.mat-calendar-body-selected {
  border-color: var(--corporate-magenta);
}

.cdk-overlay-container .mat-calendar-body-cell-content.mat-calendar-body-selected {
  background-color: var(--corporate-magenta);
  color: white;
}

.mat-calendar-body-disabled.visible > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0,0,0,.38);
}

/* MAT FLAT SELECT */
.flat-select-panel.mat-select-panel {
  min-width: 112px!important;
  margin-left: 15px;
  margin-top: 29px;
  box-shadow: 0 12px 30px rgba(150, 169, 188, 0.3);
  background: #fff;
}
.flat-select-panel mat-option.mat-option {
  height: 28px;
  padding: 0 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary);
  letter-spacing: -0.3px;
  background: #fff;
}
.flat-select-panel mat-option.mat-option:hover,
.flat-select-panel mat-option.mat-option.mat-selected:hover {
  background: rgba(0, 139, 203, 0.1);
}
.flat-select-panel.mat-select-panel .mat-ripple {
  display: none;
}
.flat-select-panel mat-option.mat-selected {
  font-weight: 700;
  color: var(--navy-blue);
}
.flat-select-panel .mat-option-text {
  position: relative;
  display: flex;
  align-items: center;
}
.flat-select-panel .with-indicator .mat-option-text {
  padding-left: 14px;
}
.flat-select-panel .option-indicator {
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  width: 5px;
  height: 5px;
  margin-right: 8px;
  margin-top: -3px;
  flex-shrink: 0;
  border-radius: 50%;
}

.flat-select-panel.mat-select-panel.task-status {
  min-width: 112px!important;
  margin-top: 21px;
  margin-bottom: 21px;
}

.flat-select-panel-extended mat-option.mat-option {
  height: 38px;
  font-size: 14px;
}

.flat-select-panel-extended mat-option.mat-selected {
  font-weight: 600;
}

/* AUTOCOMPLETE */
.mat-autocomplete-panel mat-option.mat-option {
  height: 36px;
}

.mat-autocomplete-panel mat-option.mat-option,
.mat-autocomplete-panel mat-option.mat-option.mat-selected {
  color: var(--primary);
}

.mat-autocomplete-panel {
  background-color: white;
}

.mat-autocomplete-panel mat-option:hover:not(.mat-option-disabled),
.mat-autocomplete-panel mat-option.mat-selected:hover:not(.mat-option-disabled),
.mat-autocomplete-panel mat-option.mat-selected:not(.mat-active):not(:hover),
.mat-autocomplete-panel mat-option.mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled),
.mat-autocomplete-panel mat-option.mat-option.mat-active,
.mat-autocomplete-panel mat-option.mat-option.mat-highlighted,
.mat-autocomplete-panel mat-option.mat-option.mat-selected {
  background-color: rgba(0, 139, 203, 0.1);
  color: var(--primary);
}

.mat-autocomplete-panel mat-option.mat-option.mat-highlighted:not(.mat-option-disabled):hover,
.mat-autocomplete-panel mat-option.mat-option.mat-active:not(.mat-option-disabled):hover,
.mat-autocomplete-panel mat-option.mat-option.mat-selected:not(.mat-option-disabled):hover {
  font-weight: 600;
}

/* CHIPS */

mat-chip.mat-chip.mat-standard-chip.mat-chip-with-trailing-icon {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 6px;
  padding-left: 9px;
  min-height: 24px;
  border-radius: 30px;
}

mat-chip.mat-chip.mat-primary {
  background-color: rgba(56, 117, 179, 0.1);
  color: #333333;
}

mat-chip.mat-chip.mat-primary:not(.mat-chip-disabled){
  background-color: rgba(56, 117, 179, 0.15);
}

mat-chip.mat-chip.mat-primary:not(.mat-chip-disabled):hover {
  background-color: rgba(56, 117, 179, 0.2);
}

mat-chip.mat-chip .mat-form-field-flex {
  height: unset;
  padding: 5px 16px;
}

mat-chip.mat-chip.mat-primary .mat-chip-remove {
  color: var(--primary);
  opacity: .8;
}

mat-chip.mat-chip.mat-primary:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 1;
}

mat-chip.mat-chip.mat-primary::after {
  opacity: 0;
}

mat-form-field .mat-chip-list-wrapper {
  max-height: 142px;
  overflow: auto;
}

mat-form-field.mat-form-field.chip-list .mat-form-field-flex {
  overflow: auto;
}

/* No-validate */

mat-form-field.mat-form-field.no-validate {
  margin-bottom: 0;
}

mat-form-field.mat-form-field.no-validate .mat-form-field-wrapper {
  padding-bottom: 0px;
}

mat-form-field.mat-form-field.no-validate .mat-form-field-underline {
  bottom: 0px;
}

mat-form-field.mat-form-field.no-validate .mat-form-field-subscript-wrapper {
  display: none;
}

/* No-label */

mat-form-field.mat-form-field.no-label .mat-form-field-flex {
  height: 27px;
}

mat-form-field.mat-form-field.no-label .mat-form-field-infix {
  border-top: none;
}

mat-form-field.mat-form-field.no-label .mat-form-field-label-wrapper {
  display: none;
}

mat-form-field.mat-form-field.chip-list.no-label .mat-form-field-flex {
  height: unset;
  min-height: 27px;
  padding: 5px 16px;
}

/* BUTTONS */
a.mat-button-base,
button.mat-button-base {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 15px;
  transition: all .25s;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  &.mat-button,
  &.mat-flat-button,
  &.mat-stroked-button {
    padding: 0 10px;
  }

  &.mat-flat-button {
    &.mat-primary,
    &.mat-warn {
      font-weight: 600;
    }

    &.mat-primary:focus {
      box-shadow: var(--mat-focus-shadow);
    }

    &.mat-warn:focus {
      box-shadow: var(--mat-focus-shadow-warn);
    }
  }

  &.mat-button,
  &.mat-stroked-button {
    &.mat-primary:focus {
      box-shadow: var(--mat-focus-shadow-secondary);
    }

    &.mat-warn:focus {
      box-shadow: var(--mat-focus-shadow-secondary-warn);
    }
  }

  &.mat-button {
    border: 1px solid transparent;
    line-height: 34px;

    &.mat-primary {
      &:hover,
      &:focus {
        color: var(--dark-blue);
        border-color: var(--dark-blue);
      }

      &.mat-button-disabled {
        color: var(--primary);
      }
    }

    &.tertiary-btn {
      height: 36px;
      border: none !important;
      color: var(--primary);

      &:focus,
      &:hover {
        background: rgba(56, 117, 179, 0.15);
        color: var(--dark-blue);
      }
    }

    &.mat-warn {
      &:hover,
      &:focus {
        color: var(--warn-darken);
        border-color: var(--warn-darken);
      }

      &.mat-button-disabled {
        color: var(--warn);
      }
    }

    .mat-button-focus-overlay {
      background-color: transparent;
    }
  }

  &.mat-flat-button {
    &.mat-primary {
      &:hover,
      &:focus {
        background-color: var(--dark-blue);
      }

      &.mat-button-disabled {
        color: var(--corporate-white);
        background-color: var(--primary);
      }
    }

    &.mat-warn {
      color: white;

      &:hover {
        background-color: var(--warn-darken);
      }

      &:focus {
        background-color: var(--warn-darken);
      }

      &.mat-button-disabled {
        background-color: var(--warn);
      }
    }
  }

  &.mat-stroked-button {
    background-color: white;

    .mat-button-focus-overlay {
      display: none;
    }

    &.mat-primary {
      border-color: var(--primary);

      &:hover,
      &:focus {
        color: var(--dark-blue);
        border-color: var(--dark-blue);
      }

      &.mat-button-disabled {
        color: var(--primary);
      }
    }

    &.mat-warn {
      border-color: var(--warn);

      &:hover,
      &:focus {
        color: var(--warn-darken);
        border-color: var(--warn-darken);
      }

      &.mat-button-disabled {
        color: var(--warn);
      }
    }
  }
}

/* MENU */
.mat-pl-menu.mat-menu-panel {
  --fa-primary-color: var(--primary);
  --fa-secondary-color: var(--primary);
  max-height: 360px;
  min-height: 36px;
  background: #fff;
}
.mat-pl-menu .mat-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 38px;
  margin: 0;
  line-height: 1;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  color: var(--primary);
}
.mat-pl-menu .mat-menu-item span {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
  color: var(--navy-blue);
}

.mat-pl-menu .mat-menu-item {
  .primary-icon,
  fa-duotone-icon,
  fa-icon {
    display: flex;
    font-size: 19px;
    width: 18px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    margin-left: -2px;
    flex-shrink: 0;
  }
}

.mat-pl-menu .mat-menu-item[disabled] {
  --fa-primary-color: rgba(0, 0, 0, 0.38);
  --fa-secondary-color: rgba(0, 0, 0, 0.38);
  opacity: .3;
}
.mat-pl-menu .mat-menu-item-submenu-trigger::after {
  color: var(--primary);
}
.mat-pl-menu .mat-menu-item:hover,
.mat-pl-menu .mat-menu-item:active,
.mat-pl-menu .mat-menu-item.mat-menu-item-highlighted {
  background: rgba(72, 148, 225, 0.1) !important;
}
.mat-pl-menu .mat-menu-content.no-padding {
  padding: 0;
}
.mat-pl-menu .mat-menu-item.mat-pl-danger {
  --fa-primary-color: red;
  --fa-secondary-color: red;
  color: red;
}
.mat-pl-menu .mat-menu-item.mat-pl-danger:hover {
  background: rgba(223, 17, 17, 0.1);
}

.mat-pl-menu .mat-menu-item.mat-pl-divider {
  border-top: 1px solid var(--gray);
}

.mat-pl-menu.align-content-left {
  margin-left: -16px;
}

.mat-pl-menu .mat-menu-submenu-icon {
  display: none;
}

/* CHECKBOX */

.mat-checkbox input {
  visibility: hidden;
}

.mat-checkbox .mat-checkbox-layout {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
}

.mat-checkbox .mat-checkbox-frame,
.mat-checkbox.mat-checkbox-disabled .mat-checkbox-frame {
  border-color: #BABABA;
}

.mat-checkbox.mat-checkbox-disabled {
  opacity: 0.2;
}

mat-option .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.2);
}

.mat-checkbox.mat-primary.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox.mat-primary.mat-checkbox-indeterminate .mat-checkbox-background,
.mat-option .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: var(--primary);
}

.mat-option .mat-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
  color: white;
}

.mat-checkbox-indeterminate .mat-checkbox-mixedmark {
  background-color: white;
}

.mat-checkbox .mat-checkbox-background svg path {
  stroke: white!important;
}

.mat-checkbox .mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple {
  opacity: .1;
}

.pl-mat-checkbox.mat-checkbox {
  .mat-checkbox-ripple {
    width: 32px;
    height: 32px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &.primary-ripple {
    .mat-ripple-element {
      background-color: rgba(56, 117, 179);
    }
  }

  &.primary-ripple {
    .mat-checkbox-inner-container:hover {
      .mat-ripple-element {
        opacity: .2;
      }
    }
  }
}

/* Font Awesome */

fa-icon svg path {
  fill: var(--fa-primary-color);
}

fa-duotone-icon.primary {
  --fa-primary-color: var(--primary);
  --fa-secondary-color: var(--primary);
}

/* SNACK BARS */
snack-bar-container.mat-snack-bar-container {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0.02em;
  color: #fff;
  background: var(--navy-blue);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);

  simple-snack-bar {
    white-space: pre-line;
  }
}

snack-bar-container .mat-simple-snackbar-action {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 20px;
  margin: 0 0 0 30px;
}

snack-bar-container .mat-simple-snackbar-action button {
  padding: 0;
  color: var(--status-committed);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  opacity: .9;
  transition: none;
  letter-spacing: 0.09em;
}

snack-bar-container .mat-simple-snackbar-action button:hover {
  background: none;
  opacity: 1;
}

snack-bar-container .mat-simple-snackbar-action button .mat-button-focus-overlay {
  display: none;
}

simple-snack-bar {
  width: 100%;
}

/* SLIDE TOGGLE */
mat-slide-toggle .mat-slide-toggle-bar {
  width: 27px;
  height: 18px;
  border-radius: 10px;
  background: #C6C6C6;
}

mat-slide-toggle .mat-slide-toggle-thumb-container {
  width: 12px;
  height: 12px;
  left: 3px;
  top: 3px;
}

mat-slide-toggle .mat-slide-toggle-thumb {
  width: 12px;
  height: 12px;
  box-shadow: none;
  background: var(--secondary-gray);
}

mat-slide-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: var(--primary);
}

mat-slide-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background: rgb(174, 194, 217);
}

mat-slide-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(9px, 0, 0);
}

mat-slide-toggle .mat-slide-toggle.mat-checked .mat-ripple-element {
  background: var(--primary);
}

.mat-slide-toggle-input.cdk-visually-hidden {
  display: none;
}

/* TIME PICKER */
mat-form-field.time-field {
  width: 116px;
}

mat-form-field.time-field .mat-form-field-underline {
  display: none;
}

mat-form-field.time-field .mat-form-field-flex {
  padding: 0 10px;
}

mat-form-field.time-field .mat-form-field-infix {
  border-top: 0;
}

mat-form-field.time-field .mat-form-field-label-wrapper {
  top: 0;
  padding-top: 0;
}

mat-form-field.time-field fa-icon {
  font-size: 20px;
  --fa-primary-color: var(--primary);
  --fa-secondary-color: var(--primary);
}

.timepicker {
  .timepicker-dial {
    .timepicker-period__btn:hover {
      background: initial;
    }

    .timepicker-period__btn {
      margin: 0;
    }

    input {
      height: auto;
    }
  }

  .timepicker__actions {
    border-top: 1px solid #eee;

    .timepicker-button {
      padding: 0 20px;
    }
  }
}

ngx-material-timepicker-container {
  position: relative;
  z-index: 2100;
}

.p-form-field-outline.mat-form-field.mat-form-field {
  &:not(.chip-list) .mat-form-field-label-wrapper {
    top: -12px;
  }

  &.chip-list .mat-form-field-label-wrapper {
    top: -10px;
  }
}

.p-form-field-outline.mat-form-field-appearance-outline {
  display: block;
  margin-bottom: 2px;

  mat-select {
    height: 24px;

    .mat-select-arrow {
      position: relative;
      height: 6px;
      width: 10px;
      margin: 0;
      border: none;
      background: none;
      color: var(--primary);

      &:before,
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 4px;
        height: 8px;
        width: 2px;
        border-radius: 1px;
        background-color: currentColor;
        transform-origin: 50% calc(100% - 1px);
      }

      &:after {
        transform: rotateZ(48deg);
      }

      &:before {
        transform: rotateZ(-48deg);
      }
    }
  }

  &.mat-form-field-disabled {
    opacity: .5;

    .mat-form-field-flex {
      background-color: transparent;
    }
  }

  .mat-form-field-outline {
    top: 4px;
    color: var(--blue-disabled);
  }

  .mat-form-field-outline-thick {
    color: var(--primary);

    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border-width: 1px;
    }
  }

  &.mat-focused .mat-form-field-outline-thick {
    color: var(--primary);

    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border-width: 2px;
    }
  }

  &:not(.textarea.autosize) .mat-form-field-flex {
    height: 56px;
  }

  .mat-form-field-infix {
    padding: 9px 0 !important;
    border-top: 9px solid transparent !important;
  }

  .mat-input-element,
  .mat-select-value-text {
    font-size: 15px;
    font-weight: 600;
    color: var(--navy-blue);
  }

  .mat-form-field-required-marker {
    color: inherit !important;
  }

  .mat-form-field-subscript-wrapper {
    padding: 2px 10px;
    font-size: 11px;
    line-height: 1.6;
  }

  .mat-hint {
    color: #707070;
  }
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin: 14px 12px 0 0;
}

.p-form-field-outline.mat-form-field.mat-primary {
  &:not(.mat-form-field-invalid) {
    .mat-form-field-label {
      color: var(--mat-default);
    }

    &:hover {
      .mat-form-field-label {
        color: var(--primary);
      }
    }

    &.mat-focused {
      .mat-form-field-label {
        color: var(--primary);
      }
    }
  }
}

.mat-calendar.metric-editor-date-picker {
  position: relative;
  min-height: 395px;

  &:after {
    content: 'Select a day for adding a new metric value. Update existing values in the History';
    position: absolute;
    bottom: 10px;
    left: 0;
    padding: 0 16px;
    font-family: Arial;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #707070;
  }
}

.mat-calendar.metric-editor-date-picker,
.mat-calendar.task-due-date-picker {
  button:not(.mat-calendar-budget-today) .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: var(--corporate-white);
  }

  button:not(.mat-calendar-budget-today) .mat-calendar-body-cell-content.mat-calendar-body-selected {
    box-shadow: none;
  }

  .mat-calendar-budget-today .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    border-color: rgba(0, 0, 0, 0.38);
  }

  .mat-calendar-budget-today .mat-calendar-body-cell-content.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white;
  }
}

.mat-tab-label-content {
  color: var(--secondary-gray);
  font-weight: 600;
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
}

.mat-tab-label-active {
  .mat-tab-label-content {
    color: var(--primary);
    font-weight: 700;
  }
}

.mat-ink-bar {
  background-color: var(--primary);
}

.custom-scrollbar {
  ::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 50px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    box-shadow: none;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    border: 3px solid rgba(0, 0, 0, .4);
    background-color: rgba(0, 0, 0, .4);
    background-clip: padding-box;
    opacity: .8;
  }
}
