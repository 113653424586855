// legacy Bootstrap dialogs - will be removed later
.box-modalContentInn .parent-select {
  margin-top: 40px;
  margin-bottom: 40px;

  .parent-select-field {
    display: flex;
    border-top: 3px solid;
    padding-top: 30px;
  }

  .parent-select-label {
    margin-right: 30px;
    font-size: 15px;
    text-align: left;
    font-weight: 600;
    font-style: italic;
    padding: 5px 8px;
    border: 1px solid transparent;
  }

  .dropdownMenuComponent {
    font-size: 15px;
    padding: 5px 8px;
    border: 1px solid transparent;
    transition: none;
  }

  .dropdownMenuComponent.opened {
    position: relative;
    display: inline-block;
    border-color:rgba(50, 115, 183 , .7);
    box-shadow: 0px 0px 6px 2px rgba(50, 115, 183, .4);
    border-radius: 3px;
  }

  .dropdownMenuComponent .menuItemsContainer {
    right: unset;
    left: 0px;
  }

  .dropdownMenuComponent .menuItemsContainer ul {
    max-height: 170px;
    overflow-y: auto;
  }

  .dynamic-tags-info {
    position: relative;
    padding-left: 35px;
    color: #cccccc;
  }

  .dynamic-tags-info::before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 1px;
    left: 9px;
    background: url('../images/icon-tag-label.svg') no-repeat center center;
    background-size: 100%;
  }

  .dynamic-tags-info a {
    color: var(--blue);
    font-size: 12px;
    display: inline-block;
  }

  .dynamic-tags-info a:hover {
    text-decoration: underline;
  }

  .menuItem > button {
    position: relative;
  }

  .menuItem .icon {
    position: absolute;
    top: 50%;
    left: 25px;
    width: 24px!important;
    height: 24px!important;
    margin-top: -12px;
  }
}

/* MATERIAL DIALOGS */
mat-dialog-container.mat-dialog-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 15px 40px rgba(129, 160, 198, 0.25);
  border-radius: 12px;
  color: var(--navy-blue);

  .mat-dialog-title {
    margin: 12px 0 15px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;

    .icon {
      margin-right: 8px;
      font-size: 20px;

      &-warn {
        --fa-primary-color: var(--corporate-magenta);
        --fa-secondary-color: var(--corporate-magenta);
      }
    }
  }

  .mat-dialog-content {
    margin: 0;
    padding: 0;
    color: #5F6376;
    font-size: 13px;
    line-height: 1.6;
  }

  .mat-dialog-actions {
    min-height: initial;
    padding: 0;
    margin: 15px 0 0;
    justify-content: flex-end;
    text-align: center;
  }
}

.reset-paddings mat-dialog-container.mat-dialog-container {
  padding: 0;
}

.cdk-overlay-container .cdk-overlay-dark-backdrop {
  background: var(--corporate-navy-violet);
  opacity: 0.8;
}

.extended-confirmation-dialog .mat-dialog-container {
  padding: 32px 20px 20px;
}

.no-padding mat-dialog-container.mat-dialog-container {
  padding: 0;
}
