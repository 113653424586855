@use '../../../node_modules/@angular/material' as mat;
@import "assets/scss/theme";

@include mat.core();
$plannuh-primary: mat.define-palette($md-primary);
$plannuh-accent: mat.define-palette($md-accent);
$plannuh-warn: mat.define-palette($md-red);
$plannuh-theme: mat.define-light-theme((
  color: (
    primary: $plannuh-primary,
    accent: $plannuh-accent,
    warn: $plannuh-warn,
  )
));
@include mat.all-component-themes($plannuh-theme);

// Custom styles
@import "assets/scss/auth-pages";
@import "assets/scss/editable-content";
@import "assets/scss/select-panel";
@import "assets/scss/forms";
@import "assets/scss/modals";
@import "../../common-lib/src/assets/scss/tooltips";

@import "assets/scss/default";
@import "assets/scss/developer";
@import "../../common-lib/src/assets/scss/helpers";
@import "../../common-lib/src/assets/scss/editable-content";
@import "assets/scss/print";
@import '../../../node_modules/ngx-toastr/toastr.css';
@import "assets/scss/drag-drop";
@import "assets/scss/table-with-shadows";
@import "assets/scss/spending-table";
@import "assets/scss/drawer";

// styles from bootstrap
* {
  box-sizing: border-box;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h3 {
  font-size: 24px;
}
// end bootstrap styles

.mat-select-panel.mat-select-fixed-position.metric-type {
  margin-top: 41px;
}

.mat-pl-menu.navigation-menu {
  max-width: 390px;
  margin-top: 4px;
  max-height: 435px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.mat-pl-menu {
  .divider {
    position: relative;
    height: 39px;
    border-top: 1px solid #DADCE0;
  }

  .group-title {
    height: 37px;
    font-weight: 600;
    font-size: 15px;
    color: var(--navy-blue);
  }

  .item-danger {
    > span {
      color: var(--corporate-red);
    }

    &:hover {
      background-color: var(--status-over-budget_a10);
    }

    .fa-primary {
      fill: var(--corporate-red);
    }

    .fa-secondary {
      fill: var(--corporate-red-a-06);
    }

    fa-icon {
      --fa-primary-color: var(--corporate-red);
    }
  }
}

.navigation-menu.recently-viewed,
.navigation-menu.recently-added {
  max-height: 400px;
}

.manage-budget-panel + * .mat-pl-menu {
  min-width: 300px;
}

.mat-pl-menu.navigation-menu .mat-menu-item-submenu-trigger {
  padding-right: 16px;
}

.mat-pl-menu.navigation-menu .mat-menu-item-submenu-trigger::after {
  content: none;
}

.mat-pl-menu.navigation-menu .mat-menu-item-submenu-trigger span {
  margin-right: auto;
  line-height: 16px;
}

.mat-pl-menu.navigation-menu .mat-menu-item-submenu-trigger .chevron-icon {
  margin-left: 8px;
  font-size: 12px;
}

.mat-pl-menu.navigation-menu .mat-menu-item {
  border-radius: 0;
}

.mat-pl-menu.navigation-menu.history-menu {
  max-height: initial;
  max-width: initial;
}

.mat-pl-menu.history-menu .metric-origin-label {
  margin: 0 8px;
  min-width: 36px;
}

.mat-pl-menu.history-menu .inner-wrapper {
  max-width: 422px;
}

.mat-pl-menu.history-menu .inner-wrapper.elongated {
  max-width: 848px;
}

.mat-pl-menu.navigation-menu .mat-menu-item.active {
  background: #ECF0F5;
  font-weight: 600;
}

.mat-pl-menu.navigation-menu.budget-select .mat-menu-item {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.mat-pl-menu.navigation-menu.budget-select .mat-menu-item.active {
  font-weight: 700;
  background: white;
  color: var(--navy-blue);
}

.mat-pl-menu.navigation-menu.budget-select .mat-menu-item.active,
.mat-pl-menu.navigation-menu.budget-select .mat-menu-item:hover {
  border-color: #DADCE0;
}

.mat-pl-menu.integration-mapping-menu .mat-menu-item {
  min-width: 250px;
}

.mat-pl-menu.integration-mapping-menu .menu-item-icon {
  margin-right: 8px;
  font-size: 19px;
}

.search-field mat-form-field.outline .mat-input-element {
  color: var(--navy-blue);
}

.search-field mat-form-field.outline.mat-focused .mat-input-element {
  font-weight: 500;
}

.search-field mat-form-field.outline .mat-input-element::placeholder {
  transition: none;
  color: #999999;
}

.search-field mat-form-field.outline.mat-focused .mat-input-element::placeholder {
  visibility: hidden;
}

.mat-menu-item.auth-expiry-notifier:has(.integrations-icon) {
  
  &::after {
    content: "" !important;
    display: inline-block !important;
    position: absolute;
    border: 2px solid #fff;
    border-radius: 50%;
    top: 6px;
    left: 14px;
    width: 6px;
    height: 6px;
    z-index: 1;
    background-color: #FD5830 // var(--corporate-magenta);
  }
}


.header-action-item.with-icon.mat-icon-button:has(.auth-expiry-notifier) {
  &::after {
    content: "" ;
    display: inline-block;
    position: absolute;
    border: 2px solid #fff;
    border-radius: 50%;
    top: 6px;
    left: 24px;
    width: 6px;
    height: 6px;
    z-index: 1;
    background-color: #FD5830 //var(--corporate-magenta);
  }
}


.mat-menu-item:not([disabled]):has(.auth-expiry-notifier) {
  
  &::after {
    content: "" !important;
    display: inline-block !important;
    position: absolute;
    border: 2px solid #fff;
    border-radius: 50%;
    top: 6px;
    left: 14px; 
    width: 6px;
    height: 6px;
    z-index: 1;
    background-color: #FD5830 //var(--corporate-magenta);
  }

  &:has(.linkedin_ads, .facebook_ads){
    &::after {
      top: 4px;
      left: 25px;
    }
  }

  &:has(.google_ads) {
    &::after {
      top: 7px;
      left: 23px;
    }
  }


}
.mat-pl-menu .mat-menu-item[disabled].auth-expiry-warn {
  background-color: #fbe6e6;
  color: #b3a3a4;
  cursor: default;  
  opacity: 1;

  fa-icon {
    --fa-primary-color:#ff4513;
  }
}

/* FILTERS OVERLAY */

.cdk-overlay-backdrop.filters-overlay::after {
  content: '';
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  background: var(--corporate-navy-violet);
  opacity: 0.8;
}

/* GENERIC PAGE LAYOUT */
:root {
  --page-container-max-width: 2150px;
  --page-container-padding: 30px;
  --table-height-reduction: 325px;
}

.app-page {
  height: calc(100vh - 50px);
  overflow: auto;
}

.app-page page-header {
  display: block;
  max-width: var(--page-container-max-width);
  margin: 0 auto;
}

.page-content {
  width: 100%;
  height: 100%;
  padding-top: 12px;
}

.page-container {
  width: 100%;
  max-width: var(--page-container-max-width);
  margin: 0 auto;
  padding: 0 var(--page-container-padding);
}

.toast-container {
  bottom: 17px;

  &.go-back-toast {
    width: 100%;
    z-index: 998; // should be lower than top-filters backdrop and higher than drawers

    .ngx-toastr {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
