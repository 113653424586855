.auth-page {
  width: 100%;
  height: 100%;
  display: flex;
}

.auth-page .logo {
  width: 195px;
  margin: 0 auto;
  padding-bottom: 37px;
  position: unset;
  height: unset;
  text-align: center;
}

.auth-page-content {
  position: relative;
  padding-top: 20vh;
  margin: 0 auto;

  &.split-view {
    padding-top: calc(15vh - 10px);
    padding-right: 150px;
    flex: 0 0 50%;

    .login-form-container {
      margin-left: auto;
    }
  }

  .auth-page-content-title {
    color: var(--navy-blue);
    font-size: 30px;
    font-family: 'DM Serif Text', serif;
    text-align: center;
    padding-top: 23px;
    padding-bottom: 30px;

    &.title-big {
      font-size: 48px;
      line-height: 48px;
      padding-bottom: 21px;
    }
  }
}

.forgot-password-screen .auth-page-content {
  flex: unset;
  padding-right: 0;
  width: 100%;
}

.auth-page.login-screen .auth-page-form input,
.auth-page.forgot-password-screen .auth-page-form input {
  text-align: left;
}

.auth-page-content .auth-page-form {
  padding: 23px 70px;
}

.auth-page-content-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
  padding: 0;
  text-align: center;
  color: rgb(39, 86, 138);
}

.auth-page .auth-page-text {
  font-size: 14px;
  font-weight: normal;
  color: #505f66;
  margin-bottom: 5px;
}

.password-form-field {
  position: relative;

  .form-field-input {
    padding-right: 25px;
  }

  .toggle-visibility-btn {
    width: 20px;
    padding: 0;
    background: transparent;
    position: absolute;
    top: 33px;
    right: 6px;

    fa-icon {
      --fa-primary-color: var(--blue-grey-medium);
    }
  }
}
