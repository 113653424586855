.font-monospace {
  font-family: 'B612', monospace;
}

.font-w-500 {
  font-weight: 500;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left !important;
}

.mt-05 {
  margin-top: .5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-05 {
  margin-bottom: .5rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.ml-a {
  margin-left: auto;
}

.ml-05 {
  margin-left: .5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-025 {
  margin-right: .25rem !important;
}

.mr-05 {
  margin-right: .5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.full-width {
  width: 100%;
}

.p-1 {
  padding: 1rem !important;
}

.fd-column {
  flex-direction: column;
}

.d-flex {
  display: flex;
}

.d-i-flex {
  display: inline-flex;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.ai-center {
  align-items: center;
}

.as-start {
  align-self: flex-start;
}

.no-wrap {
  white-space: nowrap;
}

.color-primary {
  color: var(--primary);
}

.color-accent {
  color: var(--corporate-magenta);
}

.color-danger {
  color: var(--corporate-red) !important;
}

.p-0 {
  padding: 0 !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.hide-arrows {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance:textfield;
  }
}

.opacity-04 {
  opacity: .4;
}

.w-100 {
  flex-basis: 100%;
  max-width: 100%;
}

.w-50 {
  flex-basis: 50%;
  max-width: 50%;
}

.w-25 {
  flex-basis: 25%;
  max-width: 25%;
}

.w-20 {
  flex-basis: 20%;
  max-width: 20%;
}

.is-disabled {
  pointer-events: none;
}
