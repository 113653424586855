.editable-content-control {
  --placeholder-font-size: 12px;
  --placeholder-font-weight: 500;
  --placeholder-font-style: normal;
  --placeholder-color: #A0A0A0;
  --error-color: red;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 44px;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  color: var(--primary);
  font-weight: 500;

  &.is-readonly {
    cursor: default;
  }

  &.is-number {
    font-family: 'B612', monospace;

    input[type='number'] {
      font-family: inherit;
    }
  }

  &:not(.is-readonly):hover,
  &.edit-mode {
    border-color: var(--primary);
    background: #fff;
  }

  &.edit-mode {
    textarea,
    input {
      display: block;
    }
  }

  textarea, input {
    display: none;
    width: 100%;
    max-height: 28px;
    height: 14px;
    padding: 0 10px !important;
    min-height: auto;
    font-size: 12px;
    line-height: 14px;
    color: var(--primary);
    overflow: auto !important;
    word-break: break-all;
    transition: none;
    background-color: transparent;

    &:focus {
      color: var(--primary);
    }

    &::placeholder {
      font-size: var(--placeholder-font-size);
      font-weight: var(--placeholder-font-weight);
      font-style: var(--placeholder-font-style);
      color: var(--placeholder-color);
    }

    &::-webkit-input-placeholder {
      font-size: var(--placeholder-font-size);
      font-weight: var(--placeholder-font-weight);
      font-style: var(--placeholder-font-style);
      color: var(--placeholder-color);
    }

    &::-moz-placeholder {
      font-size: var(--placeholder-font-size);
      font-weight: var(--placeholder-font-weight);
      font-style: var(--placeholder-font-style);
      color: var(--placeholder-color);
    }

    &::-ms-input-placeholder {
      font-size: var(--placeholder-font-size);
      font-weight: var(--placeholder-font-weight);
      font-style: var(--placeholder-font-style);
      color: var(--placeholder-color);
    }
  }

  .read-value {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    max-height: 28px;
    padding: 0 10px;
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    word-break: break-word;
  }


  ::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 50px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 2px;
    box-shadow: none;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    border: 3px solid rgba(0, 0, 0, .4);
    background-color: #fff;
    background-clip: padding-box;
    opacity: .8;
  }

  .error-message {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    opacity: 0;
    visibility: hidden;
    padding: 5px 10px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(233, 48, 2, 0.12);
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    color: var(--error-color);
    transition: .2s ease;
    z-index: 1;
  }

  &.is-empty .read-value {
    color: var(--placeholder-color);
  }

  &.has-errors {
    border-color: var(--error-color) !important;

    &.edit-mode .error-message,
    &:hover .error-message {
      opacity: 1;
      visibility: visible;
    }
  }
}
