@charset "utf-8";
/* CSS Document */
body {
  font-family: 'DM Sans', sans-serif;
  direction: ltr;
  font-size: 13px;
  line-height: 22px;
  padding: 0;
  margin: 0;
  color: #272727;
  font-weight: 500;
}

input, select, textarea, button {
  font-family: 'DM Sans', sans-serif;
  direction: ltr;
  color: #8b8b8b;
  font-size: 13px;
  margin: 0px;
  padding: 10px;
  border: none;
  box-shadow: none;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

select {
  cursor: pointer;
  outline: none !important;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
  outline: none;
}

select option {
  padding-left: 4px;
}

p {
  margin: 0px;
  padding-bottom: 15px;
}

input, textarea, a, * {
  outline: none;
}

a {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

a, * {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

a.carousel-control.left, a.carousel-control.right {
  background: none;
}

input[type=checkbox] {
  height: 13px;
  width: 13px;
  margin-right: 5px;
  position: relative;
  top: 1px;
}

input[type=submit], input[type=button], input[type=reset], a, button {
  cursor: pointer;
}

input[type=submit], input[type=button], input[type=reset], button {
  overflow: visible;
}

input[type=submit] + input[type=submit], input[type=button] + input[type=button], input[type=reset] + input[type=reset], button + button, .btn + .btn, input[type=submit] + input[type=button], input[type=button] + input[type=submit] {
  margin-left: 5px;
}

input[type=radio] {
  width: 13px;
  height: 13px;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

input[type=file] {
  padding: 4px 5px;
  display: inline;
}

input[type=file] + input {
  margin-left: 5px;
}

input[type=text]:active, input[type=text]:focus, input[type=password]:active, input[type=password]:focus, textarea:active, textarea:focus, textarea.form-control:active, textarea.form-control:focus, select:active, select:focus {
  box-shadow: none;
}

textarea {
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  resize: none;
}

a {
  color: #0872ba;
}

a:hover, a:focus, a:active {
  color: #FFF;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none;
}

select:not(.form-field-input), input {
  color: #FFF;
  height: 33px;
  font-size: 15px;
  width: 100%;
  text-align: center;
  background-color: #407cbc;
  font-family: 'DM Sans', sans-serif;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 33px;
}

select {
  position: relative;
  z-index: 0;
  text-align: left;
}

.box-rightBule select:focus, .box-blueBG select:focus {
  color: #FFF;
}

select option {
  color: #FFF;
  padding: 7px 11px;
  background-color: #5c94d0;
}

mark {
  color: inherit;
  background: #FFE1B3;
  padding-left: 0;
  padding-right: 0;
}

.box-spreadSheet select option {
  color: #4E4E4E;
  padding: 7px 11px;
  background-color: #D2D2D2;
}

.box-selectBox {
  position: relative;
  width: 100%;
  padding: 0px;
  max-width: 160px;
  margin: auto;
  background-color: #407cbc;
}

.box-selectBox:after {
  position: absolute;
  top: 0px;
  right: 10px;
  bottom: 0px;
  content: '';
  width: 10px;
  height: 6px;
  margin: auto;
  background: url('../images/icon-downArrow.png') no-repeat center center;
}

.box-selectBox select {
  background-color: transparent;
  padding-right: 28px;
  width: 100%;
  overflow: hidden;
}

::-webkit-input-placeholder {
  font-family: 'DM Sans', sans-serif;
  direction: ltr;
  font-size: 15px;
  color: #FFF;
}

::-moz-placeholder {
  font-family: 'DM Sans', sans-serif;
  direction: ltr;
  font-size: 15px;
  color: #FFF;
  opacity: 1;
}

:-ms-input-placeholder {
  font-family: 'DM Sans', sans-serif;
  direction: ltr;
  font-size: 15px;
  color: #FFF;
  opacity: 1;
}

input:-moz-placeholder {
  font-family: 'DM Sans', sans-serif;
  direction: ltr;
  font-size: 15px;
  color: #FFF;
  opacity: 1;
}

.box-searchManageTeam ::-webkit-input-placeholder {
  font-size: 14px;
  color: #575757;
}

.box-searchManageTeam ::-moz-placeholder {
  font-size: 14px;
  color: #575757;
}

.box-searchManageTeam :-ms-input-placeholder {
  font-size: 14px;
  color: #575757;
}

.box-searchManageTeam input:-moz-placeholder {
  font-size: 14px;
  color: #575757;
}

.nav-bigSvgIcon {
  display: none;
}

html, span, applet, object, iframe, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, i, center, dl, dt, dd, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0px;
  padding: 0px;
}

footer, header, figure, aside {
  margin: 0px;
  padding: 0px;
  display: block;
}

article, details, figcaption, hgroup, menu, nav, section {
  display: block;
}

a:visited {
  text-decoration: none;
}

a img, a:hover img, img, a:hover, a input {
  text-decoration: none;
}

a img, img, input[type=checkbox], input[type=radio], iframe {
  border: none;
}

ul {
  margin: 0px;
  padding: 0px;
}

ul li {
  list-style: none;
  padding: 0px;
}

input, input:focus, input:active, .btn:focus, .btn:active:focus, .btn.active:focus {
  outline: none -webkit-focus-ring-color !important;
}

img {
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-button {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #DDD;
  border: skinny plain lightgray;
  box-shadow: 0px 0px 3px #dfdfdf inset;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border: skinny plain gray;
}

::-webkit-scrollbar-thumb:hover {
  background: #1b1e24;
}

::selection {
  background-color: var(--selection-bg);
}

::-moz-selection {
  background-color: var(--selection-bg);
  color: #CCC;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  padding-bottom: 15px;
}

h2 {
  color: #FFF;
  font-size: 20px;
}

.required-field {
  position: absolute;
  font-size: 17px;
  color: #ff8f8f;
}

thead tr {
  height: 30px;
}

label {
  font-family: 'DM Sans', sans-serif !important;
}

/*-------------------------------------------------------------------------------------------RESET CSS CLOSE*/
/*-------------------------------------------------------------------------------------------COMMON CSS START*/
.pointable {
  cursor: pointer
}

.error-signup {
  margin: 0 0 30px 0;
  line-height: 0;
}

.error {
  position: relative;
  color: var(--red-alert);
  letter-spacing: 0.2px;
  line-height: 17px;
  padding-top: 3px;
  z-index: 9;
}

.fa {
  min-width: 14px;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.container {
  max-width: 1120px;
  padding: 0px;
  width: 100%;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  margin: 0px auto;
}

.new-loader {
  transition: opacity .25s ease-out, visibility 0.4s ease-out;
}

.new-loader span,
.new-loader {
  position: absolute;
  z-index: 9999;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: -1px;
  margin: auto;
  background-color: var(--corporate-navy-violet_08);
}

.new-loader span {
  width: 40px;
  height: 54px;
  background: transparent url("../images/planful-animated-loader.svg") no-repeat center center;
  background-size: 85%;
}

.new-loader {
  opacity: 1;
  visibility: visible;
}

[hidden].new-loader {
  transition: opacity .35s ease-out;
  display: block;
  visibility: hidden;
  opacity: 0;
}

.box-height140 {
  min-height: 40px;
  height: 120px;
  overflow: hidden;
  margin-bottom: 0px !important;
}

.box-tabScroll {
  height: 100%;
}

.box-tabScroll .scroll-content {
  max-height: 120px !important;
}

.colorWhite, .colorWhite:hover, .colorWhite:focus {
  color: #FFF;
  cursor: default;
}

/*-------------------------------------------------------------------------------------------COMMON CSS CLOSE*/
/**Bootstrap CSS Changes Start*/
.progress-bar {
  background-color: #f9a21a;
}

.row + .row {
  padding-top: 15px;
}

/**Bootstrap CSS Changes End*/
.LogoutLink-removable {
  color: #e85353;
  font-size: 17px;
  position: fixed;
  right: 26px;
  top: 19px;
  z-index: 9999999999;
}

.LogoutLink-removable img {
  position: relative;
  top: -2px;
  padding-right: 3px;
}

.LogoutLink-removable:hover, .LogoutLink-removable:focus {
  color: #e85353;
}

#wapper:after {
  position: absolute;
  content: '';
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto;
  background-color: rgba(39, 39, 39, 0.84);
}

.box-vHeight {
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
}

.box-vHeightInn {
  display: table-cell;
  vertical-align: middle;
}

.box-flexAllCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-flexAllCenterInn {
  width: 100%;
}

.box-wapper, #wapper, html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  float: left;
}

#wapper {
  position: relative;
  background: url("../images/bg-image.jpg") no-repeat center center;
  background-size: cover;
}

.box-wapper {
  position: relative;
  z-index: 990;
  overflow: auto;
}

.box-signUp {
  width: 100%;
  padding: 15px;
  display: inline-block;
}

.box-signUp h4 {
  color: #FFF;
  font-size: 23px;
  padding-bottom: 42px;
  font-weight: 500;
}

.box-signUpInn {
  width: 100%;
  max-width: 410px;
  display: inline-block;
  background-color: #3272b7;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.4);
}

.box-inputField {
  width: 100%;
  max-width: 300px;
  padding: 30px 15px;
  margin: auto auto 23px;
  display: inline-block;
  overflow-y: auto;
}

#form-signUp .box-inputField {
  margin-bottom: 0px;
  padding-bottom: 23px;
}

.box-inputField label {
  color: #91c7ff;
  font-size: 14px;
  display: block;
  padding-bottom: 4px;
  font-weight: 500;
}

.col-firstName {
  padding-right: 3px;
}

.col-lastName {
  padding-left: 3px;
}

.box-setup .box-inputField label, .box-signUpInn label {
  text-align: center;
}

.form-checkbox label {
  position: relative;
  top: -2px;
}

.form-checkbox.box-byMonthOnboarding {
  margin-bottom: 8px;
}

.form-checkbox label {
  position: relative;
  width: 15px;
  height: 15px;
  float: none !important;
  padding: 0px;
  border-radius: 0px;
  border: 0;
  margin-right: 10px;
  display: inline-block;
  background-color: transparent;
  box-shadow: none;
  opacity: 1;
}

.form-checkbox label, .form-checkbox label:hover {
  background-color: transparent;
}

.form-checkbox label:after {
  position: absolute;
  content: '';
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 15px;
  height: 15px;
  margin: auto;
  border: 2px solid #FFF;
  background-color: transparent;
}

.form-checkbox label.active, .form-checkbox label.btn.active:active, .form-checkbox label.btn.active:hover {
  background-color: #00bcd4 !important;
  box-shadow: none;
  opacity: 1;
}

.form-checkbox label#acceptTermsCheck.active, .form-checkbox label#acceptTermsCheck.btn.active:active, .form-checkbox label#acceptTermsCheck.btn.active:hover,
.box-rightBule .form-checkbox label.active, .box-rightBule .form-checkbox label.active:active, .box-rightBule .form-checkbox label.active:hover,
.box-blueBG .form-checkbox label.active, .box-blueBG .form-checkbox label.btn.active:active, .box-blueBG .form-checkbox label.btn.active:hover {
  background-color: #28a264 !important;
  box-shadow: none;
  opacity: 1;
}

.form-checkbox label input {
  margin: 0px;
  z-index: 9999;
}

.form-checkbox a {
  color: #FFF;
}

.btn-green:hover {
  color: #FFF;
}

.pass-login {
  margin-bottom: 25px;
}

.link-createAcc {
  background-color: transparent;
  color: #91c7ff;
  font-size: 11px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.link-createAcc:hover, .link-createAcc:focus {
  color: #FFF;
}

.marginTop20 {
  margin-top: 25px;
}

/* Setup Page Start */
.box-setup h2 {
  padding-bottom: 83px;
}

.box-setupHeadingPara {
  color: #FFF;
  font-size: 19px;
  line-height: 27px;
}

.box-setupHeadingPara span {
  display: block;
}

ul.list-setup {
  max-width: 820px;
  width: 100%;
  display: inline-block;
  margin: auto;
  padding-top: 52px;
}

ul.list-setup li {
  width: 50%;
  float: left;
  padding: 0px 45px;
}

.box-setupCol {
  width: 100%;
  display: inline-block;
  box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.17);
  background-color: #f6f6f6;
}

.cir-mostCom {
  position: absolute;
  top: -24px;
  right: -28px;
  width: 63px;
  height: 63px;
  color: #FFF;
  font-size: 10px;
  border-radius: 50%;
  background-color: #f3bd48;
  line-height: normal;
  padding-top: 19px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.26);
}

ol.top-slideBar {
  padding: 0px 60px;
}

.box-setup .carousel-inner > .item {
  min-height: 600px;
}

/* Setup Page End */
/* Advanced Page 1 Start */
#form-advanced .box-inputField {
  padding-top: 52px;
  height: 341px;
}

.form-checkbox + .form-checkbox {
  margin-left: 25px;
}

.form-group.form-checkboxMain {
  margin-bottom: 0px;
  display: inline-block;
}

.link-priv {
  position: absolute;
  bottom: 65px;
  left: 15px;
  margin: auto;
  width: 25px;
  height: 25px;
  line-height: 21px;
  display: block;
  border: 1px solid #66a4e5;
  border-radius: 50%;
}

.link-priv:hover {
  background-color: #66a4e5;
}

/* Advanced Page 1 End */
/* Advanced Page 2 (Buget name) Start */

.box-customFilePlan label {
  float: left !important;
}

.box-customFilePlan span {
  display: block;
  margin-left: 30px;
}

#from-date input {
  cursor: pointer;
}

ul.list-programCard > li, ul.list-programCard, #end-date input, #end-date span {
  cursor: default !important;
}

/* Advanced Page 2 (Buget name) End */
/* Graph Start */

/* Graph Start End */
.ng2-datetime-picker-wrapper {
  float: left;
}

.btn-radioLine {
  margin-left: 15px !important;
}

.btn.btn-radio input[type="checkbox"], .btn.btn-radio input[type="radio"],
.btn-group > .btn.btn-radio input[type="checkbox"], .btn-group > .btn-radio input[type="radio"] {
  pointer-events: inherit;
  clip: inherit;
  top: -2px;
  left: -2px;
  width: 19px;
  height: 19px;
  background-color: transparent;
  cursor: pointer;
}

input[disabled] {
  cursor: default !important;
}

.box-radioCheckBtn {
  position: relative;
}

.box-radioCheckBtn + .box-radioCheckBtn {
  margin-top: 10px;
}

.col-assignBuget {
  width: calc(50% - 5px);
  padding-left: 8px;
  padding-right: 8px;
  float: none;
  display: inline-block;
}

.col-assignBuget + .col-assignBuget + .col-assignBuget {
  padding-top: 15px;
}

.col-assignBuget label {
  font-size: 15px;
  padding-bottom: 6px;
}

.dollerSign {
  position: relative;
  display: block;
}

.dollerSign:after {
  position: absolute;
  content: '$';
  font-size: 12px;
  width: 10px;
  height: 20px;
  line-height: 21px;
  z-index: 1;
  left: 12px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  text-align: center;
}

.dollerSign input {
  padding-left: 25px;
  padding-right: 25px;
}

.cus-tooltip {
  position: absolute;
  top: -6px;
  left: 24px;
  z-index: 99;
  width: 195px;
  color: #f6f7fc;
  font-size: 12px;
  display: block;
  text-align: center;
  border-radius: 2px;
  padding: 3px 1px;
  background-color: #000;
  cursor: default;
}

.cus-tooltip:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  margin: auto;
  border-style: solid;
  border-width: 5px 5px 5px 0px;
  border-color: transparent #000 transparent transparent;
  left: -5px;
  top: 0px;
  bottom: 1px;
}

.cus-tooltipOn {
  font-style: normal;
  max-width: 87px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
  margin-bottom: -7px;
  white-space: nowrap;
}

.modal-headerInnBox {
  position: relative;
  float: left;
  display: block;
}

.modal-headerInnBox.modal-headerGoalView {
  width: calc(100% - 40px);
}

.modal-headerInnBox.edit-goal-header-box {
  width: calc(100% - 15px);
}

.modal-headerInnBox a {
  position: relative;
  display: ruby-base;
}

.modal-headerInnBox .title-addGoal {
  position: relative;
  max-width: 100%;
  float: left;
  padding-top: 7px;
  padding-right: 100px;
  margin-top: 0px;
}

.modal-headerInnBox .tooltip-txtOpen .title-addGoal {
  padding-right: 80px;
}

.modal-headerInnBox .tooltip-txtPlanned .title-addGoal {
  padding-right: 114px;
}

.modal-headerInnBox .tooltip-txtCommitted .title-addGoal {
  padding-right: 135px;
}

.modal-headerInnBox .btn-tooltipCust, .modal-headerInnBox .title-addGoal button {
  top: 2px;
  right: 87px;
}

.modal-headerInnBox .tooltip-txtOpen .btn-tooltipCust, .modal-headerInnBox .tooltip-txtOpen .title-addGoal button {
  right: 67px;
}

.modal-headerInnBox .tooltip-txtPlanned .btn-tooltipCust, .modal-headerInnBox .tooltip-txtPlanned .title-addGoal button {
  right: 101px;
}

.modal-headerInnBox .tooltip-txtCommitted .btn-tooltipCust, .modal-headerInnBox .tooltip-txtCommitted .title-addGoal button {
  right: 122px;
}

.btn-tooltipCust .cus-tooltip {
  display: none;
  width: 300px;
  font-size: 12px !important;
  line-height: 18px;
  padding: 15px;
  color: #4e4e4e;
  text-align: left;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.btn-tooltipCust:hover .cus-tooltip {
  display: block;
}

.btn-tooltipCust .cus-tooltip.left-arrow {
  top: -13px;
  left: 24px;
  bottom: inherit;
  right: inherit;
}

.btn-tooltipCust .cus-tooltip.left-arrow:after {
  top: 11px;
  left: -7px;
  right: auto;
  bottom: inherit;
  border-width: 7px 9px 7px 0px;
  border-color: transparent #FFF transparent transparent;
}

ul.list-graphAddProdInn, ul.list-graphAddProd, ul.list-graphAddProd > li {
  width: 100%;
  float: left;
}

.animation-zoomIn, .animation-bottomTOtop, .animation-topTObottom, .animation-rightTOleft {
  position: relative;
  animation-direction: alternate;
}

ul.list-graphAddProdInn {
  margin-bottom: 2px;
  text-align: left;
}

.animation-rightTOleft {
  animation: rightTOleft 0.4s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes rightTOleft {
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}

@keyframes rightTOleft {
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}

.animation-topTObottom {
  animation: topTObottom 0.4s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes topTObottom {
  0% {
    top: -100%;
  }
  100% {
    top: 0%;
  }
}

@keyframes topTObottom {
  0% {
    top: -100%;
  }
  100% {
    top: 0%;
  }
}

.animation-bottomTOtop {
  animation: bottomTOtop 0.4s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes bottomTOtop {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0%;
  }
}

@keyframes bottomTOtop {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0%;
  }
}

.animation-zoomIn {
  position: static;
  animation: zoomIn 0.4s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

tfoot tr {
  cursor: default !important;
}

ul.list-graphAddProd {
  position: relative;
  z-index: 999;
  margin-bottom: 4px;
  background-color: #e4ebf3;
}

ul.list-graphAddProdTotal {
  margin-bottom: 0px;
}

ul.list-graphAddProd > li {
  position: relative;
  overflow: hidden;
}

ul.list-graphAddProd > li:first-child:before {
  border-top: 0px;
}

ul.list-graphAddProd > li:last-child:after {
  border-bottom: 0px;
}

ul.list-graphAddProdInn {
  position: relative;
  z-index: 1;
}

ul.list-graphAddProdInn li {
  position: relative;
  top: 1px;
  width: 88px;
  margin: 0px 1px;
  float: left;
  height: 39px;
  background-color: #6eb0fc;
}

ul.list-graphAddProdInn li:first-child {
  margin-left: 0px;
}

ul.list-graphAddProdInn li:last-child {
  margin-right: 0px;
}

ul.list-graphAddProdInn li input {
  width: 80px;
  height: 30px;
  font-size: 14px;
  text-align: left;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: #76b6fc;
  padding: 0px 5px 0px 20px;
}

.breakTxt, ul.list-total-remening li span {
  font-size: 13px !important;
  line-height: 12px !important;
  white-space: normal !important;
}

/* Accordion Page Start */
.bg-graphAddProd {
  position: relative;
}

.bg-graphAddProd:after {
  position: absolute;
  content: "";
  right: 15px;
  top: 62px;
  left: 0;
  bottom: 0;
  background-color: #EFEBF8;
}

.modal-accordions .panel-default {
  border: 0px;
  box-shadow: none;
  border-radius: 0px;
}

.modal-accordions .panel-default + .panel-default {
  margin-top: 23px;
}

.link-skip {
  font-size: 12px;
  color: #869bf0;
  margin-top: 12px;
  display: block;
}

.link-skip:focus, .link-skip:hover {
  color: #FFF;
}

.month ul.list-graphAddProdInn {
  width: 1078px;
}

/* Accordion Page End */
/*Menubar Start*/
.frow-manageTeamImg > span {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  color: #FFF;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  background-color: #75a8dc;
  text-transform: uppercase;
}

.frow-manageTeamImg {
  float: left;
}

.frow-manageTeamImg > span {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 3px solid #265689;
  background-color: #75a8dc;
  box-shadow: 0px 0px 10px rgba(173, 138, 255, 0.3);
  background-size: 100%;
  overflow: hidden;
}


.frow-manageTeamImg > span > img {
  max-width: 100%;
  max-height: 100%;
  -ms-interpolation-mode: bicubic !important;
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .my_elements_with_border_radius {
    border-radius: 0
  }
  .modal-headerInnBox a {
    display: -ms-inline-flexbox;
  }
  .modal-headerInnBox .title-addGoal a {
    display: inline;
  }
}

.box-profileCount {
  position: absolute;
  color: #FFF;
  font-size: 10px;
  top: -5px;
  right: -6px;
  width: 22px;
  height: 22px;
  line-height: 23px;
  border-radius: 50%;
  background-color: #e91d1d;
  text-align: center;
}

/*Menubar Start*/
.box-rightSide {
  position: relative;
  float: left;
  height: 100%;
  width: calc(100% - 300px);
  margin-left: 75px;
  overflow: hidden;
  transition: width .2s ease-out
}

.new-nav .box-rightSide {
  width: calc(100% - 225px);
  margin-left: 0;
  height: calc(100% - 60px);
}

.box-wapperWidget .box-rightSide, .manage-sheet-empty .box-rightSide, .box-rightSide.section-removeBlue, .box-wapperTwo .box-rightSide {
  width: calc(100% - 75px);
}

.box-wapperWidget.new-nav .box-rightSide, .box-wapperTwo.new-nav .box-rightSide {
  width: 100%;
  margin: 0;
}

.box-topBar {
  position: relative;
  width: 100%;
  height: 64px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 30px;
  background-color: #FFF;
  z-index: 9999;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15)
}

.box-newTopNav .box-topBar {
  height: 65px;
  padding-left: 29px;
  margin-left: 75px;
  width: calc(100% - 75px);
  box-shadow: none;
  border-bottom: 1px solid #D4D4D4;
}

.box-newTopNav.new-nav .box-topBar {
  margin-left: 0;
  width: 100%;
}

.box-searchTopNav.box-newTopNav .box-rightBule {
  height: calc(100% - 65px);
}

.close:after, .close:before {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  content: '';
  width: 2px;
  height: 20px;
  background-color: #FFF;
  margin: auto;
}

.close:before {
  width: 20px;
  height: 2px;
}

/*Modal Popup Start*/
.modal-content modal-content, .modal-content, .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: auto;
  padding: 0px;
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

.modal-content modal-content {
  display: flex;
}

.box-modalContent {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  background-color: transparent;
  width: 860px;
  margin: auto;
  padding: 35px 28px 15px;
  align-items: center;
}

.box-modalContentInn {
  border: none;
}

.box-modalContentPara {
  position: relative;
}

.box-modalContentParaInn {
  border-radius: 4px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

.modal-header {
  position: relative;
  z-index: 99;
  color: #FFF;
  font-size: 16px;
  border: none;
  text-transform: uppercase;
  padding: 23px 30px 20px;
  background-color: #323232;
}

.modal-header span {
  font-size: 16px;
}

.modal-header h4 {
  padding: 0px;
  text-align: center;
}

.modal-body {
  padding: 45px 30px 70px;
  background-color: #FFF;
}

.close {
  position: absolute;
  top: -28px;
  right: -28px;
  width: 20px;
  height: 20px;
  padding: 0px;
  opacity: 1;
  background-color: transparent;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.close:hover {
  opacity: 1;
  background-color: transparent;
}

/*Add Option Button End*/
.modal-headerRightList {
  padding: 19px 0px 0px 30px;
}

.row-addGoal {
  margin: auto;
  align-items: center;
}

.row-addGoal h5 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}

.box-modalAddGoal .modal-body {
  padding-bottom: 0px;
}

.modalBody-assignAcc {
  padding-bottom: 10px !important;
}

.box-modalAddGoal .modal-bodyAddExpense {
  padding-bottom: 20px !important;
}

.link-addGoalAccordion {
  position: relative;
  font-size: 19px;
  color: #4e4e4e;
  text-transform: uppercase;
  width: 100%;
  display: inline-block;
  padding: 12px 0px 0px 75px;
  height: 52px;
  font-weight: 600;
}

.box-userTypeMain .link-addGoalAccordion,
.box-bodyScroll .link-addGoalAccordion {
  position: relative;
  font-size: 19px;
  color: #FFF;
  text-transform: uppercase;
  width: 100%;
  display: inline-block;
  padding: 15px 0px 0px 75px;
  height: 52px;
  font-weight: 600;
  background-color: #5b8ec5;
  border-radius: 36px 0px 0px 36px;
}

.link-addGoalAccordion:hover, .link-addGoalAccordion:focus {
  color: #4e4e4e;
}

.link-addGoalAccordion span {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 27px;
  text-align: center;
  line-height: 48px;
  color: #00bcd4;
  float: left;
  width: 52px;
  height: 52px;
  border: 2px solid #7fdde9;
  border-radius: 50%;
}

.link-addGoalAccordion em {
  font-style: normal;
  font-size: 12px
}

.link-addGoalAccordion.collapsed span {
  color: #9ee6ef;
  border-color: #ccf2f6;
}

.box-userTypeMain .link-addGoalAccordion:hover, .box-userTypeMain .link-addGoalAccordion:focus,
.box-bodyScroll .link-addGoalAccordion:hover, .box-bodyScroll .link-addGoalAccordion:focus {
  color: #FFF;
}

.box-userTypeMain .link-addGoalAccordion span, .box-userTypeMain .link-addGoalAccordion.collapsed span,
.box-bodyScroll .link-addGoalAccordion span, .box-bodyScroll .link-addGoalAccordion.collapsed span {
  color: #5b8ec5;
  border-color: #5b8ec5;
  background-color: #FFF;
}

.modal {
  overflow-y: auto;
}

.modal-accordions {
  margin-bottom: 0px;
}

.modal-accordions .form-checkbox {
  color: #4e4e4e;
  font-size: 15px;
}

.modal-accordions .panel-body {
  padding: 45px 0px 15px 60px;
}

.box-addUserTop .modal-accordions .panel-body {
  padding-top: 15px !important;
}

.modal-accordions .panel-collapse.in {
  position: relative;
}

.modal-accordions .panel-collapse.in:after {
  position: absolute;
  top: 10px;
  left: 25px;
  bottom: -10px;
  content: "";
  width: 2px;
  margin: auto;
  background-color: #ccf2f6;
}

.modal-accordions .panel-default:last-child .panel-collapse.in:after {
  bottom: 15px;
}

.form-addGoalTimeFrame .form-checkbox {
  margin: 0px;
}

.box-modalAddGoal .modal-footer {
  width: 100%;
  display: block;
  padding: 15px 45px 21px 105px;
  border: 0px;
  background-color: #FFF;
}

.box-modalGLCode.box-modalAddGoal .modal-body {
  padding-top: 45px;
}

.box-modalGLCode.box-modalAddGoal .modal-footer {
  padding: 25px 30px;
}

.box-bodyScroll .modal-footer {
  padding: 15px 30px 21px;
}

.box-modalExpenseType.box-modalAddGoal .modal-body {
  padding: 45px 30px 0px;
}

.box-modalExpenseType.box-modalAddGoal .modal-footer {
  padding: 20px 30px;
}

.box-modalAddGoal .modal-footer.modal-footerEditAll {
  padding-left: 45px;
}

.box-modalAddGoal .modal-body.modal-bodyEditAll {
  padding-top: 45px;
  padding-bottom: 20px;
}

.btn-navBlue {
  font-size: 15px;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  background-color: #3272b7;
  padding: 7px 25px;
  margin-top: 23px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}

.btn-navBlue {
  background-color: #5d72c9;
}

.box-inputAnimation {
  position: relative;
  width: 100%;
  float: left;
  display: block;
}

.box-inputAnimation + .box-inputAnimation {
  margin-top: 40px;
}

.box-inputAnimation label {
  color: #b3b3b3;
  font-size: 15px;
  position: absolute;
  top: 17px;
  left: 10px;
  right: 2px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
  z-index: 1;
  background-color: #FFF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
}

.box-inputAnimation label.trigger {
  font-weight: 400;
}

.expense-planned-field.box-inputAnimation .selection-container label {
  top: unset;
  right: unset;
  left: unset;
  transition: none;
  pointer-events: unset;
  color: #272727;
}

.box-inputAnimationRow {
  max-width: 200px;
}

.input-budgetField input {
  padding-left: 33px !important;
}

.input-budgetField.expense-planned-field input {
  text-align: right !important;
}

.expense-planned-field .amount-in-budget-currency {
  text-align: right;
  font-size: 13px;
  padding: 5px 10px;
  color: #b3b3b3;
}

.currency-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.input-animationW, .input-animation, select.input-animation, select.input-animationW {
  width: 100%;
  color: #424242;
  font-size: 15px;
  text-align: left;
  border: 1px solid #D3D3D3;
  background-color: transparent;
  border-radius: 4px;
  height: 56px;
  line-height: 56px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
}

.input-animationW, select.input-animationW {
  background-color: #FFF;
}

.box-inputCheckBox {
  position: relative;
  padding-right: 150px;
}

.box-inputAnimationInExp .input-animation {
  padding-left: 10px;
}

.box-inputAnimationInExp {
  position: absolute;
  right: -172px;
  top: -2px;
  width: 167px;
}

.box-quickAddProg {
  position: relative;
  width: 100%;
  float: left;
  display: inline-block;
  padding: 40px 120px 0px 0px;
  margin-bottom: 20px;
}

.box-quickAddProg .input-animationW, .box-quickAddProg select.input-animationW, .box-quickAddProg .input-animation, .box-quickAddProg select.input-animation {
  height: 40px;
  line-height: 40px;
}

.box-quickAddProg .box-inputAnimation label {
  top: 9px;
}

.input-deliveryDate .input-animation {
  display: block;
}

textarea.input-animation, textarea.input-animationW {
  height: 125px;
  resize: vertical;
  line-height: 22px;
  float: left;
}

textarea.input-animation ~ label, textarea.input-animationW ~ label {
  top: 11px;
}

.box-rightSideInn {
  height: calc(100% - 60px);
  width: 100%;
  display: block;
  padding: 23px 30px;
  overflow: auto;
}

.box-newDashboardRightInn, .box-dashboardRightInn {
  position: relative;
  overflow: hidden;
  padding: 43px 23px;
}

.box-newDashboardRightInn, .box-dashboardRightInn, .new-nav .box-rightSideInn {
  padding-top: 10px;
  padding-bottom: 10px;
}

ul.nav-tabs {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  border: 0px;
  background-color: transparent;
}

ul.nav-tabs li {
  float: none;
  display: inline-block;
  max-width: 100%;
  border: 0px;
  border-radius: 0px;
  padding: 0px 12px;
}

ul.nav-tabs li:first-child {
  padding-left: 0px;
}

ul.nav-tabs li:last-child {
  padding-right: 0px;
}

ul.nav-tabs > li > a {
  color: #bebebe;
  font-size: 14px;
  border: 0px;
  padding: 0px 0px 4px;
  border-radius: 0px;
  border-bottom: 3px solid rgba(0, 0, 0, 0);
}

ul.nav-tabs > li.active > a, ul.nav-tabs > li.active > a:focus, ul.nav-tabs > li.active > a:hover,
ul.nav-tabs > li > a:focus, ul.nav-tabs > li > a:hover {
  color: #1f1f1f;
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  border-bottom: 3px solid #3273b7;
}

.btn-viewGoal, .btn-clearUnread {
  font-size: 11px;
  line-height: 16px;
  padding: 3px 12px;
  border-radius: 20px;
  color: #3272b7;
  background-color: transparent;
  border: 1px solid #3272b7;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 9;
}

.btn-viewGoal:hover, .btn-clearUnread:hover {
  color: #FFF;
  background-color: #3272b7;
}

.btn-viewGoal {
  right: 192px;
}

#resetPass .box-modalContent {
  width: 460px;
}

#resetPass .modal-body {
  padding-bottom: 20px;
}

.box-clearBoth {
  width: 100%;
  clear: both;
  height: 0px;
  display: block;
}

.nav-mainDashTab {
  position: relative;
  width: calc(100% - 75px);
  margin-left: 75px;
  float: left;
  background-color: #EDEDED;
}

.nav-mainDashTab .budget-date {
  position: absolute;
  left: 30px;
  top: 11px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.nav-mainDashTab.nav-mainDashTabNew {
  margin-left: 0px;
  width: 100%;
  background-color: #FFF;
}

.slide-viewMetric {
  margin-bottom: 18px;
}

ul.list-viewExpenseApp:after {
  display: none;
}

ul.list-viewExpenseApp li {
  width: 100%;
  padding: 0px 40px 4px;
}

.object-header-controls {
  position: absolute;
  right: 10px;
  top: 2px;
  transition: none;
}

.modal-headerGoalView .object-header-controls {
  right: 90px;
}

.object-header-controls > * {
  display: inline-block;
}

.tags-number-badge {
  background: #2E6CA4;
  padding: 0 8px 0 12px;
  font-size: 12px;
  position: relative;
  margin-right: 15px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
}

.tags-number-badge:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 0 11px 10px;
  border-color: transparent transparent transparent #2E6CA4;
  left: 100%;
}

.object-tags-container {
  position: absolute;
  top: calc(100% + 20px);
  width: 580px;
  z-index: 10;
  left: -280px;
  background: #fff;
  padding: 20px;
  border: 2px solid #439DD4;
  box-shadow: 6px 6px 0 0 rgba(0, 0, 0, 0.3);
}

.object-tags-container:before {
  content: '';
  position: absolute;
  width: 26px;
  height: 26px;
  transform: rotate(45deg);
  background: #fff;
  top: -14px;
  left: calc(50% - 10px);
  border-top: 2px solid #439DD4;
  border-left: 2px solid #439DD4;
}

.modal-headerGoalView timeframes {
  right: 100px;
}

.box-noMetric {
  color: #CCC;
  font-size: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center !important;
  width: 100%;
  flex-direction: column;
}

.box-noMetric h6 {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
}

.actual-value, .projected-value {
  position: relative;
  color: #323232;
  font-style: normal;
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;
}

.actual-value, .projected-value {
  display: inline-block;
  min-width: 6px;
  min-height: 14px;
}

.actual-value.removeDollar, .projected-value.removeDollar {
  padding-left: 0px;
}

.owl-theme .owl-nav {
  margin-top: 0px !important;
}

.owl-theme .owl-nav [class*='owl-'] {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 11px;
  margin: auto !important;
  width: 29px;
  height: 29px;
  opacity: 1 !important;
  border-radius: 50% !important;
  border: 1px solid #e1e1e1 !important;
  background: url(../images/icon-leftRight.png) no-repeat 10px center #FFF !important;
  text-indent: -999em;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: url(../images/icon-leftRightW.png) no-repeat 10px center #26cfe5 !important;
  border-color: #26cfe5;
}

.owl-prev > div:hover {
  background-color: #e1e1e1;
}

.owl-theme .owl-nav > [class*='owl-'].disabled {
  display: none !important;
}

.box-tabViewGoal {
  width: 100%;
  display: inline-block;
  padding-bottom: 10px;
}

.owl-theme .owl-nav [class*='owl-'].owl-next {
  background: url(../images/icon-leftRight.png) no-repeat -14px center #FFF !important;
  left: auto;
  right: 11px;
}

.owl-theme .owl-nav > [class*='owl-'].owl-next:hover {
  background: url(../images/icon-leftRightW.png) no-repeat -14px center #26cfe5 !important;
}

.table-viewAdd tr, .table-viewAdd thead, .table-viewAdd thead tr, .table-viewAdd {
  width: 100%;
}

.table-accountEdit thead tr, .table-viewAdd thead tr {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

tr th, tr td {
  position: relative;
  vertical-align: top;
  word-break: break-word;
}

tfoot > tr > td {
  padding: 0px !important;
}

tfoot > tr > td table tr td {
  text-align: center !important;
}

table thead {
  border-bottom: 1px solid #d7d7d7;
}

table.table-manageTeam thead {
  border: 0px;
}

table.table-evenOdd a:hover {
  color: #0872ba;
}

table.table-evenOdd a.btn-addCampaignView:hover {
  color: #FFF;
}

table.table-evenOdd {
  background-color: #FFF;
}

table.table-evenOdd > tbody > tr:nth-child(odd) {
  background-color: #f2f8ff;
}

table.table-evenOdd > tbody > tr.tr-noResults:nth-child(odd), table.table-evenOdd > tfoot > tr:nth-child(odd) {
  background-color: #FFF;
}

.table-dashboardEx table tr td, .table-viewAdd thead tr th {
  width: 25%;
  color: #707070;
  font-size: 11px;
  padding: 4px 15px;
  line-height: 20px;
}

.table-viewAdd thead tr th {
  width: 20%;
}

.table-dashboardEx table tfoot tr td {
  padding: 4px 0px;
}

.box-scrollViewTable {
  max-height: 143px;
}

.box-scrollViewTable table tr td {
  position: relative;
}

.table-viewAdd thead tr th {
  text-transform: uppercase;
  font-weight: 600;
  padding: 8px 15px;
}

.btn-addCampaignView {
  float: right;
  margin: 10px 10px 0px 0px;
  padding: 1px 7px 0px;
  font-size: 11px;
  color: #00bcd4;
  text-transform: uppercase;
  border: 1px solid #00bcd4;
  background-color: transparent;
}

.btn-addCampaignView:hover {
  color: #FFF;
  background-color: #00bcd4;
}

.btn-addCampaignView.shift-top {
  position: absolute;
  top: -31px;
  right: 15px;
  margin: 0px;
}

.box-tabViewNav ul.nav-tabs {
  padding: 0px;
  margin: 0px;
  float: left;
  border: 1px solid #d4d4d4;
  border-bottom: 0px;
  overflow: inherit;
  border-left: 0px;
}

.box-tabViewNav ul.nav-tabs li {
  padding: 0px;
  margin: 0px auto;
  border-left: 1px solid #d4d4d4;
  float: left;
  display: block;
}

.box-tabViewNav ul.nav-tabs li a {
  font-size: 12px;
  font-weight: 500;
  color: #323232;
  margin: 0px;
  border-bottom: 0px;
  padding: 8px 20px;
}

.box-tabViewNav ul.nav-tabs > li.active > a:after, .box-tabViewNav ul.nav-tabs > li > a:hover:after {
  position: absolute;
  content: '';
  top: -1px;
  left: 0px;
  right: 0px;
  height: 3px;
  background-color: #00bcd4;
  margin: auto;
  z-index: 9;
}

.box-tabViewNav ul.nav-tabs > li.active > a, .box-tabViewNav ul.nav-tabs > li.active > a:hover,
.box-tabViewNav ul.nav-tabs > li > a:hover {
  color: #00bcd4;
  border-bottom: 0px;
  background-color: #FFF;
}

.img-dragDrop.img-dragDropCamCard {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.icon-sortingTable {
  position: absolute;
  width: 7px;
  height: 10px;
  top: 0;
  bottom: 1px;
  margin: auto auto auto 7px;
  cursor: pointer;
  background: url('../images/icon-sorting.svg') no-repeat center center;
}

.icon-sortingTable.desc {
  background-image: url('../images/icon-sortingUp.svg');
}

.icon-sortingTable.asc {
  background-image: url('../images/icon-sortingDown.svg');
}

.thead-sorting .icon-sortingTable {
  top: 13px;
  bottom: inherit;
}

.td-expenseNotFound {
  color: #CCC !important;
  height: 90px;
  vertical-align: middle !important;
  text-align: center;
  font-size: 13px !important;
}

.td-expenseNotFound span {
  display: block;
  font-weight: normal;
}

.box-btnCenter {
  position: relative;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.box-btnCenter button {
  float: none !important;
  display: inline-block;
  font-size: 13px;
}

.progress {
  height: 6px;
  display: block;
  box-shadow: none;
  background-color: transparent;
  border-radius: 0px 6px 6px 0px;
  margin-bottom: 3px;
}

.progress em {
  display: block;
  box-shadow: none;
  border-radius: 0px 6px 6px 0px;
}

.tab-pane .btn-clear, .btn-clearPermission {
  position: absolute;
  right: 0;
  top: -31px;
  color: #e85353;
  font-size: 15px;
  padding: 2px 0 5px;
  background-color: transparent;
  z-index: 1;
  font-weight: 500;
}

.btn-clearPermission {
  position: static;
  float: right;
  font-size: 11px;
  font-weight: 600;
  padding: 0px;
}

.tab-pane .btn-cleared {
  color: #b2b2b2;
}

.tab-pane .btn-clear:hover, .btn-clearPermission:hover {
  color: #f56969;
}

.box-tableImg {
  min-width: 50px;
  text-align: center;
}

/*Modal Popup End*/
/*Campaign Cards Start*/
.box-campCards {
  margin: 0px -8px;
  padding-bottom: 15px;
}

ul.list-campCards {
  width: 100%;
  display: block;
  float: left;
  cursor: default !important;
}

ul.list-campCards > li {
  width: 338px;
  display: inline-block;
  border: 0px;
  padding: 9px;
  vertical-align: top;
  cursor: default !important;
}

.box-campCardsInn .panel-heading {
  background-color: #F8F8F8;
  color: #fff;
  border-color: #ECECEC;
  position: relative;
  height: 58px;
  width: 100%;
  padding: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

ul.list-programCard .box-campCardsInn {
  border-top-color: #3272b7;
}

ul.list-programCard .box-campCardsInn .panel-heading {
  background-color: #e9f4fb;
}

ul.list-programCard .box-camCardAmt,
ul.list-programCard ul.list-programCardsTymStatus li, ul.list-programCard .frow-camCardRight span.panel-title {
  color: #3272b7;
}

ul.list-programCard .box-campCardsInn .panel-heading .box-camCardAmt em {
  color: #3272b7;
  border-color: #3272b7;
}

ul.list-programCard .box-campCardsInn.box-closedCard {
  border-top-color: #323232;
}

ul.list-programCard .box-campCardsInn.box-closedCard .panel-heading .box-camCardAmt em {
  color: #757575;
  border-color: #757575;
}

ul.list-programCard .box-campCardsInn.box-closedCard .panel-heading,
.box-closedCard.box-campCardsInn .panel-heading {
  background-color: #bababa;
}

ul.list-programCard .box-closedCard .box-camCardAmt,
ul.list-programCard .box-closedCard ul.list-programCardsTymStatus li, ul.list-programCard .box-closedCard .frow-camCardRight span.panel-title,
.box-closedCard ul.list-programCardsTymStatus li,
.box-closedCard .box-camCardAmt, .box-closedCard .frow-camCardRight span.panel-title {
  color: #757575;
}

.box-closedCard.box-campCardsInn .panel-body {
  border-color: #757575;
}

.frow-camCardRight span.panel-title {
  color: #323232;
  font-size: 16px;
  padding: 5px 0px 0px;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 700 !important;
  line-height: 18px;
}

.box-campCardsInn .panel-heading span.panel-title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 105px;
}

.box-campCardsInn .panel-heading em {
  position: absolute;
  bottom: 3px;
  right: 10px;
  color: #96c3f2;
  font-style: normal;
  font-size: 10px;
  text-transform: uppercase;
}

.frow-camCardLeft {
  float: left;
  width: 50px;
  padding-left: 8px;
}

.frow-camCardLeft > span {
  display: flex;
  color: #FFF;
  width: 44px;
  height: 44px;
  border: 3px solid #265689;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  background-color: #75a8dc;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: normal;
  align-items: center;
  justify-content: center;
}

.frow-camCardRight {
  position: relative;
  margin-left: 61px;
}

.box-camCardAmt {
  position: absolute;
  top: 2px;
  right: 6px;
  color: #323232;
  font-size: 13px;
  font-weight: 400;
  padding-right: 18px;
}

.box-campCardsInn .panel-heading .box-camCardAmt em, .box-camCardAmt em {
  position: absolute;
  right: 0px;
  top: -2px;
  bottom: inherit;
  color: #3272b7;
  font-size: 8px;
  font-style: normal;
  line-height: 13px;
  text-align: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #3272b7;
}

.box-campCardsInn .panel-heading .box-camCardAmt em {
  color: #a9a9a9;
  border-color: #a9a9a9;
}

.box-campCardsInn .panel-body {
  padding: 0px 9px;
  background-color: #FFF;
}

/* temp libs */
.drop-list-temp {
  width: 100%;
  color: #3272b7;
  margin: auto;
  text-align: left;
  padding: 12px 35px 12px 18px !important;
  float: left !important;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drop-list-temp span {
  position: absolute;
  top: 12px;
  right: 10px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: block;
  border: 1px solid #3272b7;
  background: url("../images/icon-downArrowPink.png") no-repeat center 5px;
}

/* temp libs */
.icon-plusCss {
  position: relative;
  color: #7c8295;
  font-size: 12px;
  margin: auto;
  text-transform: uppercase;
  padding: 0px 0px 0px 12px;
  text-decoration: underline;
}

.empty-campCardsProg .icon-plusCss {
  margin: auto;
  display: inline-block !important;
}

.icon-plusCss:after, .icon-plusCss:before {
  top: 7px;
  left: 3px;
  position: absolute;
  content: '';
  width: 2px;
  height: 8px;
  background-color: #3273b7;
}

.icon-plusCss:after {
  top: 10px;
  left: 0px;
  width: 8px;
  height: 2px;
}

.icon-plusCss:hover:after, .icon-plusCss:hover:before {
  background-color: #00bcd4;
}

.box-progressBar {
  padding: 0px 50px;
  text-align: left;
}

.box-progressBar > span {
  padding-bottom: 10px;
}

.box-progressBar .progress {
  height: 20px;
  overflow: inherit;
  background-color: transparent;
}

.box-progressBar .progress-bar {
  position: relative;
  font-style: normal;
  border-radius: 0px;
  font-size: 13px;
  font-weight: 600;
  background-color: #A6D7A7;
  border-top: 2px solid #A6D7A7;
  border-bottom: 2px solid #A6D7A7;
  float: left;
  width: 0;
  height: 100%;
  line-height: 20px;
  color: #fff;
  text-align: center;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  transition: width .6s ease;
}

.box-progressBar .progress-bar span {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  line-height: 29px;
}

.timeLineGraph-available .txt-timeLineGraph em, .timeLineGraph-reserved .txt-timeLineGraph em, .timeLineGraph-underBudget .txt-timeLineGraph em,
.box-underBudgetProgress span, .box-reservedProgress span, .box-availableProgress span, .green-bar span, .orange-bar span, .res-bar span {
  color: #757575 !important;
}

.box-progressBar .black-bar.progress-bar:before, .box-progressBar .black-bar.progress-bar:after, .box-progressBar .progress .progress-bar.black-bar {
  background-color: #323232;
  border-color: #323232;
}

.box-progressBar .purple-bar.progress-bar:before, .box-progressBar .purple-bar.progress-bar:after, .box-progressBar .progress .progress-bar.purple-bar {
  background-color: #7c4dff;
  border-color: #7c4dff;
}

.box-progressBar .blue-bar.progress-bar:before, .box-progressBar .blue-bar.progress-bar:after, .box-progressBar .progress .progress-bar.blue-bar {
  background-color: #4392e4;
  border-color: #4392e4;
}

.box-progressBar .red-bar.progress-bar:before, .box-progressBar .red-bar.progress-bar:after, .box-progressBar .progress .progress-bar.red-bar {
  background-color: #f74747;
  border-color: #f74747;
}

.box-progressBar .orange-bar.progress-bar:before, .box-progressBar .orange-bar.progress-bar:after, .box-progressBar .progress .progress-bar.orange-bar {
  background-color: #FF9800;
  border-color: #FF9800;
}

.box-progressBar .orange-bar.progress-bar:after {
  background-color: #FF9800;
}

.box-progressBar .res-bar.progress-bar:before, .box-progressBar .res-bar.progress-bar:after, .box-progressBar .progress .progress-bar.res-bar {
  border-color: #BABABA;
  background-color: #BABABA;
}

.box-progressBar .progress-bar .reserved-bar, .reserved-bar {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  background-color: #BABABA;
  overflow: hidden;
}

.box-progressBarCam.box-progressBar .progress {
  height: 32px;
}

.box-progressBarCam .graph-tooltip {
  height: 30px;
  color: #323232;
  padding-top: 2px;
}

.box-progressBarCam .graph-tooltip em {
  margin-top: -2px;
}

i.normal-text, .box-progressBarCam i {
  font-style: normal;
}

.hide-text i {
  display: none;
}

.progress-bar:hover .graph-tooltip, .box-camCardAmt em:hover .graph-tooltip, .box-progressBarCam .progress-bar:hover .graph-tooltip {
  display: block;
}

.box-camCardAmt em .graph-tooltip:after {
  top: 9px;
  bottom: auto;
  right: -9px;
  left: auto;
  background: url("../images/icon-tooltipArrowR.png") no-repeat right center;
}

.box-camCardAmt em .graph-tooltip {
  top: -9px;
  left: -175px;
  right: auto;
  bottom: auto;
  width: 170px;
  height: auto;
  color: #7a8297;
  font-weight: 500;
  font-size: 10px;
  padding: 7px 5px;
  text-transform: none;
  word-wrap: break-word;
}

.alertTitle-tooltip {
  position: relative;
  cursor: default;
}

.alertTitle-tooltipInn {
  position: absolute;
  display: none;
  font-size: 10px;
  width: 176px;
  top: -20px;
  right: -181px;
  text-align: center;
  padding: 4px;
  font-weight: 500;
  color: #323232;
  line-height: 14px;
  border-radius: 3px;
  border: 1px solid #6eb0f5;
  background-color: #FFF;
  z-index: 999;
  word-wrap: break-word;
  text-transform: uppercase;
}

.btn-delAlerts .alertTitle-tooltipInn, .btn-goAlerts .alertTitle-tooltipInn {
  right: auto;
  left: -181px;
}

.btn-delAlerts .alertTitle-tooltipInn {
  width: 120px;
  left: -125px;
}

.btn-delAlerts:hover .alertTitle-tooltipInn, .btn-goAlerts:hover .alertTitle-tooltipInn, .alertTitle-tooltip:hover .alertTitle-tooltipInn {
  display: block;
}

.box-programSpent {
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 0px;
}

.box-programSpentInn {
  color: #FFF;
  font-weight: 500;
  width: 43px;
  height: 43px;
  line-height: 38px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
}

.box-programSpentInn span {
  font-size: 10px;
}

.box-programSpentInn em {
  font-style: normal;
  font-size: 7px;
}

.spent-program {
  font-size: 10px;
  text-transform: uppercase;
  color: #bbdafb;
  bottom: -1px;
  position: absolute;
  right: 0px;
  left: 0;
  text-align: center;
  font-weight: 500;
}

.red-spentCampaign {
  color: #e91d1d;
}

/*Campaign Cards End*/
/*Program Cards Start*/

.view-mode-icons {

}

.table-view {
  .view-mode-icons li.image-view button {
    width: 18px;
    background: url('../images/plan-detail/icon-tiles-view-inactive.svg') no-repeat center center;
    background-size: 100%;
  }

  .view-mode-icons li.image-view button:hover {
    background: url('../images/plan-detail/icon-tiles-view-inactive-hover.svg') no-repeat center center;
  }

  .view-mode-icons li.table-view button:hover {
    background: url('../images/widget-container-settings/icon-table-view-active.svg') no-repeat center center;
  }

  .view-mode-icons li.table-view button {
    width: 19px;
    background: url('../images/widget-container-settings/icon-table-view-active.svg') no-repeat center center;
    background-size: 100%;
    margin-right: 0;
  }
}

.view-mode-icons li {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 15px;
  color: var(--primary);
  text-transform: none;

  fa-icon {
    font-size: 20px;
    line-height: 20px;
    margin-right: 8px;
    --fa-primary-color: var(--primary);
    --fa-secondary-color: var(--primary);
  }

  &:hover {
    color: var(--dark-blue);

    fa-icon {
      --fa-primary-color: var(--dark-blue);
      --fa-secondary-color: var(--dark-blue);
    }
  }
}

.view-mode-icons li.image-view button {
  width: 18px;
  background: url('../images/plan-detail/icon-tiles-view-active.svg') no-repeat center center;
  background-size: 100%;
}

ul.list-programCard {
  position: relative;
  width: 100%;
  float: left;
  padding: 16px 0px;
}

ul.list-programCard > li {
  position: relative;
  width: 338px;
  display: inline-block;
  padding: 9px;
  vertical-align: top;
}

div.list-programCardsTymStatus {
  width: 100%;
  display: inline-flex;
  padding-right: 25px;
  margin-top: 15px;
}

div.list-programCardsTymStatusSecond {
  position: absolute;
  right: 23px;
  bottom: 5px;
  color: #757575;
  font-size: 11px;
  font-weight: 700;
}

div.list-programCardsTymStatusSecond .list-programCardsTymStatusSecondSeparator {
  position: absolute;
  left: -10px;
  top: 4px;
  content: '';
  width: 1px;
  height: 12px;
  background-color: #b1b1b1;
}

/*Program Cards End*/

/*Manage Team Start*/
.box-scrollTable.table-budPerDivi {
  max-height: 140px;
  overflow-y: auto;
}

.box-scrollTable.boxTbl-manageTeamInn {
  overflow-y: auto;
}

.box-wapperTwo .box-topBar {
  width: 100%;
  display: inline-block;
  padding: 0px 125px 0px 35px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.box-manageTeam {
  width: 100%;
  display: inline-block;
}

.box-searchManageTeam {
  position: relative;
  float: left;
  width: 100%;
  max-width: 320px;
  margin: 5px 0px 15px;
}

.box-searchTopNav .box-searchManageTeam {
  margin: 0px;
}

ul.list-assOwner {
  float: right;
  padding-top: 10px;
}

ul.list-assOwner li {
  float: left;
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 600;
  margin-right: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
}

ul.list-assOwner li span {
  margin-right: 5px;
}

ul.list-assOwner li:last-child {
  margin-right: 0px;
}

ul.list-assOwner li .btn-tableEdit {
  position: relative;
  top: 2px;
  left: 0px;
}

.btn-searchTeam {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 16px;
  width: 14px;
  height: 15px;
  margin: auto;
  display: block;
  padding: 0px;
  background: url('../images/icon-searchBlue.png') no-repeat center center;
  border-radius: 0px;
}

.btn-searchTeam:hover, .btn-searchTeam:focus {
  background-color: transparent;
}

.box-searchManageTeam input {
  font-size: 13px;
  color: #575757;
  padding: 0px 35px 0px 15px;
  text-align: left;
  width: 100%;
  height: 34px;
  line-height: 34px;
  border: 1px solid rgba(50, 115, 183, 0.35);
  border-radius: 20px;
  background-color: transparent;
  font-weight: 500;
}

.box-spendBtTypeTopBar .box-searchManageTeam input {
  color: #3273b7;
}

.table-manageTeam, .table-manageTeamInn {
  width: 100%;
}

table tr.table-main-tr {
  border-bottom: 1px solid #f2f2f2;
}

table tr.table-main-tr:last-child {
  border-bottom: 0px;
}

tr.table-main-tr > th, tr.table-main-tr > td {
  padding-left: 15px;
  font-size: 13px;
  color: #424242;
  font-weight: 500;
}

tr.table-main-tr > td {
  padding: 10px 0px 10px 15px;
  vertical-align: top;
  font-weight: 500;
}

.table-manageTeamInn tr > td > span {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

tr.table-main-tr > th:nth-child(2), tr.table-main-tr > td:nth-child(2) {
  width: calc(100% - 500px);
  padding-left: 0px;
}

tr.table-main-tr > th:nth-last-child(3), tr.table-main-tr > td:nth-last-child(3) {
  width: 80px;
  min-width: 80px;
  text-align: center;
}

.super-manageMyTeam tr.table-main-tr > th:nth-last-child(2), .super-manageMyTeam tr.table-main-tr > td:nth-last-child(2) {
  text-align: left;
  width: 55%;
}

.super-manageMyTeam tr.table-main-tr > th:nth-last-child(3), .super-manageMyTeam tr.table-main-tr > td:nth-last-child(3) {
  width: 110px;
  text-align: left;
}

tr.table-main-tr > td:nth-last-child(3) {
  padding-top: 10px;
}

.wap-manageMyTeam tr.table-main-tr > td:nth-last-child(3) {
  padding-top: 16px;
}

tr.table-main-tr > th:nth-last-child(2), tr.table-main-tr > td:nth-last-child(2) {
  width: 70px;
  min-width: 70px;
  text-align: center;
}

tr.table-main-tr > th:last-child, tr.table-main-tr > td:last-child {
  width: 80px;
  min-width: 80px;
  padding-left: 0px;
  padding-right: 15px;
  text-align: center;
}

tr.table-main-tr > td:last-child, tr.table-main-tr > td:nth-last-child(2) {
  padding-top: 16px;
}

tr.table-main-tr > th:first-child, tr.table-main-tr > td:first-child {
  width: 250px !important;
  min-width: 250px !important;
}

tr.table-main-tr > td:first-child {
  font-size: 13px;
}

/*Super Admin Table Start*/

.btn-dropDownTable {
  position: relative;
  width: 17px;
  height: 11px;
  padding: 0px;
  display: block;
  background: transparent url("../images/icon-dottes.png") no-repeat center center;
  margin: auto;
}

.btn-dropDownTable:focus, .btn-dropDownTable:hover {
  background-color: transparent;
}

ul.list-dropDownTable {
  position: absolute;
  top: calc(100% + 4px);
  right: -40px;
  width: 130px !important;
  padding-top: 0px !important;
  border: 1px solid #edf2f6 !important;
  box-shadow: 0px -2px 18px rgba(22, 71, 123, 0.2) !important;
  background-color: #FFF;
  z-index: 999;
  display: none;
  overflow: hidden;
  border-radius: 4px;
}

ul.list-dropDownTable li {
  width: 100%;
  display: inline-block;
}

ul.list-dropDownTable li button {
  color: #323232;
  width: 100%;
  font-size: 11px;
  text-align: left;
  padding: 5px 10px;
  display: block;
  font-weight: 500;
  background-color: #FFF;
}

ul.list-dropDownTable li button:hover {
  color: #323232;
  background-color: #F5F5F5;
}

.btn-dropDownTable.active ul.list-dropDownTable {
  display: block;
}

.btn-dropDownTable {
  position: relative;
  width: 17px;
  height: 11px;
  padding: 0px;
  display: block;
  margin: auto;
  background: transparent url("../images/icon-dottes.png") no-repeat center center;
}

.btn-dropDownTable:focus, .btn-dropDownTable:hover {
  background-color: transparent;
}

ul.list-dropDownTable {
  position: absolute;
  top: calc(100% + 4px);
  right: -10px;
  width: 130px !important;
  padding-top: 0px !important;
  border: 1px solid #edf2f6 !important;
  box-shadow: 0px -2px 18px rgba(22, 71, 123, 0.2) !important;
  background-color: #FFF;
  z-index: 999;
  display: none;
  overflow: hidden;
  border-radius: 4px;
}

ul.list-dropDownTable li {
  width: 100%;
  display: inline-block;
}

ul.list-dropDownTable li a {
  color: #868686;
  font-size: 11px;
  text-align: left;
  padding: 5px 10px;
  display: block;
  font-weight: 500;
  background-color: #f5f5f5;
}

ul.list-dropDownTable li a:hover {
  color: #868686;
  background-color: #FFF;
}

ul.list-dropDownTable:after {
  position: absolute;
  content: '';
  right: 7px;
  top: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #f5f5f5 transparent;
}

.btn-dropDownTable.active ul.list-dropDownTable {
  display: block;
}

/* Super-user Css End */

.table-manageTeam tr th {
  font-size: 12px;
  color: #707070;
  padding-left: 15px;
  font-weight: 600;
}

.table-manageTeamInn tr th, .table-manageTeamInn tr td {
  width: 33.33%;
  padding: 0px 0px 2px 15px;
  font-weight: 500;
}

.table-manageTeamInn tr td {
  font-weight: 400;
}

.table-budPerDivi .table-manageTeamInn tr:first-child > td {
  padding-top: 4px;
}

.wap-manageMyTeam .table-budPerDivi .table-manageTeamInn tr:first-child > td {
  padding-top: 6px;
}

.super-manageMyTeam .table-budPerDivi .table-manageTeamInn tr:first-child > td {
  padding-top: 0px;
}

.table-manageTable.table-budPerDivi .table-manageTeamInn tr > td {
  max-width: 250px;
}

.table-manageTable.table-budPerDivi .table-manageTeamInn tr > td:nth-child(2) {
  max-width: 100px;
}

.boxTbl-manageTeamInn {
  margin-top: 4px;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  background-color: #FFF;
  box-shadow: 0px 0px 10px rgba(50, 114, 183, 0.3);
}

.frow-manageTeam {
  width: 100%;
  display: inline-block;
  padding-top: 2px;
}

.frow-manageTeamName {
  margin: 4px 0px 0px 50px;
  color: #3272b7;
  font-weight: 600;
}

.btn-tableEdit {
  position: absolute;
  top: 13px;
  left: 0px;
  right: 0px;
  width: 16px;
  height: 17px;
  padding: 0px;
  background: url('../images/icon-edit.png') no-repeat center center rgba(0, 0, 0, 0);
  margin: auto;
  background-size: 100%;
  text-indent: -999em;
}

.wap-manageMyTeam .btn-tableEdit {
  top: 21px;
  left: 15px;
}

.btn-tableDelete {
  position: absolute;
  top: 13px;
  left: 0px;
  right: 15px;
  width: 15px;
  height: 18px;
  padding: 0px;
  background: url('../images/icon-deleteRed.png') no-repeat center center rgba(0, 0, 0, 0);
  margin: auto;
  background-size: 100%;
  text-indent: -999em;
}

.wap-manageMyTeam .btn-tableDelete {
  top: 20px;
}

.btn-tableReq {
  width: 15px;
  height: 20px;
  margin: 6px auto 0px;
  padding: 0px;
  background: url('../images/icon-lockReq.png') no-repeat center center rgba(0, 0, 0, 0);
}

.btn-tableAdd {
  position: relative;
  width: 16px;
  height: 16px;
  padding: 0px;
  margin: 8px auto 0px;
  background-color: transparent;
  text-indent: -999em;
}

.btn-tableAdd:after, .btn-tableAdd:before {
  position: absolute;
  content: '';
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: auto;
  width: 2px;
  height: 16px;
  background-color: #3272b7;
}

.btn-tableAdd:after {
  width: 16px;
  height: 2px;
}

.btn-tableEdit:hover, .btn-tableDelete:hover, .btn-tableReq:hover, .btn-tableAdd:hover {
  background-color: transparent;
}

.btn-addUser {
  padding: 8px 25px 8px 55px;
  margin-bottom: 10px;
  background: url("../images/icon-addUser.png") no-repeat 22px 10px #00bcd4;
}

.name-customFieldPlan {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name-customFieldPlan {
  position: absolute;
  top: 0px;
  margin-left: 30px;
  width: calc(100% - 30px);
  display: block;
}

.form-asAdmin {
  margin: 0px 0px 52px;
}

.form-checkbox.form-asAdmin {
  font-size: 18px;
  font-weight: 500;
}

.form-checkbox label:after {
  border-color: #f0f0f0;
}

/*Manage Team End*/
/*Plan Setting Start*/
.box-pageHeadingBar {
  padding: 0;
  color: #1f1f1f;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

.box-pageHeadingBar span {
  text-transform: none;
}

.input-animationW {
  font-size: 17px;
}

.box-resetPass {
  width: 100%;
  display: inline-block;
  padding: 18px 0px 0px;
}

.box-resetPass button {
  display: inline-block;
  color: #3273b7;
  font-size: 13px;
  padding: 0px;
  background-color: transparent;
  border-bottom: 1px solid #3273b7;
  border-radius: 0px;
  font-weight: 400;
}

.box-resetPass button:hover {
  color: #6176cc;
}


/*Plan Setting End*/
/*Upload Start*/

ul.list-upload {
  width: 100%;
  display: inline-block;
}

ul.list-upload li {
  width: calc(33.33% - 5px);
  display: inline-block;
  padding: 40px 4px 0px;
}


.double-buttons-box {
  width: 100%;
  display: flex;
  justify-content: space-between;

  > * {
    width: calc(50% - 5px);

    &:only-child {
      width: 100%;
    }
  }
}

html body button.flex-space-between, html body button.flex-center {
  border-radius: 50% !important;
  background-color: #3954bf !important;
}

html body button.flex-center {
  opacity: 0;
}

.title-addGoal {
  position: relative;
  float: left;
  margin-top: 7px;
  padding-bottom: 20px;
  max-width: 260px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
}

.addUser .title-addGoal {
  width: 100%;
  max-width: 774px;
  padding-right: 35px;
  font-weight: 600;
}

.box-buttonQue {
  position: relative;
  width: 100%;
  padding: 15px 0px 0px;
  display: inline-block;
}

.title-addGoal > button + .tooltip {
  width: 350px !important;
}

.box-spreadSheet {
  width: 100%;
  height: 100%;
}

.head-spreadSheet {
  padding: 39px 20px 0px;
  height: 100px;
}

.head-spreadSheet h4 {
  font-size: 15px;
  text-align: center;
  height: 72px;
  color: #4E4E4E;
  padding: 0px;
}

.tool-assign {
  font-size: 8px;
  color: #3954bf;
  line-height: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 0px;
  background-color: #FFF;
}

.tool-assign:hover {
  color: #3954bf;
  background-color: #FFF;
}

.box-spreadSheet label, .box-spreadSheet select.input-animation {
  background-color: #D2D2D2;
  border: 0px;
  color: #4E4E4E;
}

.box-spreadSheet label {
  color: #b2bae2;
}

.box-spreadSheet label.trigger {
  font-size: 13px;
  color: #4E4E4E;
}

.form-checkbox label.btn:after {
  border: 2px solid #FFF;
}

.form-asAdmin.form-checkbox label.btn:after {
  border-color: #9F9F9F;
}

.box-scrollUploaded {
  height: 100%;
  overflow-y: auto;
}

.box-tableManageExpense, .box-tableManageExpenseInn {
  width: 100%;
  height: 100%;
  display: block;
  float: left;
  overflow: hidden;
}

.box-tableManageExpenseInn {
  overflow: auto;
}

.box-tableManageExpense.box-tableManageExpenseScroll {
  height: calc(100% - 161px);
}

.super-company .box-tableManageExpense {
  height: calc(100% - 65px);
}


.box-successErrorNav {
  position: relative;
}

.box-successErrorNav ul.nav-tabs > li > a {
  position: relative;
}

.successError-count {
  position: relative;
  right: 0px;
  top: -2px;
  color: #FFF;
  font-size: 9px;
  min-width: 26px;
  display: inline-block;
  text-align: center;
  padding: 1px 4px;
  text-align: center;
  border-radius: 26px;
  background-color: #E85353;
}

.successError-back {
  position: absolute;
  top: 25px;
  right: 30px;
  height: 30px;
  font-size: 11px;
  font-weight: 500;
  line-height: 31px;
  padding: 0px 15px 0px 25px;
  border-radius: 3px;
  background-color: #3272b7;
}

.successError-back:hover {
  background-color: #3d83cf;
}

.successError-back img {
  position: absolute;
  top: 0;
  bottom: 0px;
  width: 11px;
  height: 11px;
  margin: auto;
  left: 8px;
}

/*Upload End*/
/*Faqs Start*/
ul.list-getInTouch {
  width: 100%;
  display: inline-block;
  padding: 0px 15px 0px 50px;
}

ul.list-getInTouch li {
  position: relative;
  padding: 0px 0px 24px 40px;
  color: #FFF;
}

ul.list-getInTouch li:after {
  position: absolute;
  left: 0px;
  top: 3px;
  content: '';
  width: 21px;
  height: 24px;
  background: url('../images/icons-getInTouch.png') no-repeat center 0px;
}

ul.list-getInTouch li h4 {
  font-size: 11px;
  text-transform: uppercase;
  padding: 0px;
}

ul.list-getInTouch li span {
  display: block;
  color: #c0c6e7;
  font-size: 13px;
}

ul.list-getInTouch li.emailAdd:after {
  background-position: center -38px;
}

ul.list-getInTouch li.address:after {
  background-position: center -67px;
}

.faqs-accordion {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(173, 138, 255, 0.4);
}

.faqs-accordion .panel {
  border: 0px;
  border-radius: 0px;
}

.faqs-accordion .panel + .panel {
  margin: 0px;
}

/*Faqs End*/
/*Market Place Start*/
.box-marketPlaceMain {
  width: 100%;
  display: inline-block;
  padding: 35px;
}

.box-markPlaceHead {
  width: 100%;
  max-width: 705px;
  margin: auto;
  text-align: center;
  padding-bottom: 69px;
}

.box-markPlaceHead h4 {
  font-size: 30px;
  color: #1f1f1f;
  padding-bottom: 24px;
  font-weight: 600;
}

.box-markPlaceHead p {
  font-size: 17px;
  color: #5b5b5b;
  line-height: 24px;
  font-weight: 500;
}

.box-markPlaceHead p span {
  display: block;
}

.box-markPlacePara, ul.list-markPlace, .box-markPlace {
  width: 100%;
  display: inline-block;
}

.box-markPlace {
  height: 433px;
  overflow-y: auto;
}

ul.list-markPlace {
  max-width: 1130px;
  margin: auto;
}

ul.list-markPlace li {
  float: left;
  width: 25%;
  padding: 24px 8px 0px;
}

.box-markPlaceInn {
  overflow: hidden;
  border-radius: 4px;
  background-color: #FFF;
  box-shadow: 0px 0px 8px rgba(0, 188, 212, 0.4);
}

.box-markPlaceImg {
  width: 100%;
  height: 201px;
}

.box-markPlaceImg img {
  width: 100%;
  max-width: 100%;
}

.link-viewOff {
  color: #FFF;
  padding: 7px 36px 6px;
  font-size: 13px;
  display: inline-block;
  background-color: #28a364;
  border-radius: 2px;
  text-transform: uppercase;
  margin-top: 22px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
}

/*Market Place End*/
.list-graphAddProd .fix-height {
  max-height: 300px !important;
}

.addUserAsAdmin .asAdminHide, .asAdminShow {
  display: none;
}

.addUserAsAdmin .asAdminShow {
  display: block;
}

.addUserAsAdmin .form-asAdmin {
  margin-bottom: 0px;
}

.text-makingUser {
  width: 100%;
  font-size: 13px;
  color: #00bcd4;
  display: inline-block;
  padding: 3px 15px 43px;
  font-weight: 400;
}

.addUserAsAdmin .panel-body {
  padding-bottom: 0px;
}

.addUserAsAdmin .asAdminShow.box-asAdminMaking {
  width: 100%;
  display: inline-block;
}

.addUserAsAdmin .modal-accordions .panel-collapse.in::after {
  bottom: 0px;
}

.box-rightBule {
  width: 225px;
  float: left;
  height: 100%;
  overflow-y: hidden;
}

.wizard-tabs {
  pointer-events: none;
}

.step-complete {
  background-color: #00bcd4 !important;
}

.dollerSign.ng-invalid {
  border: 1px solid red;
}

.backHide {
  display: none;
}

.viewNote {
  display: none;
}

.tab-notAvailable {
  color: #b2b2b2;
  display: inline-block;
  font-size: 12px;
  width: 100%;
}

.selbtngroup, .selbtngroup button {
  width: 120px !important;
  text-align: right;
}

#editAll .box-modalAddGoal .modal-footer {
  padding: 25px 30px 15px;
  text-align: center;
}

#editAll .box-modalContent {
  width: 600px;
}

.box-planSettingMSG p {
  color: #b2b2b2;
  font-size: 14px;
  padding: 0px 30px;
  line-height: 28px;
}

#form-signIn .box-inputField {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px;
}

.modal-backdrop.in {
  opacity: 0.8 !important;
}

.box-blueAddField {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-top: 40px;
  padding: 30px 10px 10px;
  border-radius: 4px;
  background-color: #D2D2D2;
}

.box-spreadSheet .box-blueAddField label {
  background-color: transparent;
}

.box-blueAddField .box-inputAnimation .input-animation {
  color: #4E4E4E;
  border: 0px;
  background-color: #DDD;
}

.box-blueAddField .box-inputAnimation + .box-inputAnimation {
  margin-top: 10px;
}

.link-removeField {
  position: absolute;
  right: -6px;
  top: -6px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #D2D2D2;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.link-removeField:after, .link-removeField:before {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  content: '';
  width: 2px;
  height: 10px;
  background-color: #4E4E4E;
}

.link-removeField:after {
  height: 2px;
  width: 10px;
}

.box-upgradeBilling .box-inputAnimation label {
  right: 30px;
}

.box-upgradeBilling .box-inputAnimation label.trigger {
  right: 0px;
}

.manage-sheet-empty .box-rightBule {
  display: none;
}

.manage-sheet-empty .box-tableManageExpense {
  display: table;
  text-align: center;
}

.manage-sheet-empty .box-tableManageExpenseInn {
  display: table-cell;
  vertical-align: middle;
  float: none;
}

.manage-sheet-empty .box-tableManageExpenseInn p {
  padding-bottom: 0px;
}

/* --- temp libs  */
.tooltip-inner {
  padding: 8px;
}

.link-expenseTbl {
  position: relative;
}

.box-Unallocated .link-expenseTbl {
  display: inherit;
}

.table-contOverflow {
  display: -moz-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-scrollTable.boxTbl-manageTeamInn, #tab-manageTeam-1, #tab-manageTeam-2, .box-tabManageContent {
  width: 100%;
  height: 100%;
  float: left;
}

ul.list-allCategory {
  position: relative;
  float: left;
  padding-left: 5px;
}

ul.list-allCategory > li {
  position: relative;
  float: left;
  padding-right: 5px;
  text-transform: uppercase;
}

ul.list-allCategory > li + li {
  padding-left: 4px;
}

ul.list-allCategory > li .link-home {
  width: 36px;
  height: 36px;
  line-height: 29px;
  display: block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #d1d1d1;
}

ul.list-allCategory > li:last-child {
  padding-right: 0px;
}

ul.list-allCategory > li.list-selectCategory {
  padding-top: 3px;
}

.select-category .selected-list .c-btn {
  padding: 0px !important;
  box-shadow: none !important;
  border-color: #F7F7F7 !important;
  border-radius: 4px;
}

.select-category .selected-list .c-btn:hover {
  border-color: #C1C1C1 !important;
}

.select-category .selected-list .c-btn > span,
.select-category .selected-list .c-list .c-token {
  float: none;
  overflow: hidden;
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  color: #323232 !important;
  font-size: 11px;
  padding: 3px 28px 2px 10px !important;
  margin: 0px !important;
  border-radius: 0px !important;
  background: transparent !important;
  font-family: 'DM Sans', sans-serif !important;
  font-weight: 700;
}

.select-category .selected-list .c-btn > span + span:after {
  display: none;
}

.select-category .selected-list .c-btn > span + span {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.select-category .selected-list .c-btn > .c-list + span {
  position: absolute;
  top: 4px;
  right: 29px;
  padding: 0px !important;
  width: auto;
}

.select-category .selected-list .c-btn > .c-list + span:after {
  display: none !important;
}

.select-category .selected-list .c-btn > span {
  padding: 3px 26px 2px 10px !important;
  display: block;
}

.select-category .selected-list .c-btn > span:before, .select-category .selected-list .c-list .c-token:before,
.select-category .selected-list .c-btn > span:after, .select-category .selected-list .c-list .c-token:after {
  position: absolute;
  content: '';
  top: 0px;
  right: 8px;
  bottom: 0px;
  margin: auto;
  width: 9px;
  height: 6px;
  background: url('../images/icon-downArrowBlack.png') no-repeat center center;
}

.select-category .selected-list .c-btn > span:before, .select-category .selected-list .c-list .c-token:before {
  background: #D1D1D1 none;
  width: 1px;
  height: 27px;
  right: 24px;
  left: auto;
}

.select-category .selected-list .c-btn > span:before, .select-category .selected-list .c-list .c-token:before
.select-category .selected-list .c-btn:hover > span:beofre {
  background-color: #C1C1C1 !important;
}

span.c-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 158px;
}

.select-category .selected-list .c-list .c-token .c-label, .select-category .dropdown-list .pure-checkbox label {
  float: none !important;
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}

.select-category .selected-list .c-list .c-token .c-label {
  max-width: 90px;
}

.select-category .pure-checkbox input[type="checkbox"] + label {
  color: #323232 !important;
  font-weight: 700 !important;
}

.select-category .pure-checkbox input[type="checkbox"] + label:before {
  background: #e6eef6 !important;
  border-color: #e6eef6 !important;
  border-radius: 50%;
}

.select-category .pure-checkbox input[type="checkbox"]:checked + label {
  color: #3272b7 !important;
}

.select-category .pure-checkbox input[type="checkbox"]:checked + label:before {
  border-color: #3272b7 !important;
  background: #3272b7 !important;
}

span.fa-remove, .select-category .selected-list .c-btn > span.fa-angle-down, .select-category .selected-list .c-btn > span.fa-angle-up {
  display: none;
}

.select-category .arrow-up {
  display: none;
}

.select-category .dropdown-list {
  left: -25px;
  width: 215px !important;
  padding-top: 0px !important;
}

.select-category .dropdown-list .list-area {
  border: 1px solid #edf2f6 !important;
  box-shadow: 0px -2px 18px rgba(22, 71, 123, 0.2) !important;
}

.list-area ul > li {
  width: 100%;
  display: block;
}

.paddRight0 {
  padding-right: 0px !important;
}

.select-category.import-table-header .selected-list .c-btn > span:first-child {
  width: 100% !important;
}

.select-category.import-table-header .selected-list .c-btn > span + span {
  display: none;
}

.select-category.import-table-header .pure-checkbox input[type=checkbox], .select-category.import-table-header .pure-checkbox label:before, .select-category.import-table-header .pure-checkbox label:after {
  display: none;
}

.graph-tooltip {
  display: none;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  right: -91px;
  width: 86px;
  height: 55px;
  font-size: 11px;
  border-radius: 4px;
  border: 1px solid #6eb0f5;
  background-color: #FFF;
  padding: 7px 0px 0px;
  z-index: 9999;
  line-height: 14px;
  color: #323232;
}

.graph-tooltip {
  right: -87px;
  width: 83px;
}

.graph-tooltip em {
  font-style: normal;
  color: #3188e3;
  font-size: 8px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.profile-tooltip:after, .graph-tooltip:after {
  position: absolute;
  content: '';
  top: 0px;
  left: -9px;
  bottom: 0px;
  margin: auto;
  width: 9px;
  height: 10px;
  background: url("../images/icon-tooltipArrow.png") no-repeat left center;
}

.box-inputAnimationTooltip {
  position: relative;
}

.graph-tooltip.profile-tooltip, .profile-tooltip {
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0px;
  right: -204px;
  margin: auto;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #6eb0f5;
  background-color: #FFF;
  padding: 8px 7px 7px;
  z-index: 9999;
  line-height: 14px;
  color: #323232;
  text-align: center;
}

.graph-tooltip.profile-tooltip span, .profile-tooltip span {
  color: #323232;
  width: 100%;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.graph-tooltip.profile-tooltip i, .profile-tooltip i {
  color: #a6a7a7;
  width: 100%;
  display: inline-block;
  font-style: normal;
  font-size: 12px;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.box-viewModalImg:hover .profile-tooltip,
.box-inputAnimationTooltip:hover .profile-tooltip {
  opacity: 1;
  visibility: visible;
}

.btn-addAField {
  color: #4E4E4E;
  font-size: 11px;
  padding: 2px 18px 0px;
  border-radius: 2px;
  background: transparent;
  border: 2px solid #4E4E4E;
  margin: 16px auto 5px;
}

.btn-addAField:hover {
  color: #4E4E4E;
  background-color: #D2D2D2;
}

.box-topHierarchy, .box-topTimeLine {
  position: relative;
  width: 50%;
  padding-top: 0px;
}

.alert-dotte {
  position: relative;
  top: -1px;
  font-size: 9px;
  color: #FFF;
  border-radius: 6px;
  background-color: #ff5300;
  margin-left: 3px;
  padding: 1px 7px 2px;
}

.box-spendByTypeChart {
  width: 100%;
  min-height: 420px;
}

.box-UnallocatedError {
  width: 100%;
  height: calc(100% - 37px);
  display: table;
  text-align: center;
  padding-top: 0px;
}

.box-UnallocatedError p {
  color: #CCC;
  padding: 0px 15px;
  display: table-cell;
  vertical-align: middle;
  font-size: 13px;
}

.fullWidth-spendBy .box-UnallocatedError p {
  font-size: 15px;
}

.box-UnallocatedError p span {
  display: block;
}

.link-widgetOther {
  position: absolute;
  top: 10px;
  left: 20px;
  color: #c1c1c1;
  font-size: 9.5px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 0px 14px;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  font-weight: 600;
}

.link-widgetOther:focus, .link-widgetOther:hover {
  color: #3273b7;
  border-color: #3273b7;
}

.fullWidth-spendBy .link-widgetOther span, .link-widgetOther em {
  display: none;
  font-style: normal;
}

.fullWidth-spendBy .link-widgetOther em {
  display: inline-block;
}

.box-timeLineGraph {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  padding: 10px 0px 30px;
}

.box-timeLineGraphInn {
  position: relative;
  width: 100%;
  height: calc(100% - 24px);
  display: block;
  padding: 0px 9px 16px;
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 1px solid #ececec;
}

.startDate-timeLineGraph, .endDate-timeLineGraph {
  position: absolute;
  left: 0px;
  bottom: 5px;
  color: #707070;
  font-size: 11px;
}

.endDate-timeLineGraph {
  left: auto;
  right: 0px;
}

.box-Unallocated, .box-alerts {
  height: 100%;
  overflow: auto;
}

.box-Unallocated {
  padding: 0px;
}

.btn-delAlerts, .btn-goAlerts {
  position: absolute;
  top: 4px;
  right: 17px;
  height: 22px;
  line-height: 22px;
  color: #262626;
  font-size: 10px;
  padding: 0px;
  border-radius: 3px;
  border: 1px solid #ededed;
  background-color: transparent;
}

.btn-delAlerts {
  width: 22px;
}

.btn-delAlerts:after {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  content: '+';
  color: #323232;
  font-size: 22px;
  line-height: 22px;
  font-weight: normal;
  transform: rotate(45deg);
}

.btn-goAlerts {
  right: 46px;
  padding: 0px 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.btn-delAlerts:hover {
  background-color: #e85353;
}

.btn-delAlerts:hover:after {
  color: #FFF;
}

.btn-goAlerts:hover {
  color: #FFF;
  background-color: #28a364;
}

.table-viewAdd tbody tr {
  cursor: pointer;
}

.table-viewAdd tbody tr.edit-tableRow {
  cursor: default;
}

.table-widget.table-viewAdd thead tr {
  border-top: 0px;
  border-bottom-color: #ededed;
}

.table-widget tr td, .table-viewAdd.table-widget thead tr th {
  font-size: 11px;
  color: #707070;
  width: 20%;
  padding: 4px 10px !important;
  font-weight: 600;
}

.table-viewAdd.table-widget tr td {
  width: 20%;
}

.table-viewAdd.table-widget thead tr th {
  padding: 8px 10px !important;
  border-top: 1px solid #eaeaea
}

.table-widget tr td {
  font-weight: 500;
  line-height: 16px;
}

.box-accordionTable, .table-dashboardEx {
  width: 100%;
  max-height: 616px;
  overflow-y: auto;
  overflow-x: hidden;
}

.table-permissionBox {
  height: 589px;
}

.box-spendTopPadd .btn-viewGoal, .box-unallTopPadd .btn-viewGoal {
  top: 14px;
  right: 95px;
}

.btn-backPage {
  position: absolute;
  top: 13px;
  left: 30px;
  padding: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #d2d2d2;
  border-radius: 50%;
  background: #FFF url('../images/icon-backArrowBlack.png') no-repeat 6px 4px;
  z-index: 9;
}

.btn-backPage:hover {
  background-color: #FFF;
}

.box-spendByTypeChart {
  padding-left: 5px;
  padding-right: 5px;
}

.box-passOverly:after {
  top: inherit;
  bottom: 0px;
}

.teble-expenseType thead {
  background-color: #FFF;
}

.teble-expenseType tr th {
  color: #707070;
}

.teble-expenseType tr th, .teble-expenseType tr td {
  width: 8%;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.teble-expenseType tr th.upTD, .teble-expenseType tr td.upTD {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.teble-expenseType .box-scrollTable.boxTbl-manageTeamInn {
  margin-top: 0px;
}

.remove-ShadowBorder.teble-expenseType .box-scrollTable.boxTbl-manageTeamInn {
  border-radius: 0px;
  border: none;
  box-shadow: none;
}

.btn-addNewExpType {
  margin: 0px;
}

.teble-expenseType tr th:first-child, .teble-expenseType tr td:first-child {
  width: 84%;
  text-align: left;
}

.teble-expenseType.table-manageTeam {
  height: calc(100% - 53px);
}

.empty-textCon {
  text-align: center;
  font-size: 12px;
}

.empty-textCon.empty-textConInMod {
  font-size: 14px;
  text-align: left;
  padding: 21px 0px 0px;
}

.box-addEditExType.box-modalAddGoal .modal-footer {
  padding: 15px 45px 21px;
}

.box-addEditExType .modal-headerInnBox {
  width: 100%;
  display: block;
}

.box-addEditExType .title-addGoal {
  max-width: 100%;
  width: 100%;
  padding-right: 30px;
}

.box-expensType {
  height: calc(100% - 52px)
}

.selectAll-radio {
  color: #B3B3B3;
  padding-right: 0px;
}

.removeDropDown .box-inputAnimationInExp {
  color: #4e4e4e;
  font-size: 15px;
  position: absolute;
  right: -188px;
  top: 0px;
}

.cursorPointer, .cursorPointer tbody tr {
  cursor: pointer !important;
}

.box-goPro {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  background-color: #44a747;
  z-index: 9999999999;
  height: 50px;
  line-height: 50px;
  padding: 0px 30px;
}

.box-goPro strong {
  position: relative;
  font-weight: 700;
  padding-right: 22px;
}

.box-goPro strong:after {
  position: absolute;
  content: '';
  width: 2px;
  height: 20px;
  top: 0px;
  bottom: 0px;
  right: 9px;
  margin: auto;
  background-color: #FFF;
}

.box-goPro .close {
  top: 0px;
  bottom: 0px;
  margin: auto;
  right: 30px;
}

.text-sameAdd {
  color: #4e4e4e;
  font-weight: 600;
}

.input-cardNum input {
  padding: 0px 54px;
}

.input-cardNum {
  position: relative;
}

.input-cardNum:after {
  position: absolute;
  content: '';
  top: 0px;
  right: 15px;
  bottom: 0px;
  margin: auto;
  width: 17px;
  height: 13px;
  background: url('../images/icon-greenRight.png') no-repeat center center;
  z-index: 9;
}

.input-cardNum em {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 10px;
  margin: auto;
  width: 34px;
  height: 26px;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 9;
}

.box-cardNumber.box-inputAnimation label {
  left: 54px;
}

.box-cardNumber.box-inputAnimation label.trigger {
  left: 0px;
}

.box-accountMainInn {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
}

.box-accountMainInn .nav-mainDashTab {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 100%;
  margin-left: 0px;
  background-color: #FFF;
}

.rightBorder {
  border-right: 1px solid #EFEFEF;
}

.leftBorder {
  border-left: 1px solid #EFEFEF;
}

.renew-trails, .end-trails {
  font-weight: normal;
}

.end-trails {
  position: absolute;
  bottom: -5px;
  left: 0px;
}

h3.add-users-message {
  padding-bottom: 0;
  padding-top: 15px;
}

h3.add-users-message a.link-here {
  float: none;
}

.btn-edit, .btn-edit:hover, .btn-edit:focus, .btn-edit:active {
  float: right;
  padding: 0px;
  width: 15px;
  height: 15px;
  display: block;
  background: url("../images/icon-edit.png") no-repeat center center transparent;
  background-size: 90%;
}

.box-details {
  padding-bottom: 20px;
  width: 100%;
  display: block;
}

.box-details p {
  color: #323232;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 0px;
  line-height: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.contactNum {
  padding-top: 20px;
}

.text-center {
  text-align: center;
}

.table-accountEdit tr td, .table-accountEdit tr th {
  width: 38%;
}

.table-accountEdit tr td:first-child, .table-accountEdit tr th:first-child {
  width: 60px;
  min-width: 60px;
}

.table-accountEdit tr td:last-child, .table-accountEdit tr th:last-child {
  width: 24%;
}

.box-scrollTableAcc {
  max-height: 270px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.box-paymentMethod {
  width: 100%;
  display: inline-block;
}

.btn-makeDefault {
  position: relative;
  top: -1px;
  color: #3875b4;
  font-size: 11px;
  font-weight: 500 !important;
  padding: 0px;
  background-color: transparent;
}

.btn-makeDefault:hover, .btn-makeDefault:focus {
  color: #3875b4;
  background-color: transparent;
}

.row-accDefault, .row-accDefault [class*="col-"] {
  height: 100%;
}

.modalBody-assignAcc h4 {
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 35px;
}

.modalBody-confirmAccDetail h4 {
  line-height: 30px;
  text-align: left;
}

.col-accOwnerLeft {
  font-size: 16px;
  font-weight: 600;
  line-height: 59px;
}

.box-modalAddGoal .modal-footer.modalFooter-assignAcc {
  padding-left: 30px;
  padding-right: 30px;
}

.list-assignAcc {
  position: relative;
  width: 100%;
  display: inline-block;
}

.list-assignAccInn {
  position: relative;
  top: 100%;
  left: 0px;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  display: inline-block;
  border: 1px solid #D3D3D3;
  border-radius: 0px 0px 4px 4px;
}

.list-assignAccInn li {
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  display: block;
  border-bottom: 1px solid #D3D3D3;
}

.list-assignAccInn li:last-child {
  border-bottom: 0px;
}

.list-assignAccInn li span {
  display: block;
}

.hs-error-msgs li label a, .hs-error-msgs li label a:hover, .hs-error-msgs li label a:focus {
  color: #424242;
}

.box-padd10-15 {
  padding: 10px 15px 0px;
}

.btn-startSub, .btn-remvoeSub {
  float: right;
  font-size: 11px;
  color: #707070;
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  background-color: transparent;
  text-transform: uppercase;
  margin: 15px 0px 0px 13px;
}

.btn-startSub {
  color: #FFF;
  width: 210px;
  height: 34px;
  border-radius: 4px;
  background-color: #ff4181;
}

.btn-startSub:hover {
  background-color: #ed5d8e;
}

.btn-remvoeSub {
  padding: 0px;
  margin-top: 21px;
}

.btn-remvoeSub:hover {
  background-color: transparent;
}

.modalBody-currency {
  padding: 20px 0px 0px;
}

.modalBody-currency .table-viewAdd tr td, .modalBody-currency .table-viewAdd thead tr th {
  width: 18%;
  text-align: center;
  padding: 8px 15px;
  line-height: 14px;
}

.modalBody-currency .table-viewAdd tr td:first-child, .modalBody-currency .table-viewAdd thead tr th:first-child {
  width: 28%;
  text-align: left;
}

.modalBody-currency .table-viewAdd tr th span {
  position: relative;
  padding-left: 6px;
  margin-left: 3px;
}

.modalBody-currency .table-viewAdd tr th span:after {
  position: absolute;
  content: '';
  width: 1px;
  height: 14px;
  background-color: #909090;
  left: 0;
  top: 0;
}

.modalBody-currency thead tr {
  border: 0px;
}

.modalFooter-currency p {
  font-weight: 500;
  text-align: left;
  padding: 15px 0px 25px;
}

.box-modalAddGoal .modal-footer.modalFooter-currency {
  padding-left: 30px;
  padding-right: 30px;
}

.row-disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

.box-bodyScroll {
  height: 100%;
  overflow: hidden;
}

.box-bodyScroll .box-modalContentInn, .box-bodyScroll .box-modalContentPara, .box-bodyScroll .box-modalContentParaInn, .box-bodyScroll .row-addGoal {
  height: 100%;
}

.box-bodyScroll .box-viewLogTable .row-addGoal {
  height: calc(100% - 112px);
}

.box-bodyScroll .box-viewLogTable.viewLogOpen .row-addGoal {
  height: calc(100% - 300px);
}

.box-bodyScroll .box-viewLogTable.viewLogOpen .table-dashboardEx {
  height: 190px;
}

.box-bodyScroll .modal-body {
  height: calc(100% - 140px);
  overflow: hidden;
}

.box-bodyScroll.box-modalAddGoal {
  padding-left: 165px;
  padding-right: 185px;
  width: 1020px;
}

.box-wapperTwo .box-topBar .list-companyDropDown {
  padding-top: 17px !important;
}

/*Goal Container Plan Detail*/
.box-goalCardsInn.box-campCardsInn {
  cursor: pointer !important;
}

.box-goalCardsInn.box-campCardsInn .panel-heading {
  height: 38px;
}

.box-goalCardsInn .frow-camCardRight {
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-goalCardsInn .panel-timeFrame {
  position: absolute;
  right: 8px;
  top: 3px;
  color: #323232;
  font-size: 12px;
  font-weight: 600;
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.box-progressBar.box-progressBarGoal {
  padding: 18px 6px;
}

.box-goalCardsInn .panel-body {
  border-radius: 0px 0px 6px 6px;
  height: 166px;
}

.box-goalCardsInn .box-progressBar .progress {
  margin: 0px 0px 7px;
  height: 32px;
  width: 100%;
  border-radius: 0px;
  background-color: #bdbdbd;
  position: relative;
  overflow: hidden;
}

.box-goalCardsInn .box-progressBar .progress:last-child {
  margin-bottom: 0px;
}

.box-goalCardsInn .box-progressBar .progress span {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  line-height: 32px;
  font-size: 12px;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  padding: 0px 10px;
}

.box-goalCardsInn .progress-barHor {
  box-shadow: none;
  background-color: #3272b7;
  white-space: nowrap;
  border: 0;
}

.orange {
  background-color: #ff9800 !important;
}

.box-progressBar .progress-barHor:last-child:before, .box-progressBar .progress-barHor:first-child:after {
  display: none;
}

.box-noContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 0px 0px 4px 4px;
}

.btn-noContent {
  float: none;
  padding: 0px 10px;
  border-radius: 3px;
  margin: 10px 0px 0px;
  display: inline-block;
  min-width: 114px;
  height: 25px;
  line-height: 25px;
}

.box-viewModalImg {
  position: absolute;
  right: 25px;
  bottom: -50%;
}

.box-viewProgModalImg.box-viewModalImg {
  top: -36px;
  left: -36px;
  right: inherit;
  bottom: inherit;
}

.box-viewModalImg > span {
  width: 82px;
  height: 82px;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #265689;
  background-color: #75a8dc;
  text-transform: uppercase;
  background-size: 100%;
}

.box-viewProgModalImg.box-viewModalImg > span {
  width: 72px;
  height: 72px;
}

.box-viewModalImg .profile-tooltip {
  text-transform: capitalize;
}

.box-metrics {
  width: 100%;
  display: inline-block;
  padding: 0px 30px 30px;
}

.box-metricsInn {
  width: 100%;
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  border: 1px solid #F1F1F1;
  border-top: 1px solid #000;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.box-metricsInn h4 {
  position: relative;
  font-size: 16px;
  width: 100%;
  height: 44px;
  color: #323232;
  font-weight: 700;
  background-color: #F1F1F1;
  border-radius: 6px 6px 0px 0px;
  padding: 14px 15px 0px;
}

.link-updateMetrics {
  position: absolute;
  top: 17px;
  right: 15px;
  color: #265689;
  font-size: 10px;
  text-transform: uppercase;
}

.link-updateMetrics:active, .link-updateMetrics:hover, .link-updateMetrics:focus {
  color: #265689;
}

.row-metrics {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #F1F1F1;
  border-bottom: 1px solid #F1F1F1;
}

.row-metrics.box-noMetric {
  flex-direction: column;
  border: 0px;
  padding: 0px 30px;
}

.col-metrics-left, .col-metrics-right {
  width: 50%;
}

.col-metrics-right .box-progressBarGoal, .col-metrics-left h5 {
  border-top: 1px solid #F1F1F1;
}

.col-metrics-right .box-progressBarGoal:first-child, .col-metrics-left h5:first-child {
  border-top: 0px;
}

.box-goalConTooltip {
  position: absolute;
  top: 100%;
  left: 10px;
  padding: 8px 10px;
  background-color: #FFF;
  border-radius: 3px;
  border: 1px solid #27568a;
  display: none;
  color: #323232;
  white-space: nowrap;
  text-align: center;
  z-index: 99;
  letter-spacing: 0.5px;
  color: #323232;
  line-height: 14px;
}

.box-goalConTooltip:after {
  position: absolute;
  left: 10px;
  top: -9px;
  content: '';
  width: 10px;
  height: 10px;
  background: url('../images/icon-tooltipArrowBlueT.png') no-repeat center center;
}

.box-tooltipWapper:hover .box-goalConTooltip, .panel-heading:hover .box-goalConTooltip {
  display: block;
}

.box-goalConTooltip h6 {
  color: #323232;
  font-size: 10px;
  font-weight: 700;
  padding: 0px 0px 2px;
  text-transform: capitalize;
}

.box-goalConTooltip h6, .box-goalConTooltip b, .box-goalConTooltip i {
  display: block;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  margin: auto;
}

.link-click4More {
  color: #27568a;
  display: inline-block;
  margin: auto;
  font-size: 10px;
  font-weight: 700;
  margin-top: 5px;
}

.link-click4More:hover, .link-click4More:active, .link-click4More:focus {
  color: #27568a;
}

.box-goalConTooltip b {
  font-weight: normal;
  font-size: 9px;
}

.box-goalConTooltip i {
  font-size: 8px;
}

.box-tooltipWapper {
  position: relative;
  width: 100%;
  display: block;
  float: left;
}

.box-goalCardsInn .box-progressBar .box-tooltipWapper {
  margin-bottom: 7px;
}

.box-goalCardsInn .box-progressBar .box-tooltipWapper .progress {
  margin-bottom: 0px !important;
}

.border-topRadius {
  border-radius: 6px 6px 0px 0px;
}

.border-bottomRadius {
  border-radius: 0px 0px 6px 6px;
}

.box-glCode {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.box-glCode .row {
  height: 100%;
  overflow: hidden;
}

.box-glCode .row [class*="col-"] {
  height: 100%;
  overflow: hidden;
}

.box-glCode h4 {
  position: relative;
  font-size: 13px;
  font-weight: 600;
  color: #323232;
  text-transform: uppercase;
  padding: 9px 15px 11px;
}

.btn-add {
  position: absolute;
  top: 6px;
  right: 15px;
  font-size: 12px;
  color: #c5cbcb;
  text-transform: uppercase;
  padding: 2px 22px 3px 32px;
  border: 1px solid #c5cbcb;
  background-color: transparent;
}

.btn-add:before, .btn-add:after {
  position: absolute;
  left: 18px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  content: '';
  width: 7px;
  height: 1px;
  background-color: #c5cbcb;
}

.btn-add:before {
  left: 21px;
  width: 1px;
  height: 7px;
}

.btn-add:hover:before, .btn-add:hover:after {
  background-color: #3273b7;
}

.btn-add:hover, .btn-add:active {
  color: #3273b7;
  background-color: #FFF;
  border-color: #3273b7;
}

.box-glCode .col-glCode {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: #fff;
}

.box-glCodeInn {
  padding: 20px 15px;
  height: calc(100% - 34px);
  overflow: hidden;
}

ul.list-glCode {
  padding-bottom: 15px;
}

ul.list-glCode li {
  position: relative;
  font-size: 13px;
  font-weight: 600;
  color: #323232;
  padding-left: 20px;
}

ul.list-glCode li:after {
  position: absolute;
  top: 9px;
  left: 0px;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #323232;
}

.box-glCodeInn .box-scrollTable {
  height: calc(100% - 81px);
  overflow: auto;
}

.table-glCode {
  width: 100%;
}

.table-glCode thead tr {
  background-color: #FFF;
  border-top: 1px solid #eaeaea;
  text-transform: uppercase;
}

.table-glCode tbody tr {
  border-top: 1px solid #eaeaea;
}

.table-glCode tbody tr:nth-child(odd) {
  background-color: #f2f8ff;
}

.table-glCode thead tr th, .table-glCode tbody tr td {
  color: #707070;
  vertical-align: middle;
  padding: 5px 15px;
  font-weight: 600;
  font-size: 11px;
  width: 40%;
}

.table-glCode tbody tr td {
  font-weight: 500;
}

.table-glCode thead tr th:last-child, .table-glCode tbody tr td:last-child {
  text-align: center;
}

ul.list-graphAddProdInn li .two-letterCurr input {
  padding-left: 27px;
}

ul.list-graphAddProdInn li .three-letterCurr input {
  padding-left: 32px;
}

.input-budgetField.two-letterCurr input {
  padding-left: 38px !important;
}

.input-budgetField.three-letterCurr input {
  padding-left: 45px !important;
}

.table-glCode thead tr th:first-child, .table-glCode tbody tr td:first-child {
  width: 35%;
}

.table-glCode thead tr th:last-child, .table-glCode tbody tr td:last-child {
  text-align: center;
  width: 25%;
}

.table-glCodeExpenseType.table-glCode thead tr th,
.table-glCodeExpenseType.table-glCode tbody tr td {
  width: 30%;
}

.table-glCodeExpenseType.table-glCode thead tr th:first-child,
.table-glCodeExpenseType.table-glCode tbody tr td:first-child {
  width: 45%;
}

.table-glCodeExpenseType.table-glCode thead tr th:last-child,
.table-glCodeExpenseType.table-glCode tbody tr td:last-child {
  width: 25%;
}

ul.list-btnEditDel {
  display: inline-block;
  line-height: normal;
  padding-top: 3px;
  width: 100%
}

ul.list-btnEditDel li {
  position: relative;
  display: inline-block;
  padding: 3px 0px 0px;
  width: 48%;
  line-height: normal;
}

ul.list-btnEditDel li + li:after {
  position: absolute;
  left: -1px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  content: '';
  width: 1px;
  height: 20px;
  background-color: #3c78b1;
}

ul.list-btnEditDel li button {
  position: static;
  padding: 0px;
  background-color: transparent;
}

.btn-addCampaignView.shift-top.btn-quickAddExp {
  right: 124px;
}

.table-accordion table {
  background-color: transparent;
}

.table-accordion tr:last-child {
  border-bottom: 0px !important;
}

.table-accordion tr.table-accordionHead, .table-accordion tr {
  border-bottom: 1px solid #d4d4d4 !important;
}

.table-accordion tr tr {
  border-bottom: 0px !important;
}

.table-accordion tr:nth-child(even) {
  background-color: #f2f8ff;
}

.table-accordion tr td {
  text-align: left;
  padding-left: 10px;
}

.table-accordion .table-contOverflow {
  position: static;
  width: 230px;
  max-width: 230px;
}

.table-accordionUnassign .table-contOverflow {
  width: 293px;
  max-width: 293px;
}

.box-Unallocated .table-accordion .table-contOverflow {
  width: 248px;
  max-width: 248px;
}

.table-accordion tr td:first-child,
.table-accordion tr td:nth-child(2) {
  width: 5%;
  padding-left: 0px;
  text-align: center;
}

.table-accordion tr td:nth-child(3),
.table-accordion tr td:nth-child(6),
.table-accordion tr td:nth-child(7) {
  width: 17%;
}

.table-accordion tr td:nth-child(5),
.table-accordion tr td:nth-child(4),
.table-accordion tr td:nth-child(8) {
  width: 13%;
}

.table-accordionUnassign.table-accordion tr td:nth-child(4),
.table-accordionUnassign.table-accordion tr td:nth-child(5),
.table-accordionUnassign.table-accordion tr td:nth-child(7) {
  width: 16%;
}

.table-accordionUnassign.table-accordion tr td:nth-child(3),
.table-accordionUnassign.table-accordion tr td:nth-child(6) {
  width: 21%;
}

.box-Unallocated.box-accordionTable .table-accordion tr td {
  text-align: left;
  padding-left: 10px;
}

.box-Unallocated.box-accordionTable .table-accordion tr td:nth-child(2) {
  width: 44%;
}

.box-Unallocated.box-accordionTable .table-accordion tr td:nth-child(3),
.box-Unallocated.box-accordionTable .table-accordion tr td:nth-child(4) {
  width: 28%;
}

.table-accordion tfoot tr td.td-expenseNotFound {
  text-align: center !important;
}

.table-accordion tr td:last-child {
  width: 30px !important;
  min-width: 30px !important;
  padding-left: 0px !important;
}

.table-accordionHead > td {
  color: #707070;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 0px 8px 10px;
  line-height: 20px;
}

.table-accordionHead .form-checkbox {
  margin-top: 10px;
}

.table-accordionInnHead td {
  color: #707070;
  font-size: 11px;
  font-weight: 600;
  padding: 5px 0px 3px 10px;
  line-height: 20px;
}

.table-accordion tr td.table-accordionTD {
  width: 100%;
  border-top: 1px solid #d4d4d4;
  padding: 0px !important;
}

.row-halfCol .col-xs-12 + .col-xs-12 + .col-xs-12 {
  margin-top: 40px;
}

.row-halfCol .col-sm-6 {
  float: none;
  display: inline-block;
  width: calc(50% - 4px);
  vertical-align: top;
}

.btn-addCards {
  position: absolute;
  top: 13px;
  right: 45px;
  width: 23px;
  height: 23px;
  display: block;
  border-radius: 50%;
  background-color: #c4d0de;
}

.btn-addCards:hover, .btn-addCards:focus, .btn-addCards:active {
  background-color: #6c6c6c;
}

.btn-addCards:after, .btn-addCards:before {
  position: absolute;
  content: '';
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  width: 1px;
  height: 13px;
  background-color: #6c6c6c;
}

.btn-addCards:after {
  transform: rotate(90deg)
}

.btn-addCards:hover:after, .btn-addCards:focus:after, .btn-addCards:active:after,
.btn-addCards:hover:before, .btn-addCards:focus:before, .btn-addCards:active:before {
  background-color: #c4d0de;
}

.box-userTypeAdmin {
  padding: 15px 25px;
  border-radius: 4px;
  background-color: #F0F0F0;
}

.box-userTypeAdmin + .box-userTypeAdmin {
  margin-top: 30px;
}

.box-userTypeAdmin h6 {
  color: #4e7a34;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 0px;
}

.box-userTypeAdmin .row-halfCol {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 26px;
}

.panel-userTypeAdmin {
  padding-left: 50px;
}

.btn-addPermission {
  position: relative;
  color: #4a772f;
  font-size: 13px;
  padding: 0px;
  font-weight: 600;
  background-color: transparent;
  padding: 0px 0px 0px 12px;
}

.btn-addPermission:after, .btn-addPermission:before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto;
  width: 7px;
  height: 1px;
  background-color: #4a772f;
}

.btn-addPermission:after {
  width: 1px;
  height: 7px;
  left: 3px;
}

.btn-addPermission:hover, .btn-addPermission:focus {
  color: #5d8445;
  background-color: transparent;
}

.godMode ul.list-assOwner {
  padding: 0px 0px 5px;
}

ul.list-btnTable {
  width: 100%;
  display: inline-block;
  text-align: center;
  line-height: normal;
  padding-top: 2px;
}

ul.list-btnTable li {
  display: inline-block;
  width: 47%;
}

ul.list-btnTable li .btn-tableDelete, ul.list-btnTable li .btn-tableAdd, ul.list-btnTable li .btn-tableEdit {
  position: static;
}

.cuppa-dropdown .dropdown-list .list-area h5.list-message {
  display: block;
  height: 30px;
  line-height: 30px;
}

.box-searchValue {
  position: relative;
  width: 100%;
  display: inline-block;
}

.box-searchValue h4 {
  color: #707070;
  font-size: 13px;
}

.btn-closeSearch {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #fc531f;
  font-size: 13px;
  background-color: transparent;
  text-transform: uppercase;
  padding: 0px 20px 0px 0px;
  opacity: 0.7;
}

.btn-closeSearch:hover, .btn-closeSearch:active, .btn-closeSearch:focus {
  background-color: transparent;
  opacity: 1;
}

.btn-closeSearch span {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
}

.btn-closeSearch span:before, .btn-closeSearch span:after {
  position: absolute;
  content: '';
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  width: 2px;
  height: 14px;
  background-color: #fc531f;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 0.8;
}

.btn-closeSearch span:before {
  width: 14px;
  height: 2px;
}

.btn-closeSearch:hover, .btn-closeSearch:active, .btn-closeSearch:focus {
  color: #fc531f;
}

.btn-closeSearch:focus span:before, .btn-closeSearch:focus span:after,
.btn-closeSearch:hover span:before, .btn-closeSearch:hover span:after,
.btn-closeSearch:active span:before, .btn-closeSearch:active span:after {
  background-color: #fc531f;
}

.box-tableSearch {
  height: calc(100% - 29px);
  overflow: auto;
}

.box-tableSearch .table-accordionInnHead {
  cursor: pointer;
}

.table-search .thead-sorting .icon-sortingTable {
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
}

.table-search .table-accordionHead {
  border-bottom: 1px solid #D4D4D4;
}

.table-search tr td {
  width: 16.66%;
}

.table-search tr td:last-child, .table-search tr td:nth-last-child(2), .table-search tr td:nth-last-child(3) {
  min-width: 150px;
  width: 150px;
}

.table-search .table-accordionInnHead {
  border-bottom: 1px solid #d4d4d4;
  background-color: #FFF;
}

.table-search .table-accordionInnHead:nth-child(even) {
  background-color: #F2F8FF;
}

.table-search .table-accordionInnHead:last-child {
  border-bottom: 0px;
}

.red-text {
  color: #e85353;
}

ul.list-timeFrameDisk {
  padding: 0px;
  margin: 0px;
  width: 100%;
  display: inline-block;
  max-width: 110px;
}

ul.list-timeFrameDisk.disk12 {
  display: block;
}

ul.list-timeFrameDisk li {
  padding: 0px 4px;
  float: left;
}

ul.list-timeFrameDisk li span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  border: 1px solid #2a5788;
}

ul.list-timeFrameDisk li span.filled {
  background-color: #2a5788;
}

ul.list-timeFrameDisk.disk12 li + li + li + li + li + li + li {
  padding-top: 4px;
}

.link-allPermission {
  font-size: 15px;
  color: #00bcd4;
  float: left;
  font-weight: 600;
  margin-top: 6px;
}

.link-allPermission:hover, .link-allPermission:active, .link-allPermission:focus {
  color: #00bcd4;
}

.table-permission tr td + td {
  padding-top: 10px;
}

.table-permission thead tr th, .table-permission tbody tr td {
  width: 50%;
  text-transform: none;
}

.table-permission thead tr th:nth-child(2),
.table-permission tbody tr td:nth-child(2) {
  width: 95px;
  min-width: 95px;
}

.table-permission thead tr th:nth-child(3),
.table-permission tbody tr td:nth-child(3) {
  width: 150px;
  min-width: 150px;
}

.table-permission .box-modalContentParaInn {
  box-shadow: none !important;
}

.btn-linkClone, .btn-linkClone:hover, .btn-linkClone:focus, .btn-linkClone:active {
  position: absolute;
  top: 9px;
  right: 30px;
  color: #00bcd4;
  padding: 0px !important;
  text-align: center;
  background-color: transparent;
}

.title-pageHeading {
  float: left;
  padding: 0px 0px 5px;
  color: #3272b7;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .box-campCardsInn .panel-heading .box-camCardAmt em, .box-camCardAmt em {
    line-height: 14px;
  }
}

/* All Pages Media Css Start*/
@media(max-width: 1860px) {
  .table-accordion .table-contOverflow {
    width: 200px;
    max-width: 200px;
  }
  .table-accordionUnassign .table-contOverflow {
    width: 270px;
    max-width: 270px;
  }
  .box-Unallocated .table-accordion .table-contOverflow {
    width: 220px;
    max-width: 220px;
  }
}

@media(max-width: 1680px) {
  .box-scroll .table-accountHistory tr td:first-child {
    padding-left: 15px;
  }
  .unread-tableView .table-viewAdd tr td:first-child, .unread-tableView .table-viewAdd thead tr th:first-child {
    padding-left: 50px;
  }
  ul.list-programCard > li, ul.list-campCards > li {
    width: 318px;
  }
  ul.list-programCardsTymStatus li span {
    max-width: 128px;
  }
  .panel-camCardHead ul.list-programCardsTymStatus li span {
    max-width: 142px;
  }
  .select-category .selected-list .c-btn > span, .select-category .selected-list .c-list .c-token,
  .box-spendBtTypeTopBar .select-category .selected-list .c-list .c-token {
    width: 140px !important;
  }
  .box-newTopNav .box-topBar.box-spendBtTypeTopBar {
    padding-right: 85px;
  }
  .box-spendBtTypeTopBar .select-category .selected-list .c-list .c-token .c-label {
    width: 80px;
  }
  .select-category .selected-list .c-btn > .c-list + span {
    width: auto !important;
  }
  .box-spendBtTypeTopBar ul.list-allCategory > li.list-clearAllFilter {
    padding-right: 0px;
  }
}

@media(max-width: 1679px) {
  ul.list-programCard > li, ul.list-campCards > li {
    width: 338px;
  }
  ul.list-programCardsTymStatus li span {
    max-width: 145px;
  }
  .panel-camCardHead ul.list-programCardsTymStatus li span {
    max-width: 162px;
  }
  .table-accordion .table-contOverflow {
    width: 180px;
    max-width: 180px;
  }
  .table-accordionUnassign .table-contOverflow {
    width: 233px;
    max-width: 233px;
  }
  .box-Unallocated .table-accordion .table-contOverflow {
    width: 200px;
    max-width: 200px;
  }
}

@media(max-width: 1579px) {
  .box-newDashboardRightInn {
    padding-top: 23px;
  }
  .btn-clearUnread {
    top: 33px;
    right: 60px;
  }
  .btn-viewGoal {
    top: 33px;
    right: 153px;
  }

  ul.nav-tabs > li > a {
    font-size: 11px;
  }
  .box-campCardsInn .panel-heading span.panel-title {
    font-size: 14px;
  }
  .box-pageHeadingBar {
    font-size: 12px;
  }
  ul.list-allCategory > li .link-home {
    width: 34px;
    height: 34px;
    line-height: 28px;
  }
  .btn-viewGoal, .btn-clearUnread {
    font-size: 10px;
    padding: 2px 13px 1px;
  }
  .box-newTopNav .box-rightBule {
    height: calc(100% - 96px);
  }
  .btn-closeSearch.btn-clearAllFilter {
    top: 6px;
  }
  .box-newTopNav .box-topBar.box-spendBtTypeTopBar {
    padding-right: 55px;
  }
  .box-accordionTable {
    overflow: auto;
  }
  .table-accordion .table-contOverflow {
    width: 160px;
    max-width: 160px;
  }
  .table-accordionUnassign .table-contOverflow {
    width: 210px;
    max-width: 210px;
  }
  .box-Unallocated .table-accordion .table-contOverflow {
    width: 140px;
    max-width: 140px;
  }
  .box-Unallocated.box-accordionTable .table-accordion tr td:nth-child(2) {
    width: 38%;
  }
  .box-Unallocated.box-accordionTable .table-accordion tr td:nth-child(3),
  .box-Unallocated.box-accordionTable .table-accordion tr td:nth-child(4) {
    width: 31%;
  }
  ul.list-tabBankDetail li a {
    font-size: 14px;
  }
  ul.list-timeFrameDisk {
    max-width: 94px;
  }
  ul.list-timeFrameDisk li span {
    width: 7px;
    height: 7px;
  }
  .select-category .selected-list .c-btn > span, .select-category .selected-list .c-list .c-token,
  .box-spendBtTypeTopBar .select-category .selected-list .c-list .c-token {
    width: 128px !important;
  }
  .box-spendBtTypeTopBar .select-category .selected-list .c-list .c-token .c-label {
    width: 68px;
  }
  .btn-closeSearch.btn-clearAllFilter {
    top: 3px;
  }
}

@media(max-width: 1480px) {
  .select-category .selected-list .c-btn > span, .select-category .selected-list .c-list .c-token,
  .box-spendBtTypeTopBar .select-category .selected-list .c-list .c-token {
    width: 123px !important;
  }
  .box-spendBtTypeTopBar .select-category .selected-list .c-list .c-token .c-label {
    width: 63px;
  }
}

@media(max-width: 1440px) {
  ::-webkit-input-placeholder {
    font-size: 13px;
  }
  ::-moz-placeholder {
    font-size: 13px;
  }
  :-ms-input-placeholder {
    font-size: 13px;
  }
  input:-moz-placeholder {
    font-size: 13px;
  }
  .box-modalContent {
    width: 780px;
  }
  .modal-header span, .modal-header {
    font-size: 15px;
  }
  .box-deliveryDateView span {
    font-size: 11px;
    line-height: 14px;
  }
  .box-btnAddBox button, .box-addNotesView h4 {
    font-size: 14px;
  }
  .box-deliveryDateView em, .modal-header h4 {
    font-size: 15px;
  }
  ul.list-viewExpense li h4 {
    font-size: 16px;
  }
  .modal-body {
    padding: 25px 30px 40px;
  }
  .box-addEditExType .modal-body {
    padding-top: 45px;
  }
  .modal-header {
    padding: 14px 20px 15px;
  }
  .modal-headerRightList {
    padding: 10px 0px 0px 20px;
  }
  .title-addGoal {
    padding-bottom: 11px;
    margin-top: 4px;
    max-width: 245px;
  }
  .addUser .title-addGoal {
    max-width: 692px;
    padding-right: 30px;
  }
  .modal-accordions .panel-body {
    padding-left: 45px;
  }
  .modal-accordions .panel-collapse.in:after {
    left: 17px;
  }
  .box-userTypeMain .link-addGoalAccordion,
  .box-bodyScroll .link-addGoalAccordion, .link-addGoalAccordion {
    font-size: 15px;
    height: 34px;
    padding: 6px 0px 0px 60px;
  }
  .link-addGoalAccordion span {
    width: 35px;
    height: 34px;
    line-height: 32px;
    font-size: 16px;
  }
  .input-animationW, .input-animation, select.input-animation, select.input-animationW {
    height: 46px;
    font-size: 13px;
    line-height: 46px;
    border-radius: 2px;
  }
  .box-inputAnimation label {
    top: 11px;
    font-size: 13px;
  }
  .input-budgetField.two-letterCurr input {
    padding-left: 37px !important;
  }
  .input-budgetField.three-letterCurr input {
    padding-left: 45px !important;
  }
  .panel-bodyAddGoal {
    padding-top: 30px !important;
  }
  .modal-accordions .panel-bodyAddGoal {
    padding-top: 45px !important;
  }
  .box-modalAddGoal .modal-footer {
    padding-left: 90px;
  }
  .box-bodyScroll .modal-footer {
    padding-left: 30px;
  }
  .modal-accordions .form-checkbox {
    font-size: 13px;
  }
  .btn-addUser {
    font-size: 13px;
    padding: 8px 15px 8px 35px;
    background-position: 12px 13px;
    background-size: 16px;
  }
  ul.list-upload li {
    padding-top: 20px;
  }
  .box-topBar {
    height: 44px;
  }
  .box-newDashboardRightInn {
    padding: 23px 0px 20px;
  }
  .box-widgetBottom .box-widgetPara {
    padding: 36px 0px 0px;
  }
  .box-timeLineGraph {
    padding: 15px 0px 10px;
  }
  .box-timeLineGraphInn {
    padding-bottom: 10px;
  }
  .link-widgetOther {
    top: 7px;
    left: 15px;
  }
  .box-campCardsInn .chart-abs {
    top: -3px;
    right: 0px;
  }

  .table-dashboardEx table tr td {
    padding: 4px 5px;
  }
  .table-permission tr td + td {
    padding-top: 10px;
  }
  .table-viewAdd thead tr th {
    padding: 9px 5px 7px;
  }
  .box-scroll table tr td {
    position: relative;
  }
  .box-rightSide {
    margin-left: 55px;
    width: calc(100% - 280px);
  }
  .box-wapperWidget .box-rightSide, .manage-sheet-empty .box-rightSide, .box-rightSide.section-removeBlue, .box-wapperTwo .box-rightSide, .box-rightSide.tabledata {
    width: calc(100% - 55px);
  }
  .box-planSettingMSG p {
    font-size: 13px;
  }
  .box-dashboardRightInn {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .link-expenseTbl, .table-viewAdd thead tr th {
    font-size: 10px;
    line-height: 18px;
  }
  .link-removeField {
    top: -7px;
    right: -7px;
    width: 14px;
    height: 14px;
  }
  .link-removeField:after {
    width: 8px;
    height: 1px;
  }
  .link-removeField:before {
    width: 1px;
    height: 8px;
  }
  .box-campCardsInn .panel-heading em {
    bottom: -1px;
  }
  .select-category .selected-list .c-btn > span, .select-category .selected-list .c-list .c-token {
    width: 140px;
  }
  .select-category .selected-list .c-list .c-token .c-label {
    width: 80px;
  }
  .box-tabViewNav ul.nav-tabs li a {
    font-size: 13px;
    padding: 6px 20px;
  }
  .box-addNotesView {
    padding: 0px 30px 40px;
  }
  .box-deliveryDateView {
    left: 30px;
  }
  ul.list-programCard > li, ul.list-campCards > li {
    width: 360px;
  }
  ul.list-programCardsTymStatus li span {
    max-width: 170px;
  }
  .panel-camCardHead ul.list-programCardsTymStatus li span {
    max-width: 184px;
  }
  .box-campCardsInn .panel-heading .box-camCardAmt em, .box-camCardAmt em {
    width: 12px;
    height: 12px;
    font-size: 7px;
    line-height: 10px;
    right: 3px;
  }
  .box-goalCardsInn .box-progressBar .progress span, .box-progressBar .progress-bar {
    font-size: 11px;
  }
  .btn-maximize {
    width: 16px;
    height: 18px;
    margin-top: 3px;
    background-size: 100%;
  }
  .box-newTopNav .box-topBar {
    height: 45px;
    width: calc(100% - 55px);
    margin-left: 55px;
  }
  .nav-mainDashTab {
    margin-left: 55px;
    width: calc(100% - 55px);
  }
  .box-newTopNav .box-rightBule {
    height: calc(100% - 76px);
  }
  .btn-addNewExpType {
    font-size: 13px;
    padding: 5px 20px;
  }
  .empty-textCon.empty-textConInMod {
    font-size: 12px;
  }
  .select-category .selected-list .c-btn > span, .select-category .selected-list .c-list .c-token,
  .select-category .selected-list .c-btn > span, .select-category .selected-list .c-list .c-token,
  .box-spendBtTypeTopBar .select-category .selected-list .c-list .c-token {
    width: 117px !important;
  }
  .box-spendBtTypeTopBar .select-category .selected-list .c-list .c-token .c-label {
    width: 57px;
  }
  .box-accordionTable, .table-dashboardEx {
    max-height: 400px;
  }
  .table-permissionBox {
    height: 380px;
  }
  .startDate-timeLineGraph, .endDate-timeLineGraph {
    bottom: -12px;
  }
  ul.list-programCard {
    padding: 11px 0px;
  }
  .row-addGoal h5 {
    font-size: 14px;
    padding-bottom: 5px;
  }
  .removeDropDown .box-inputAnimationInExp {
    right: -184px;
  }
  .modalBody-assignAcc h4 {
    font-size: 15px;
  }
  .col-accOwnerLeft {
    width: 28%;
    font-size: 13px;
    line-height: 46px;
  }
  ul.list-allCategory > li.list-selectCategory {
    padding-top: 2px;
  }
  .btn-startSub {
    width: 200px;
    height: 33px;
    margin-top: 6px;
  }
  .btn-remvoeSub {
    margin-top: 12px;
  }
  .modalBody-assignAcc {
    padding-top: 45px;
  }
  .box-details p {
    font-size: 12px;
    line-height: 20px;
  }
  .btn-edit {
    font-size: 13px;
    font-weight: 500;
    padding: 0px 6px;
  }
  .box-bodyScroll .modal-body {
    height: calc(100% - 122px);
  }
  .box-viewModalImg {
    right: 15px;
  }
  .box-viewModalImg > span {
    width: 62px;
    height: 62px;
  }
  .modal-headerInnBox.modal-headerGoalView {
    width: calc(100% - 10px);
  }
  .box-viewProgModalImg.box-viewModalImg {
    top: -26px;
    left: -26px;
  }
  .box-viewProgModalImg.box-viewModalImg > span {
    width: 52px;
    height: 52px;
    font-size: 13px;
  }
  .box-metricsInn h4 {
    font-size: 14px;
  }

  .box-bodyScroll .box-viewLogTable .row-addGoal {
    height: calc(100% - 87px);
  }
  .box-bodyScroll .box-viewLogTable.viewLogOpen .row-addGoal {
    height: calc(100% - 277px);
  }
  .btn-addCampaignView.shift-top {
    top: -28px;
  }
  .box-topBarCompany .box-compFilter:after {
    top: 6px;
  }
  .box-thanksUpgrade h4 {
    font-size: 18px;
    padding-bottom: 40px;
  }
  .box-thanksUpgrade p {
    font-size: 15px !important;
    line-height: 22px !important;
  }
  .box-thanksUpgrade p strong {
    font-size: 12px;
  }

  .table-accordion .table-contOverflow {
    font-size: 11px;
    line-height: 20px;
    width: 150px;
    max-width: 150px;
  }
  .table-accordionUnassign .table-contOverflow {
    width: 192px;
    max-width: 192px;
  }
  .modalBody-confirmAccDetail h4 {
    line-height: 26px;
  }
  .table-search tr td:last-child, .table-search tr td:nth-last-child(2), .table-search tr td:nth-last-child(3) {
    min-width: 120px;
    width: 120px;
  }
  .box-searchTopNav .box-searchManageTeam input {
    height: 28px;
  }
  .box-searchTopNav .box-searchManageTeam {
    margin-top: 2px;
  }
  ul.list-timeFrameDisk {
    max-width: 86px;
  }
  ul.list-timeFrameDisk li span {
    width: 6px;
    height: 6px;
  }
  .table-permission thead tr th:nth-child(2), .table-permission tbody tr td:nth-child(2) {
    min-width: 85px;
    width: 85px;
  }
  .table-permission thead tr th:nth-child(3), .table-permission tbody tr td:nth-child(3) {
    min-width: 130px;
    width: 130px;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .box-campCardsInn .panel-heading .box-camCardAmt em, .box-camCardAmt em {
      line-height: 12px;
    }
  }
}

@media(max-width: 1366px) {
  .box-signUp h4 {
    font-size: 18px;
    padding-bottom: 20px;
  }
  .form-bugetNameCheckBox ::-webkit-input-placeholder {
    font-size: 13px;
  }
  .form-bugetNameCheckBox ::-moz-placeholder {
    font-size: 13px;
  }
  .form-bugetNameCheckBox :-ms-input-placeholder {
    font-size: 13px;
  }
  .form-bugetNameCheckBox input:-moz-placeholder {
    font-size: 13px;
  }
  .box-setup h2, .box-blueBG h4 {
    font-size: 16px;
  }
  #form-signUp input, .box-blueBG h5 {
    font-size: 14px;
  }
  .form-innFormSetup .form-checkbox, .box-paraCol {
    font-size: 13px;
  }
  select, input, .box-inputField label {
    font-size: 12px;
  }
  ol.top-slideBar li span {
    top: 3px;
  }
  ol.top-slideBar li.active span {
    top: -20px;
  }
  .box-setup h2 {
    padding-bottom: 60px;
  }
  .box-blueBG {
    padding: 20px 15px 0px;
  }
  .box-advancePageInn .row-advancePage, .box-setup .carousel-inner > .item {
    height: 530px;
    min-height: inherit;
  }
  .box-setupHeadingPara {
    max-width: 790px;
    margin: auto;
    font-size: 15px;
    line-height: 24px;
  }
  ul.list-setup {
    max-width: 720px;
  }
  ul.list-setup li {
    padding: 0px 25px;
  }
  .container {
    max-width: 1030px;
  }
  #form-bugetName .box-inputField {
    height: 346px;
    padding-top: 25px;
  }
  .box-infoGraph {
    padding-left: 15px;
  }
  .link-priv {
    bottom: 28px;
  }
  #form-custBudget {
    margin-top: 22px;
  }
  .breakTxt, ul.list-total-remening li span {
    font-size: 10px !important;
  }
  .select-category .selected-list .c-list .c-token {
    padding-right: 22px !important;
  }
  .table-widget tr td, .table-viewAdd.table-widget thead tr th {
    font-size: 10px;
  }
  .teble-expenseType tr th, .teble-expenseType tr td {
    width: 12%;
  }
  .teble-expenseType tr th:first-child, .teble-expenseType tr td:first-child {
    width: 76%;
    text-align: left;
  }
  ul.list-programCard > li, ul.list-campCards > li {
    width: 333px;
  }
  ul.list-programCardsTymStatus li span {
    max-width: 142px;
  }
  .panel-camCardHead ul.list-programCardsTymStatus li span {
    max-width: 156px;
  }
  .empty-textCon {
    text-align: center;
    font-size: 11px;
  }
  .table-manageTable.table-budPerDivi .table-manageTeamInn tr > td {
    max-width: 200px;
  }
  .form-innFormSetup .box-inputField {
    max-width: 390px;
  }
  .link-goalContainer {
    width: 12px;
  }
  .graph-tooltip.profile-tooltip span, .profile-tooltip span {
    font-size: 13px;
  }
  .graph-tooltip.profile-tooltip i, .profile-tooltip i {
    font-size: 11px;
  }
  .box-noMetric {
    font-size: 11px;
    line-height: 18px;
  }
  .box-noMetric h6 {
    font-size: 13px;
    padding-bottom: 8px;
  }
  .box-goPro {
    font-size: 12px;
    height: 40px;
    line-height: 40px;
  }
  .box-glCode h4 {
    font-size: 12px;
  }
  ul.list-glCode li, .box-glCode .btn-add {
    font-size: 11px;
  }
  .table-glCode thead tr th, .table-glCode tbody tr td {
    font-size: 10px;
  }
  ul.list-btnEditDel li button.btn-tableEdit {
    width: 14px;
    height: 15px;
  }
  ul.list-btnEditDel li button.btn-tableDelete {
    width: 12px;
    height: 16px;
  }
}

@media(max-width: 1359px) {
  .table-accordion .table-contOverflow {
    width: 135px;
    max-width: 135px;
  }
  .table-accordionUnassign .table-contOverflow {
    width: 175px;
    max-width: 175px;
  }
  .box-Unallocated .table-accordion .table-contOverflow {
    width: 135px;
    max-width: 135px;
  }
  .box-Unallocated.box-accordionTable .table-accordion tr td:nth-child(2) {
    width: 38%;
  }
  .box-Unallocated.box-accordionTable .table-accordion tr td:nth-child(3) {
    width: 28%;
  }
  .box-Unallocated.box-accordionTable .table-accordion tr td:nth-child(4) {
    width: 34%;
  }
  .select-category .selected-list .c-btn > span, .select-category .selected-list .c-list .c-token,
  .box-spendBtTypeTopBar .select-category .selected-list .c-list .c-token {
    width: 108px !important;
    padding: 1px 20px 0px 8px !important;
  }
  .box-spendBtTypeTopBar .select-category .selected-list .c-list .c-token .c-label {
    width: 57px;
  }
  .select-category .selected-list .c-btn > span:before, .select-category .selected-list .c-list .c-token:before {
    right: 20px;
  }
  .select-category .selected-list .c-btn > .c-list + span {
    right: 23px;
  }
}

@media(max-width: 1340px) {
  ul.list-allCategory > li .link-home {
    width: 32px;
    height: 32px;
    line-height: 28px;
  }
  .select-category .selected-list .c-btn > span {
    padding: 1px 18px 0px 7px !important;
  }
  .select-category .selected-list .c-btn > .c-list + span {
    top: 2px;
  }
  .select-category .selected-list .c-btn > span:after, .select-category .selected-list .c-list .c-token:after {
    right: 5px;
  }
  ul.list-allCategory > li:last-child {
    padding-left: 0px;
  }
  ul.list-allCategory {
    padding-top: 1px;
  }
  ul.list-allCategory > li:after {
    top: 10px;
    border-width: 5px 0px 5px 6px;
  }
  ul.list-programCardsTymStatus li span {
    max-width: 114px;
  }
  .panel-camCardHead ul.list-programCardsTymStatus li span {
    max-width: 130px;
  }
  .empty-textCon {
    text-align: center;
    font-size: 10px;
  }

  .btn-startSub {
    width: 190px;
    height: 29px;
    margin-top: 8px;
  }
  .btn-closeSearch.btn-clearAllFilter {
    top: 4px;
    height: 22px;
  }
  .box-topBarCompany .box-compFilter:after {
    top: 8px;
    height: 29px;
  }
}

/* All Pages Media Css End*/
input:not(.form-field-input):-webkit-autofill, input:not(.form-field-input):-webkit-autofill:hover, input:not(.form-field-input):-webkit-autofill:focus, input:not(.form-field-input):-webkit-autofill:active {
  -webkit-text-fill-color: #FFF;
  transition: background-color 500000000000s ease-in-out 0s;
}

.box-inputAnimation input:not(.form-field-input):-webkit-autofill, .box-inputAnimation input:not(.form-field-input):-webkit-autofill:hover, .box-inputAnimation input:not(.form-field-input):-webkit-autofill:focus, .box-inputAnimation input:not(.form-field-input):-webkit-autofill:active {
  -webkit-text-fill-color: #424242;
}

select::-ms-expand {
  display: none;
}

@media all and (-ms-high-contrast: none) {
  label.btn-radio {
    overflow: visible;
    border-radius: 0px !important;
  }
  input {
    line-height: normal;
    cursor: #FFF;
  }
  .box-rightBule .form-checkbox label.active, .box-rightBule .form-checkbox label.active:active, .box-rightBule .form-checkbox label.active:hover,
  .box-blueBG .form-checkbox label.active, .box-blueBG .form-checkbox label.btn.active:active, .box-blueBG .form-checkbox label.btn.active:hover,
  .form-checkbox label.active, .form-checkbox label.btn.active:active, .form-checkbox label.btn.active:hover {
    background: transparent !important;
    border: 0px !important;
    overflow: visible;
  }
  input[type='checkbox'], input[type='radio'] {
    z-index: 0 !important;
  }
  .btn-radio.btn input[type='checkbox'], .btn-radio.btn input[type='radio'], .btn-group > .btn-radio.btn input[type='checkbox'], .btn-group > .btn-radio input[type='radio'] {
    width: 14px;
    height: 14px;
    left: 0px;
  }
  .form-checkbox label.btn:after {
    display: none !important;
  }
  .btn.active, .btn:active {
    box-shadow: none;
    background-color: transparent;
  }
  .cus-tooltipOn {
    margin-bottom: -6px;
  }
  .row-metrics {
    padding-bottom: 17px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .box-inputAnimationInExp .input-animation, .box-searchManageTeam input, .input-animationW, .input-animation, select.input-animation, select.input-animationW, select, input {
    line-height: normal !important;
  }
  input:not(.form-field-input)[type=password] {
    font-size: 24px;
  }
  ul.list-graphAddProd input[disabled], .uneditable-graph input[disabled] {
    color: #FFF;
    -webkit-text-fill-color: #FFF;
  }
  .box-blueBG h6 {
    font-weight: 300;
  }
  .box-paraColInn p, .box-signUp h4, .box-inputField label, body {
    font-weight: 400;
  }
  .box-campCardsInn .panel-heading em, .table-manageTeamInn tr td, .box-inputAnimation label, .name-customFieldPlan, .box-markPlacePara p,
  .box-campCardsInn .panel-heading span.panel-title,
  .box-campCardsInn .panel-heading em, .LogoutLink-removable,
  .input-calendar em, .box-blueBG h5, .box-blueBG h4 {
    font-weight: 500;
  }
  input[type=submit], input[type=button], input[type=reset], button, .btn, ul.list-graph li h4, ul.list-graphInn li span {
    font-weight: 600;
  }
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    transform: scale(1.2);
  }
  .form-checkbox label {
    overflow: hidden;
  }
  .box-passOverly:after {
    bottom: 1px;
  }
  .table-contOverflow {
    display: block !important;
    max-width: 185px;
    white-space: nowrap;
  }
  .teble-expenseType .table-contOverflow {
    max-width: 100%;
  }
}

/* Safari 10.1+ */
@media not all and (min-resolution: .001dpcm) {
  .v-text, .h-text {
    font-size: 9px;
  }
  input, .table-manageTeamInn tr td {
    font-weight: 400;
  }
  .text-makingUser, .box-radioCheckBtn, .cir-mostCom, .box-setupHeadingPara, .LogoutLink-removable, body {
    font-weight: 500;
  }
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    transform: scale(1);
  }
  .box-passOverly:after {
    bottom: 0px;
  }
  .box-modalAddGoal .modal-footer {
    position: relative;
    top: -1px;
  }
  .table-accordion tr td:last-child {
    padding: 0px !important;
  }
}

/****/
@media screen and (min--moz-device-pixel-ratio: 0) {
  .row-metrics {
    padding-bottom: 17px;
  }
  .box-padd10-15 {
    padding: 10px 15px 15px;
  }
  ul.list-assOwner li .btn-tableEdit {
    top: -4px;
  }
  .table-contOverflow {
    width: 100%;
  }
  .table-accordion tr td:last-child {
    padding-top: 0px !important;
  }
}

.fav-iconImg {
  width: 12px !important;
  height: 16px !important;
}

select:focus::-ms-value {
  color: #424242;
  background-color: transparent;
}

.box-rightBule select:focus::-ms-value, .box-blueBG select:focus::-ms-value {
  color: #FFF;
}

input:-ms-clear {
  display: none !important;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn, .btn.disabled:hover, .btn[disabled]:hover, fieldset[disabled] .btn:hover {
  color: #FFF !important;
  background-color: #d2d2d2 !important;
  opacity: 1;
}

/**Nav Blue BG Start**/
.btnBG-navBlue {
  color: #FFF !important;
  background-color: #3272b7 !important;
}

.btnBG-navBlue:hover {
  color: #FFF !important;
  background-color: #3d83cf !important;
}

.btnBG-navBlue[disabled],
.btnBG-navBlue.disabled,
  /**Nav Blue BG End**/
.form-checkbox label.active.btn.disabled {
  background-color: #3273b7 !important;
}

.box-rightBule .form-checkbox label.active.btn.disabled, .box-blueBG .form-checkbox label.active.btn.disabled {
  background-color: #28a264 !important;
}

@media all and (-ms-high-contrast: none) {
  .box-rightBule .form-checkbox label.active.btn.disabled, .box-rightBule .form-checkbox label.btn:after,
  .box-blueBG .form-checkbox label.active.btn.disabled, .box-blueBG .form-checkbox label.btn:after,
  .form-checkbox label.active.btn.disabled, .form-checkbox label.btn:after {
    border: 0px !important;
    background-color: transparent !important;
  }
  .table-contOverflow {
    display: block !important;
    max-width: 120px;
  }
  .teble-expenseType .table-contOverflow {
    max-width: 100%;
  }
  span.c-label {
    width: 140px;
  }
  .select-optionStatus select option[disabled] {
    font-weight: 400 !important;
  }
  .box-campCardsInn .panel-heading .box-camCardAmt em, .box-camCardAmt em {
    width: 14px;
    height: 14px;
    line-height: 12px;
  }
  .table-contOverflow {
    width: 100% !important;
  }
}

.select-optionStatus select option[disabled] {
  color: #c1bdbd;
  font-weight: bold;
  opacity: 1;
}

.input-animation[readonly], .input-animation[disabled] {
  color: #000;
  opacity: 0.5;
}

select, option {
  width: 250px;
}

option {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

@media(max-width: 1340px) {
  .table-contOverflow {
    max-width: 105px;
  }
  @media all and (-ms-high-contrast: none) {
    .table-contOverflow {
      max-width: 105px;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .table-contOverflow {
      max-width: 105px;
    }
  }
}

#statusColorListClone ul {
  background-color: #f3f3f3;
}

/** End of styles applied during timeline downloadable image rendering **/

/** These styles are being applied during spend by type downloadable image rendering **/
#spendByTypeClone {
  position: absolute;
  top: 200%;
  left: 200%;
  z-index: 10000;
  background: white;
  width: 1900px;
  height: 1000px;
}

/** End of styles applied during spend by type downloadable image rendering **/


/** Dropdown Menu global styles **/
.dropdownMenuComponent .menuItemsContainer {
  top: 20px;
  right: 0;
}

.dropdownMenuComponent .menuItemsContainer .menuItem {
  white-space: nowrap;
}

.dropdownMenuComponent .menuItemsContainer .menuItem button span {
  margin: 0 !important;
}

.downArrow {
  width: 9px;
  height: 9px;
  transform: rotate(-45deg);
  border-left: 2px solid #3174b7;
  border-bottom: 2px solid #3174b7;
  display: inline-block;
  border-bottom-left-radius: 2px;
  margin-left: 3px;
  margin-bottom: 3px;
}

/** End of Dropdown Menu global styles **/

/** Expenses Table global styles **/
.expenses-table .inner-cell-container.has-problem {
  color: #FC582F;
}

.expenses-table .expenses-table-row .inner-cell-container .text-with-add-new-expense .add-objects {
  display: none;
}

.expenses-table .expenses-table-row:hover .inner-cell-container .text-with-add-new-expense .add-objects {
  display: block;
}

.ng2-dropdown-menu__options-container {
  max-height: 150px;
  overflow: auto;
}

/** End of Expenses Table global styles **/

.root-layout {
  height: 100%;
  display: flex;

  .right-side {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 75px);

    &-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      max-height: calc(100% - 60px);
      padding: 23px 30px;
      overflow: auto;
    }
  }

  @media(max-width: 1440px) {
    .right-side {
      max-width: calc(100% - 55px);
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.expansion-panel-chevron {
  transform: rotate(-90deg);
  transition: transform .2s linear;
  --fa-primary-color: var(--mat-default);
  --fa-secondary-color: var(--mat-default);

  &.expanded {
    transform: rotate(0);
  }
}

.status-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;

  &.planned {
    background-color: var(--status-planned);
  }

  &.committed {
    background-color: var(--status-committed);
  }

  &.closed {
    background-color: var(--status-closed);
  }
}

@page {
  margin: 0!important;
}
