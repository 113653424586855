.spending-table-container {
  .table-wrap {
    max-height: calc(100vh - 275px);

    .banner-shown & {
      max-height: calc(100vh - 339px);
    }

    .p-form-field-outline {
      margin-top: 7px;

      .mat-select-placeholder {
        color: var(--blue-grey-medium);
        -webkit-text-fill-color: var(--blue-grey-medium);
      }

      .mat-form-field-flex {
        height: 40px !important;
      }

      mat-select {
        height: initial;
      }

      .mat-form-field-outline {
        color: transparent;
      }

      .mat-select-arrow-wrapper {
        padding-left: 8px;
        transition: opacity .25s;
      }

      .mat-form-field-flex:not(:hover) .mat-select-arrow-wrapper {
        opacity: 0;
      }

      .mat-form-field-infix {
        border-top-width: 4px !important;
      }

      .mat-select-value {
        padding-top: 0 !important;
      }
    }
  }

  .cell-content {
    --text-align: 'flex-start';
    display: flex;
    height: 52px;
    flex-basis: 100%;
    min-width: 155px;
    margin-left: auto;
    padding-left: 19px;
    padding-right: 19px;
    justify-content: var(--text-align);

    &.parent-cell {
      object-name {
        min-width: 200px;
        max-width: 400px;
      }
    }
  }

  .cell-content-default {
    display: flex;
    align-items: center;
    max-width: 300px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tr {
    &.opened-in-drawer {
      background-color: #FEF7FAFF;

      td:first-child {
        background-color: #FEF7FAFF;
      }
    }

    &.is-selected {
      background-color: #E6F4FAFF;

      td:first-child {
        background-color: #E6F4FAFF;
      }
    }

    &:hover {
      icon-grip {
        transform: translate(0, -50%) scale(1);
        opacity: 1;
      }
    }
  }

  th,
  tbody td {
    font-size: 14px;
    line-height: 1;
    border-bottom: var(--border-style);
  }

  th {
    white-space: nowrap;
    word-break: initial;
    font-weight: 600;

    .cell-content {
      padding: 19px;
    }
  }

  td {
    font-weight: 500;
  }
}

