.filter-select,
.mat-select-panel.filter-select {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 420px;

  .option-text,
  .mat-option-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-pack: center;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    word-break: break-all;
    line-height: 20px;
  }

  select-option,
  .mat-option,
  &.group-mode .mat-optgroup-label {
    background: white;
    line-height: 38px;
    height: auto;
    min-height: 38px;
  }

  select-option,
  mat-option,
  .mat-option {
    max-height: 40px;
    padding-left: 10px;
    opacity: 1;
    transform: scaleY(1);
    transform-origin: 0 0;
    transition: opacity .25s, max-height .15s .25s, transform .35s;

    .mat-checkbox-inner-container {
      margin-left: 0;
    }

    &.visually-hidden {
      opacity: 0;
      overflow: hidden;
      transform: scaleY(0);
      max-height: 0 !important;
      min-height: auto!important;
      pointer-events: none;
    }
  }

  &.group-mode {
    select-option,
    .mat-option {
      padding-left: 40px;

      &.select-all {
        padding: 0 10px;
      }

      &.select-group {
        position: sticky;
        top: 38px;
        background: #EDEBF4;
        padding: 0 10px;
        z-index: 50;
      }
    }

    .group-option {
      padding-left: 56px;
    }

    .select-group .mat-checkbox-label {
      font-size: 14px;
      color: var(--purple);
    }

    arrow-toggler {
      margin-right: 10px;
    }
  }

  select-option {
    &.select-group {
      top: 0 !important;
    }
  }

  &.control-has-search {
    .mat-option.select-group {
      top: 96px;
    }
  }

  mat-option.mat-option.mat-selected {
    font-weight: 500;
  }

  .mat-checkbox,
  .mat-checkbox-layout {
    width: 100%;
  }

  .select-all {
    .mat-checkbox-inner-container {
      margin-left: initial;
    }
  }

  mat-option:hover:not(.mat-option-disabled),
  mat-option.mat-selected:not(.mat-option-disabled) {
    background-color: white;
  }
}

.mat-select-panel.reset-panel-width {
  max-width: 800px;
}

.mat-menu-panel {
  &.metric-masters-list {
    min-width: 280px;
    max-width: initial;
    max-height: initial;
    box-shadow: 0 12px 30px rgba(150, 169, 188, .3);
    border-radius: 0 0 4px 4px;
  }

  &.expenses-view-mode-list {
    min-width: 240px;
  }
}

.mat-autocomplete-panel .max-length-counter {
  margin-left: 12px;
  opacity: .8;
  font-size: 12px;
}
