
.form-field-group {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.form-field {
  margin-bottom: 14px;
}

.form-field-group .form-field {
  max-width: 48%;
}

.form-field .form-field-label {
  font-size: 13px;
  font-weight: normal;
  color: #505f66;
  margin-bottom: 5px;
}

.form-field .form-field-input {
  display: block;
  height: 33px;
  width: 100%;
  background-color: white;
  border: 1px solid;
  border-color: #c3d3db;
  border-radius: 0px;
  color: black;
  font-size: 14px;
  line-height: initial;
  padding: 0px 10px;
}

.form-field select.form-field-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: rgb(39, 86, 138);
}

.form-field .select-wrapper {
  position: relative;
}

.form-field .select-wrapper::before {
  content: "";
  display: block;
  position: absolute;
  height: 6px;
  width: 6px;
  border-style: solid;
  border-width: 2px 2px 0 0;
  transform: rotate(135deg);
  z-index: 1;
  top: 12px;
  right: 12px;
  color: rgb(39, 86, 138);
}

.form-field .form-field-input[type="password"] {
  font: small-caption;
  font-size: 16px;
}

.form-field .form-field-checkbox {
  display: inline-block;
  cursor: pointer;
}

.form-field .form-field-checkbox input,
.form-field-radiogroup-item input {
  display: none;
}

.form-field .form-field-checkbox input + .checkbox-item {
  display: inline-block;
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  border: 2px solid #333333;
  margin-right: 5px;
}

.form-field .form-field-checkbox input:checked + .checkbox-item,
.form-field .form-field-radiogroup-item input:checked + span {
  background-color: #28a264;
}

.form-field .form-field-checkbox span {
  vertical-align: middle;
  font-size: 13px;
}

.form-field .form-field-radiogroup {
  display: block;
}

.form-field .form-field-radiogroup-item {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  margin-right: 15px;
}

.form-field .form-field-radiogroup-item span {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border: 2px solid #333333;
  margin-right: 6px;
  box-sizing: border-box;
}

.text-separator {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #505F66;
}

.form-links {
  display: flex;
  justify-content: space-between;
}

.form-links.forgot-password {
  justify-content: space-between;
  width: 270px;
  font-size: 12px;

  a {
    color: var(--mat-default);
    margin-top: 5px;
  }
}

.form-links.switch-user {
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
}

.form-links .form-links-item:hover {
  color: #0872ba;
  text-decoration: underline;
}

.login-form-container,
.forgot-password-form-container,
.register-form-container,
.register-success-container {
  width: 410px;
}

.forgot-password-form-container {
  margin: 0 auto;
}

.register-form-container .form-actions {
  margin: 28px 0;
}

.login-form-container .form-actions.login {
  margin-bottom: 19px;
  margin-top: 45px;
}

.forgot-password-form-container .form-actions {
  margin-top: 20px;
}

.login-form-container .form-actions.login button[type=submit],
.forgot-password-screen .forgot-password-form-container .form-actions button[type=submit],
.register-screen .register-form-container .form-actions button[type=submit],
.register-success-container .auth-page-form .form-actions button[type=button] {
  background: var(--corporate-ultraviolet);
  font-weight: 500;
}

.login-form-container .form-actions.custom-login {
  margin-top: 16px;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
}

.login-form-container .form-actions button.secondary {
  background-color: var(--corporate-white);
  color: var(--primary);
  border: 1px solid var(--primary);
}

input.default-placeholder::-webkit-input-placeholder {
  color: lightgray;
  font-size: 14px;
  font-weight: normal;
}

input.default-placeholder:-moz-placeholder {
  color: lightgray;
  font-size: 14px;
  font-weight: normal;
}

input.default-placeholder:-ms-input-placeholder {
  color: lightgray;
  font-size: 14px;
  font-weight: normal;
}

input.default-placeholder::placeholder {
  color: lightgray;
  font-size: 14px;
  font-weight: normal;
}

mat-radio-group.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

mat-radio-group {
  .mat-radio-button {
    margin-bottom: .75rem;
    font-family: 'DM Sans', sans-serif;
  }

  :not(.mat-radio-checked) .mat-radio-outer-circle {
    border-color: var(--blue-disabled);
  }

  .mat-radio-label-content {
    font-weight: 500;
  }

  .mat-radio-checked .mat-radio-label-content {
    font-weight: 700;
  }
}

mat-radio-button.mat-radio-button {
  input[type="radio"] {
    background-color: transparent;
  }
}


.mat-slide-toggle {
  .mat-slide-toggle-label {
    font-weight: 600;
    font-size: 14px;
    color: var(--primary);
  }

  .mat-slide-toggle-content {
    font-family: 'DM Sans', sans-serif;
  }

  .mat-slide-toggle-persistent-ripple {
    left: 0;
  }
}

.form-control-shake {
  animation: shake .7s cubic-bezier(.36, .07, .19, .97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-1px);
  }
  20%, 80% {
    transform: translateX(2px);
  }
  30%, 50%, 70% {
    transform: translateX(-4px);
  }
  40%, 60% {
    transform: translateX(4px);
  }
}
