@charset "utf-8";
/* CSS Document */
.disabled{ pointer-events:none; }
.input-animation.error{ border-color:red; }
.ng2-dropdown-menu{ z-index:9999 !important; }

/************** SCROLLBAR BASE CSS **************/
.scroll-wrapper { overflow:hidden !important; padding:0 !important; position:relative; }
.scroll-wrapper > .scroll-content { position:relative !important; top:0; left:0; padding:0; width:auto !important; height:auto; margin:0; max-height:none; max-width:none !important; overflow-y:scroll !important; border:none !important; box-sizing:content-box !important; }
.scroll-wrapper > .uprofile-panel.scroll-content{ width:calc(100% + 0px) !important; }
.scroll-wrapper > .scroll-content::-webkit-scrollbar{ height:0; width:0; }
.scroll-element{ display:none; }
.scroll-element, .scroll-element div{ box-sizing:content-box; }
.scroll-element.scroll-x.scroll-scrollx_visible,.scroll-element.scroll-y.scroll-scrolly_visible { display: block; }
.scroll-element .scroll-bar, .scroll-element .scroll-arrow { cursor:default; }
.scroll-textarea { border:1px solid #cccccc; border-top-color:#999999; }
.scroll-textarea > .scroll-content { overflow:hidden !important; }
.scroll-textarea > .scroll-content > textarea{ border:none !important; box-sizing:border-box; height:100% !important; margin:0; max-height:none !important; max-width:none !important; overflow:scroll !important; outline:none; padding:2px; position:relative !important; top:0; width:100% !important; }
.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar { height:0; width:0; }

html body g text{ font-style:normal !important; font-size:12px; }
html body svg > g > text{ display:none !important; }
.dnd-drag-start { -moz-transform:scale(0.8); -webkit-transform:scale(0.8); transform:scale(0.8); opacity:0.7; border:1px dashed #a0a5b6; }
.dnd-drag-enter { opacity:0.7; border:1px dashed #a0a5b6 !important; }
.dnd-drag-over { border:1px dashed #a0a5b6 !important; }
.dnd-sortable-drag { -moz-transform:scale(0.9); -webkit-transform:scale(0.9); transform:scale(0.9); opacity:0.7; border: 1px dashed #a0a5b6; }

/*** Budget Hierarchy Graph ***/
path[stroke-width="1"], path[stroke-width="2"], ellipse[stroke-width="1"], ellipse[stroke-width="2"], ellipse[stroke-width="6.5"] , path[stroke-width="6.5"] { stroke: transparent; }
.google-visualization-tooltip { font-size: 11px !important; left: 5px; top: 5px; padding: 2px !important; text-transform: capitalize !important; left: 5px; top: 5px; min-width: max-content !important; min-width: -moz-max-content !important; min-width: -ms-max-content !important; min-width: -o-max-content !important; min-width: -webkit-max-content !important; }
.google-visualization-tooltip{ display: -ms-grid; -ms-grid-columns: max-content; }
svg > g > g:last-child { pointer-events: none }
div.google-visualization-tooltip { pointer-events: none; z-index: 1;}
div.google-visualization-tooltip span{ display: inline-block; margin: 0; padding: 0;}
div.google-visualization-tooltip p{ margin: 0; padding: 0;}

.btn-option:after{ position:absolute; top:0px; right:10px; bottom:0px; content:''; width:10px; height:6px; margin:auto; background:url('../images/icon-downArrow.png') no-repeat center center; }

.search-main-block{position: relative;}
.search_block{position: absolute; right: 0; top: 0;}
.search_block button.btn-option{padding:1px 25px 1px 10px;}
.search_block button.btn-option:after{ width:20px; right:0px; }
ul.search_block li{ position:relative; float:left; }
ul.search_block li .btn-addCampaignView{ position:relative; color: #FFF; background-color: #00bcd4; border-color: #00bcd4; }
ul.search_block li .btn-addCampaignView.disabled{ color: #FFF; background-color: #d2d2d2; border-color: #d2d2d2; }
.search-main-block .select-category .selected-list .c-btn{ border-color:rgba(50,115,183,0.35) !important; background:transparent !important; }
.search-main-block .select-category .selected-list .c-btn > span{ height:34px; font-size:13px; color:#575757 !important; font-weight:500; line-height:34px; padding-top:0px !important; padding-bottom:0px !important; }
.select-category .selected-list .c-btn > span:before, .select-category .selected-list .c-list .c-token:before{ background:#F7F7F7 !important; }


.exp-head{ height: auto; }
.exp-head h4 { font-size: 15px; text-align: center; height: auto; }
.exp-head p { color:#4E4E4E; line-height: 1.2; padding: 7px 0 0 0; text-align: center; font-size: 13px; }
.exp-title{ float: left; display: block; width: 100%; margin:40px 0 30px 0; color: #4E4E4E; }

.disabled-opt span{ position: absolute; font-size:14px; background-color:#699fd8; color:#fff !important; }
.head-spreadSheet{ padding-top: 25px; }
.box-comboSelect  .selected-list .c-list .c-token{ padding:0px !important; margin:0px !important; height:56px; line-height:56px; background:transparent !important; }
.btn-upload {width: 150px; height: 30px; background-color: #3272b7;}
.ChangeExpense{ color: #00bcd4; font-size: 13px; margin: 2px 0 0 10px; text-decoration: underline; }
.ChangeExpense:hover{ color: #000; }
.box-alerts .box-alertsPara{ width: 100%; height:100%; display:table; text-align:center; padding-bottom:15px; }
.box-alerts .box-alertsPara p{ padding: 0px; color:#CCC; font-size:13px; display: table-cell; vertical-align: middle; }
.btn-changeLogView{ margin:27px 0px 0px; }
.box-changeLogModal .modal-header{ background-color:#265689; }
.box-changeLogModal .modal-header h4{ text-align:left; }
.box-changeLogModal .modal-body{ padding:0px; }
.box-changeLogModal .border-bottomRadius{ overflow:hidden; }
ul.list-assOwner li em{ font-style:normal; }

@media screen and (max-width: 1366px){
  .box-spreadSheet select.input-animation{ height: 40px; font-size: 12px }
  .exp-head p { font-size: 12px; }
}

.close-hubspot-form{ position:absolute; top:5px; right:7px; font-size:28px; transform:rotate(45deg); }
.listed-segAddGoal > a{ position:relative; padding-right:45px !important; }
.listed-segAddGoal > a span{ position:absolute; left:calc(100% - 32px); }
.box-spendByTypeChart svg g[clip-path] rect { cursor:pointer; }

